import { useMediaQuery, useTheme } from "@crayon/design-system-react";

const useScreen = () => {
  const theme = useTheme();
  const xsMatch = useMediaQuery(theme.breakpoints.up("xs"));
  const smMatch = useMediaQuery(theme.breakpoints.up("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.up("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));

  const isBigScreen = lgMatch; // used to detect when hide the sidebar and display menu btn

  return { isBigScreen, xsMatch, smMatch, mdMatch, lgMatch, xlMatch };
};

export default useScreen;
