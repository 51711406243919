/* eslint-disable no-useless-escape */
const isValidHttpUrl = (urlString: string): boolean => {
  const res = urlString.match(
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );

  return res !== null;
};

export default isValidHttpUrl;
