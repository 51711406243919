import { Box, useTheme } from "@crayon/design-system-react";

interface IFlexSpacer {
  themeSpacing?: number;
}

const defaultThemeSpacing = 4;

const FlexSpacer = ({ themeSpacing = defaultThemeSpacing }: IFlexSpacer) => {
  const theme = useTheme();
  return <Box sx={{ width: theme.spacing(themeSpacing), minWidth: "5px" }} flexShrink={2} />;
};

export default FlexSpacer;
