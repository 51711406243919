import { useQuery } from "@tanstack/react-query";
import { GetProductSyncResultsResponse } from "api/client.generated";
import useApi from "./useApi";

interface UseProductDigestReturn {
  productDigest: GetProductSyncResultsResponse | undefined;
  isProductDigestFetching: boolean;
}

const useProductDigest = (digestId: string): UseProductDigestReturn => {
  const { digestClient } = useApi();

  const { data: productDigest, isFetching: isProductDigestFetching } = useQuery(
    {
      queryKey: ["getProductDigest", digestId],
      queryFn: () => digestClient.getProductDigest(digestId),
    },
  );

  return { productDigest, isProductDigestFetching };
};

export default useProductDigest;
