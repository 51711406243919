import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@crayon/design-system-react";
import { useUserContext } from "context/userContextProvider";
import { GavelIcon, LogoutIcon, SwitchAccountIcon, UserAccountIcon } from "images/MuiIcons";
import { BaseSyntheticEvent, useState } from "react";
import { NavLink } from "react-router-dom";
import AppRoutes from "routes/app-routes";

const UserMenu = () => {
  const { signOut, userName, userEmail } = useUserContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="User">
        <IconButton
          aria-controls={open ? "account-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          backgroundColor="primary"
          onClick={(e: BaseSyntheticEvent) => setAnchorEl(e.currentTarget)}
          showBackground
          showBorder={false}
          testId="User"
        >
          <UserAccountIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 2,
            sx: {
              overflow: "visible",
              mt: 0.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box px={2} pb={1}>
          <Typography variant="body1" fontWeight="bold">
            {userName}
          </Typography>
          <Typography variant="body2" fontSize="small">
            {userEmail}
          </Typography>
        </Box>
        <Divider />
        <NavLink to={AppRoutes.terms.route} style={{ textDecoration: "none", color: "inherit" }}>
          <MenuItem>
            <ListItemIcon>
              <GavelIcon fontSize="small" />
            </ListItemIcon>
            View Terms
          </MenuItem>
        </NavLink>
        <NavLink
          to={AppRoutes.selectPartner.route}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <MenuItem>
            <ListItemIcon>
              <SwitchAccountIcon fontSize="small" />
            </ListItemIcon>
            Switch Partner
          </MenuItem>
        </NavLink>
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
