import {
  Box,
  Divider,
  FormSelectOption,
  LinkBehavior,
  NormalButton,
  Paper,
  Stack,
  Typography,
} from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  BillingMotion,
  ProductSyncBillingMode,
  ProductSyncMode,
  ProgramConfigurationPostData,
  ProgramType,
  Source,
  Target,
} from "api/client.generated";
import {
  AutotaskMicrosoftCspProgramConfiguration,
  MicrosoftCspProgramConfiguration,
} from "api/client.generated.extensions";
import ControllerFormSelect from "components/primitives/ControllerFormSelect";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormCheckbox from "components/primitives/FormCheckbox";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormSwitch from "components/primitives/FormSwitch";
import FormTextWithChips from "components/primitives/FormTextWithChips";
import { useNotificationContext } from "context/notificationContext";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useApi from "hooks/api/useApi";
import useProgramConfiguration from "hooks/api/useProgramConfiguration";
import useScreen from "hooks/useScreen";
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";

import NotificationMessage from "types/notification-message";
import { combineEnumFlags, parseEnumFlags } from "utils/enum-flags";
import * as yup from "yup";
import AutotaskExtraBillingSettings from "./AutotaskExtraBillingSettings";

interface ProgramEditFormProps {
  source: Source;
  target: Target;
  program: ProgramType;
}

const ProgramEditForm = (props: ProgramEditFormProps) => {
  const { source, target, program } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");

  const navigate = useNavigate();
  const { smMatch } = useScreen();
  const { programsClient } = useApi();

  const { programConfig, isProgramConfigFetching } = useProgramConfiguration(
    source,
    target,
    program,
  );
  const { invalidatePartnerConfig } = usePartnerConfigContext();
  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();

  // duplicate autotask specific select fields to set them in the child
  // component when available select values are loaded
  const [autotaskBillingCodeId, setAutotaskBillingCodeId] = useState("");
  const [autotaskVendorId, setAutotaskVendorId] = useState("");

  const formSchema = yup.object().shape({
    isProgramSyncEnable: yup.boolean().required(),
    emailList: yup.array().of(
      yup
        .string()
        .email(({ value }) => `'${value}' is an invalid email.`)
        .required(),
    ),
    isBillingSyncEnable: yup.boolean().required(),
    billingMotions: yup
      .array()
      .of(yup.string().required())
      .when("isBillingSyncEnable", {
        is: (isBillingSyncEnable: boolean) => isBillingSyncEnable,
        then: (schema) =>
          schema.test("test-length", "Required", (billingMotions) =>
            Boolean(billingMotions?.length),
          ),
      })
      .required(),
    isProductSyncEnable: yup.boolean().required(),
    productSyncMode: yup.string().required("Required"),
    productBillingModes: yup
      .array()
      .of(yup.string().required())
      .when("productSyncMode", {
        is: (productSyncMode: ProductSyncMode) => productSyncMode === ProductSyncMode.AllProducts,
        then: (schema) => schema.test("test-length", "Required", (modes) => Boolean(modes?.length)),
      })
      .required(),
    isSyncAzureSubscriptionsEnable: yup.boolean(),
    isSyncAnnualSubscriptionsEnable: yup.boolean(),
    isSetNewProductsTaxableEnable: yup.boolean(),
    autotaskBillingCodeId: yup.string().when("isBillingSyncEnable", {
      is: (isBillingSyncEnable: boolean) => isBillingSyncEnable && target === Target.Autotask,
      then: (schema) =>
        schema.test("test-length", "Required", (billingCodeId) => Boolean(billingCodeId?.length)),
    }),
    autotaskVendorId: yup.string(),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const {
    control,
    handleSubmit,
    resetField,
    reset,
    getValues,
    formState: { isDirty },
  } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      isProgramSyncEnable: false,
      emailList: [],
      isBillingSyncEnable: false,
      billingMotions: [],
      isProductSyncEnable: false,
      productSyncMode: ProductSyncMode.InUseProductsOnly,
      productBillingModes: [],
      isSyncAzureSubscriptionsEnable: false,
      isSyncAnnualSubscriptionsEnable: false,
      isSetNewProductsTaxableEnable: false,
      autotaskBillingCodeId: "",
      autotaskVendorId: "",
    },
  });

  const isProgramSyncEnableWatch = useWatch({ control, name: "isProgramSyncEnable" });
  const isBillingSyncEnableWatch = useWatch({ control, name: "isBillingSyncEnable" });
  const isProductSyncEnableWatch = useWatch({ control, name: "isProductSyncEnable" });
  const productSyncModeWatch = useWatch({ control, name: "productSyncMode" });

  // disable BillingSync and ProductSync when ProgramSync is off
  useEffect(() => {
    if (!isProgramSyncEnableWatch) {
      resetField("isBillingSyncEnable", { defaultValue: false });
      resetField("isProductSyncEnable", { defaultValue: false });
    }
  }, [isProgramSyncEnableWatch, resetField]);

  // this is to update form when data programConfig is loaded
  useEffect(() => {
    if (!programConfig) return;

    if (target === Target.Autotask) {
      const { billingCodeId, vendorId } =
        programConfig.configuration as AutotaskMicrosoftCspProgramConfiguration;
      setAutotaskBillingCodeId(billingCodeId === 0 ? "" : `${billingCodeId}`);
      setAutotaskVendorId(vendorId === 0 ? "" : `${vendorId}`);
    }

    reset({
      /* In the Autotask case, there might be a race between this 'reset' (form reset)
         and 'resetField' from AutotaskExtraBillingSettings. In case 'resetFiled' been called 1st,
         'reset' call overrides 'autotaskBillingCodeId' and 'autotaskVendorId' fields.
         Calling 'getValues' to prevent resetting values that are not set here.
       */
      ...getValues(),
      isProgramSyncEnable: programConfig.isSyncing,
      emailList: programConfig.emails,
      isBillingSyncEnable: programConfig.isBillingSyncing,
      billingMotions: parseEnumFlags(BillingMotion, programConfig.activeBillingMotions).filter(
        (x) => x !== BillingMotion.None,
      ),
      isProductSyncEnable: programConfig.isProductsSyncing,
      productSyncMode: programConfig.productSyncMode,
      productBillingModes: parseEnumFlags(
        ProductSyncBillingMode,
        programConfig.productSyncBillingMode,
      )?.filter((x) => x !== ProductSyncBillingMode.None),
      isSyncAzureSubscriptionsEnable: programConfig.configuration?.syncAzure,
      isSyncAnnualSubscriptionsEnable: programConfig.configuration?.syncAnnualBilled,
      isSetNewProductsTaxableEnable:
        target === Target.Syncro || target === Target.ConnectWise
          ? (programConfig.configuration as MicrosoftCspProgramConfiguration).taxableProducts
          : false,
    });
  }, [reset, programConfig, target, getValues]);

  const [isPreviewDisable, setIsPreviewDisable] = useState(true);
  useEffect(() => {
    if (!isProductSyncEnableWatch || isProgramConfigFetching) {
      setIsPreviewDisable(true);
      return;
    }

    if (
      !programConfig?.productSyncMode ||
      programConfig.productSyncMode === ProductSyncMode.InUseProductsOnly
    ) {
      setIsPreviewDisable(true);
      return;
    }

    // ProductSyncMode.AllProducts case
    setIsPreviewDisable(!programConfig.productSyncBillingMode.length);
  }, [isProductSyncEnableWatch, isProgramConfigFetching, programConfig]);

  const productSyncModeOptions = useMemo<FormSelectOption[]>(
    () => [
      { value: ProductSyncMode.AllProducts, label: "All Products" },
      { value: ProductSyncMode.InUseProductsOnly, label: "In use only" },
    ],
    [],
  );

  const productBillingModeOptions = useMemo<FormSelectOption[]>(
    () => [
      {
        value: ProductSyncBillingMode.MonthlyBillingMonthlyTerm,
        label: "Monthly Billing and Term",
      },
      {
        value: ProductSyncBillingMode.MonthlyBillingAnnualTerm,
        label: "Monthly Billing with Annual Term",
      },
      {
        value: ProductSyncBillingMode.AnnualBillingAnnualTerm,
        label: "Annual Billing and Term",
      },
    ],
    [],
  );

  const billingMotionOptions = useMemo<FormSelectOption[]>(
    () => [
      {
        value: BillingMotion.SeatBased,
        label: "Monthly Subscriptions/Licenses",
      },
      {
        value: BillingMotion.SeatBasedAnnual,
        label: "Annual Subscriptions/Licenses",
      },
      {
        value: BillingMotion.AzurePlan,
        label: "Azure Plan",
      },
    ],
    [],
  );

  const BillingSection = (
    <>
      <Box display="flex">
        <Typography variant="subtitle1">Billing</Typography>
        <FormSwitch
          control={control}
          bindSchemaFieldName="isBillingSyncEnable"
          testId="isBillingSyncEnable"
          sxRoot={{ ml: "auto" }}
          isLoading={isProgramConfigFetching}
          disabled={isSaving || !isProgramSyncEnableWatch}
        />
      </Box>
      <ControllerFormSelect
        control={control}
        bindSchemaFieldName="billingMotions"
        label="Billing Motions"
        testId="Billing Motions"
        disabled={!isBillingSyncEnableWatch || isSaving}
        required={isBillingSyncEnableWatch}
        multiple
        options={billingMotionOptions}
        isLoading={isProgramConfigFetching}
      />
      <Box display="flex" gap={2} flexWrap="wrap">
        <FormCheckbox
          control={control}
          bindSchemaFieldName="isSyncAzureSubscriptionsEnable"
          label="Sync Azure Subscriptions"
          testId="Sync Azure Subscriptions"
          disabled={!isBillingSyncEnableWatch || isSaving}
          isLoading={isProgramConfigFetching}
        />
        <FormCheckbox
          control={control}
          bindSchemaFieldName="isSyncAnnualSubscriptionsEnable"
          label="Sync Annual Subscriptions"
          testId="Sync Annual Subscriptions"
          disabled={!isBillingSyncEnableWatch || isSaving}
          isLoading={isProgramConfigFetching}
        />
        {(target === Target.Syncro || target === Target.ConnectWise) && (
          <FormCheckbox
            control={control}
            bindSchemaFieldName="isSetNewProductsTaxableEnable"
            label="Set New Products as Taxable"
            testId="Set New Products as Taxable"
            disabled={!isBillingSyncEnableWatch || isSaving}
            isLoading={isProgramConfigFetching}
          />
        )}
      </Box>
      {target === Target.Autotask && (
        <AutotaskExtraBillingSettings
          control={control}
          resetField={resetField}
          disabled={!isBillingSyncEnableWatch || isSaving}
          billingCodeId={autotaskBillingCodeId}
          vendorId={autotaskVendorId}
        />
      )}
    </>
  );

  const ProductSection = (
    <>
      <Box display="flex">
        <Typography variant="subtitle1">Product</Typography>
        <FormSwitch
          control={control}
          bindSchemaFieldName="isProductSyncEnable"
          testId="isProductSyncEnable"
          sxRoot={{ ml: "auto" }}
          isLoading={isProgramConfigFetching}
          disabled={isSaving || !isProgramSyncEnableWatch}
        />
      </Box>

      <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
        {/* Product Sync Mode select */}
        <ControllerFormSelect
          control={control}
          bindSchemaFieldName="productSyncMode"
          label="Product Sync Mode"
          testId="Product Sync Mode"
          options={productSyncModeOptions}
          sxRoot={{ minWidth: 170, ...(!smMatch && { flex: 1 }) }}
          disabled={!isProductSyncEnableWatch || isSaving}
          isLoading={isProgramConfigFetching}
        />

        {/* Product Bill Motion select */}
        {productSyncModeWatch === ProductSyncMode.AllProducts && (
          <>
            <ControllerFormSelect
              control={control}
              bindSchemaFieldName="productBillingModes"
              label="Product Billing Mode"
              testId="Product Billing Mode"
              options={productBillingModeOptions}
              sxRoot={{ minWidth: 270, flex: 1 }}
              multiple
              disabled={!isProductSyncEnableWatch || isSaving}
              required={isProductSyncEnableWatch}
              isLoading={isProgramConfigFetching}
            />
            <NormalButton
              component={LinkBehavior}
              href={AppRoutes.programProductSyncPreview.buildRoute(source, target, program)}
              disabled={isPreviewDisable || isSaving}
            >
              Preview
            </NormalButton>
          </>
        )}
      </Box>
    </>
  );

  const ProgramSection = (
    <>
      <Box display="flex">
        <Typography variant="h6">{program}</Typography>
        <FormSwitch
          control={control}
          bindSchemaFieldName="isProgramSyncEnable"
          testId="isProgramSyncEnable"
          sxRoot={{ ml: "auto" }}
          isLoading={isProgramConfigFetching}
          disabled={isSaving}
        />
      </Box>
      <FormTextWithChips
        control={control}
        bindSchemaFieldName="emailList"
        label="Email notifications"
        testId="Email notifications"
        isLoading={isProgramConfigFetching}
        disabled={isSaving || !isProgramSyncEnableWatch}
      />
    </>
  );

  /* Buttons section */

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);

    const targetJsonConfig =
      target === Target.Autotask
        ? ({
            syncAnnualBilled: formData.isSyncAnnualSubscriptionsEnable,
            syncAzure: formData.isSyncAzureSubscriptionsEnable,
            billingCodeId: Number(formData.autotaskBillingCodeId),
            vendorId: Number(formData.autotaskVendorId),
          } as AutotaskMicrosoftCspProgramConfiguration)
        : ({
            syncAnnualBilled: formData.isSyncAnnualSubscriptionsEnable,
            syncAzure: formData.isSyncAzureSubscriptionsEnable,
            taxableProducts: formData.isSetNewProductsTaxableEnable,
          } as MicrosoftCspProgramConfiguration);

    const productSyncBillingMode = combineEnumFlags(...formData.productBillingModes);

    const billingMotions = combineEnumFlags(...formData.billingMotions);

    const config: ProgramConfigurationPostData = {
      emails: formData.emailList ?? [],
      isSyncing: formData.isProgramSyncEnable,
      programType: program,
      isBillingSyncing: formData.isBillingSyncEnable,
      activeBillingMotions: billingMotions?.length
        ? (billingMotions as BillingMotion)
        : BillingMotion.None,
      isProductsSyncing: formData.isProductSyncEnable,
      productSyncMode: formData.productSyncMode as ProductSyncMode,
      productSyncBillingMode: productSyncBillingMode?.length
        ? (productSyncBillingMode as ProductSyncBillingMode)
        : ProductSyncBillingMode.None,
      configuration: targetJsonConfig,
    };
    try {
      await programsClient.saveProgramConfiguration(source, target, config);
      raiseSuccessNotification(NotificationMessage.PROGRAM_CONFIG_SAVED);
      invalidatePartnerConfig();
      navigate(AppRoutes.programs.route);
    } catch (e: unknown) {
      setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_PROGRAM_CONFIG);
    }

    setIsSaving(false);
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={2}>
          {ProgramSection}
          <Divider />

          {BillingSection}
          <Divider />

          {ProductSection}
          <Divider />

          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons
            cancelRoute={AppRoutes.programs.route}
            isSaveDisable={isProgramConfigFetching || !isDirty}
            isSaveLoading={isSaving}
          />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default ProgramEditForm;
