import { Box, Divider, Paper, Skeleton, Stack, Typography } from "@crayon/design-system-react";
import ProductSyncDetailsTable from "components/hoc/Sync/Product/ProductSyncDetailsTable";
import SyncStatistics from "components/hoc/Sync/SyncStatistics";
import TextHeader from "components/primitives/TextHeader";
import useProductDigest from "hooks/api/useProductDigest";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import SyncRouteParams from "types/sync-route-params";

interface ProductSyncHistoryDetailsRouteParams extends SyncRouteParams {
  id: string;
}

const ProductSyncHistoryDetailsPage = () => {
  const { source, target, program, id } = useParams<
    keyof ProductSyncHistoryDetailsRouteParams
  >() as ProductSyncHistoryDetailsRouteParams;

  const { productDigest, isProductDigestFetching } = useProductDigest(id);

  const StatsHeaderSlice = (
    <Box p={4} display="flex" justifyContent="space-between">
      <SyncStatistics
        successCount={productDigest?.stats?.syncCount ?? 0}
        errorCount={productDigest?.stats?.errorCount ?? 0}
        warningCount={productDigest?.stats?.warningCount ?? 0}
        isLoading={isProductDigestFetching}
        syncType="Product"
      />
      <Box>
        {isProductDigestFetching ? (
          <Skeleton width={180} />
        ) : (
          // prettier-ignore
          <Typography testId="Sync Date Time" variant="body1">
              {productDigest?.stats?.when
                ? DateTime.fromJSDate(new Date(productDigest!.stats!.when)).extToDateTimeString()
                : "Unknown"}
            </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Stack spacing={2}>
      <TextHeader
        testId="page-header"
        label={`${program} Products Sync Details | ${source} > ${target}`}
      />

      <Box component={Paper}>
        {StatsHeaderSlice}
        <Divider />
        <ProductSyncDetailsTable
          productDigest={productDigest}
          isProductDigestFetching={isProductDigestFetching}
        />
      </Box>
    </Stack>
  );
};

export default ProductSyncHistoryDetailsPage;
