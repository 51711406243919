import { DateTime } from "luxon";
import TableBodyCell from "./TableBodyCell";

interface TableBodyDateCellProps {
  date: Date | undefined;
}

/* Can't include date to the prop type of TableBodyCell
 * because fields of Date type returned from API are strings ( instance of Date returns false).
 * So there is no way to differentiate them to display as date string in particular format.
 */

const TableBodyDateCell = (props: TableBodyDateCellProps) => {
  const { date } = props;

  return (
    <TableBodyCell label={date ? DateTime.fromJSDate(new Date(date)).extToDateString() : date} />
  );
};

export default TableBodyDateCell;
