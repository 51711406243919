import { PriceRuleAllowedAction } from "api/client.generated";
import React, { createContext, useContext, useMemo, useState } from "react";

interface ISamplePricingContext {
  action: PriceRuleAllowedAction | undefined;
  setAction: React.Dispatch<React.SetStateAction<PriceRuleAllowedAction | undefined>>;
  rate: number | undefined;
  setRate: React.Dispatch<React.SetStateAction<number | undefined>>;
  isActionRateValidFn: (() => Promise<boolean>) | undefined;
  setIsActionRateValidFn: React.Dispatch<
    React.SetStateAction<(() => Promise<boolean>) | undefined>
  >;
}

const SamplePricingContext = createContext<ISamplePricingContext | null>(null);

interface SamplePricingContextProviderProps {
  children: React.ReactNode;
}

export const SamplePricingContextProvider = ({ children }: SamplePricingContextProviderProps) => {
  const [action, setAction] = useState<PriceRuleAllowedAction | undefined>();
  const [rate, setRate] = useState<number | undefined>();
  const [isActionRateValidFn, setIsActionRateValidFn] = useState<() => Promise<boolean>>();

  const contextValue = useMemo<ISamplePricingContext>(
    () => ({
      action,
      setAction,
      rate,
      setRate,
      isActionRateValidFn,
      setIsActionRateValidFn,
    }),
    [action, setAction, rate, setRate, isActionRateValidFn, setIsActionRateValidFn],
  );

  // prettier-ignore
  return (
    <SamplePricingContext.Provider value={contextValue}>
      {children}
    </SamplePricingContext.Provider>
  );
};

export const useSamplePricingContext = (): ISamplePricingContext => {
  const context = useContext(SamplePricingContext);
  if (!context)
    throw new Error("useSamplePricingContext must be used within a SamplePricingContextProvider");

  return context;
};
