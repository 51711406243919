import App from "App";
import ConnectionsPage from "pages/Connections/ConnectionsPage";
import EditConnectionPage from "pages/Connections/EditConnectionPage";
import DashboardPage from "pages/DashboardPage";
import ErrorPage from "pages/ErrorPage";
import AddPriceRulePage from "pages/PriceRules/AddPriceRulePage";
import EditPriceRulePage from "pages/PriceRules/EditPriceRulePage";
import PriceRulesPage from "pages/PriceRules/PriceRulesPage";
import EditProgramPage from "pages/Programs/EditProgramPage";
import ProgramProductSyncPreviewPage from "pages/Programs/ProgramProductSyncPreviewPage";
import ProgramsPage from "pages/Programs/ProgramsPage";
import SelectPartnerPage from "pages/SelectPartnerPage";
import SignInPage from "pages/SignInPage";
import AddEditBillingMappingPage from "pages/Sync/Billing/AddEditBillingMappingPage";
import BillingSyncHistoryDetailsPage from "pages/Sync/Billing/BillingSyncHistoryDetailsPage";
import BillingSyncHistoryPage from "pages/Sync/Billing/BillingSyncHistoryPage";
import BillingSyncPage from "pages/Sync/Billing/BillingSyncPage";
import EditProductMappingPage from "pages/Sync/Product/EditProductMappingPage";
import ProductSyncHistoryDetailsPage from "pages/Sync/Product/ProductSyncHistoryDetailsPage";
import ProductSyncHistoryPage from "pages/Sync/Product/ProductSyncHistoryPage";
import ProductSyncPage from "pages/Sync/Product/ProductSyncPage";
import TermsPage from "pages/TermsPage";

import { createBrowserRouter } from "react-router-dom";
import AppRoutes from "routes/app-routes";

/*
  Root
    |-"/"             => DashboardPage
    |-"/signIn"       => SignInPage
    |-"/partners"     => SelectPartnerPage
    |-"terms"         => TermsPage
    |-"/connections"  => ConnectionsPage
    |     |-"/:endpointName/edit" => EditConnectionPage (':type' will may be required if source and target have the same entry)
    |-"/programs"     => ProgramsPage
    |     |-"/:source/:target/:program"
                                |-"/edit"               => EditProgramPage
                                |-"/productSyncPreview" => ProgramProductSyncPreviewPage
    |-"price-rules"     => PriceRulesPage
    |     |-"/:id/edit" => EditPriceRulePage
    |     |-"/add"      => AddPriceRulePage
    |-"/sync/:source/:target/:program"
          |-"/billing"  => BillingSyncOverviewPage
          |     |-"/mappings/:id/edit"  => EditBillingMappingPage
          |     |-"/mappings/add"       => AddBillingMappingPage
          |     |-"/history"            => BillingSyncHistoryOverviewPage
          |           |-"/:id"          => BillingSyncHistoryDetailsPage
          |-"/product"  => ProductSyncOverviewPage
          |     |-"/mappings/:id/edit"  => EditProductMappingPage
          |     |-"/mappings/add"       => AddProductMappingPage
          |     |-"/history"            => ProductSyncHistoryOverviewPage
          |           |-"/:id"          => ProductSyncHistoryDetailsPage

*/

// TODO: Routes values will be updated on API call integration
const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: AppRoutes.dashboard.route,
        element: <DashboardPage />,
      },
      {
        path: AppRoutes.signIn.route,
        element: <SignInPage />,
      },
      {
        path: AppRoutes.selectPartner.route,
        element: <SelectPartnerPage />,
      },
      {
        path: AppRoutes.terms.route,
        element: <TermsPage />,
      },
      {
        path: AppRoutes.connections.route,
        element: <ConnectionsPage />,
      },
      {
        path: AppRoutes.connectionEdit.route,
        element: <EditConnectionPage />,
      },
      {
        path: AppRoutes.programs.route,
        element: <ProgramsPage />,
      },
      {
        path: AppRoutes.programEdit.route,
        element: <EditProgramPage />,
      },
      {
        path: AppRoutes.programProductSyncPreview.route,
        element: <ProgramProductSyncPreviewPage />,
      },
      {
        path: AppRoutes.priceRules.route,
        element: <PriceRulesPage />,
      },
      {
        path: AppRoutes.priceRuleAdd.route,
        element: <AddPriceRulePage />,
      },
      {
        path: AppRoutes.priceRuleEdit.route,
        element: <EditPriceRulePage />,
      },
      {
        path: AppRoutes.billingSync.route,
        element: <BillingSyncPage />,
      },
      {
        path: AppRoutes.billingSyncAddMapping.route,
        element: <AddEditBillingMappingPage />,
      },
      {
        path: AppRoutes.billingSyncEditMapping.route,
        element: <AddEditBillingMappingPage />,
      },
      {
        path: AppRoutes.billingSyncHistory.route,
        element: <BillingSyncHistoryPage />,
      },
      {
        path: AppRoutes.billingSyncHistoryDetails.route,
        element: <BillingSyncHistoryDetailsPage />,
      },
      {
        path: AppRoutes.productSync.route,
        element: <ProductSyncPage />,
      },
      {
        path: AppRoutes.productSyncEditMapping.route,
        element: <EditProductMappingPage />,
      },
      {
        path: AppRoutes.productSyncHistory.route,
        element: <ProductSyncHistoryPage />,
      },
      {
        path: AppRoutes.productSyncHistoryDetails.route,
        element: <ProductSyncHistoryDetailsPage />,
      },
    ],
  },
]);

export default router;
