import {
  Box,
  Button,
  Card,
  LinkBehavior,
  Skeleton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import CardHeader from "components/primitives/CardHeader";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import { useSyncOverviewContext } from "context/syncOverviewContext";
import useSyncHistory from "hooks/api/useSyncHistory";
import { useMemo } from "react";
import AppRoutes from "routes/app-routes";

interface SyncHistoryCardProps {
  sx?: SxProps<Theme>;
}

const SyncHistoryCard = ({ sx = undefined }: SyncHistoryCardProps) => {
  const { source, target, program, syncType } = useSyncOverviewContext();

  if (syncType !== "Billing" && syncType !== "Product")
    throw new Error(`Unsupported sync type ${syncType}`);

  const { syncHistory, isSyncHistoryFetching } = useSyncHistory(
    source,
    target,
    program,
    syncType,
    1,
    3,
  );

  const theme = useTheme();
  const paperColor = theme.palette.background.paper;

  const columnOptions = useMemo<string[]>(
    () =>
      syncType === "Billing"
        ? ["When", "Customers", "Errors", "Subscriptions"]
        : ["When", "Synched", "Errors", "Warnings"],
    [syncType],
  );

  const viewAllHistoryRoute = useMemo<string>(
    () =>
      syncType === "Billing"
        ? AppRoutes.billingSyncHistory.buildRoute(source, target, program)
        : AppRoutes.productSyncHistory.buildRoute(source, target, program),
    [source, target, program, syncType],
  );

  // prettier-ignore
  const getHistoryDetailsRoute = (digestId: string) => syncType === "Billing"
    ? AppRoutes.billingSyncHistoryDetails.buildRoute(source, target, program, digestId)
    : AppRoutes.productSyncHistoryDetails.buildRoute(source, target, program, digestId);

  const renderHeaderCell = (data: string, i: number) => (
    <TableHeaderCell
      key={data + String(i)}
      columnName={data}
      colSpan={i === columnOptions.length - 1 ? 2 : 1}
    />
  );

  const historyDataTableBody = (
    <TableBody>
      {syncHistory?.slice(0, 3).map((sync, i, slicedItems) => {
        const lastRowSx: SxProps<Theme> =
          i === slicedItems.length - 1 ? { borderBottom: "none" } : {};

        return (
          <TableRow key={sync.when + String(i)}>
            <TableBodyCell label={sync.when.extToDateTimeString()} sxRoot={lastRowSx} />
            <TableBodyCell label={`${sync.syncCount}`} sxRoot={lastRowSx} />
            <TableBodyCell label={`${sync.errorCount}`} sxRoot={lastRowSx} />
            <TableBodyCell
              label={`${syncType === "Billing" ? sync.secondarySyncCount : sync.warningCount}`}
              sxRoot={lastRowSx}
            />
            <TableCell align="right" sx={lastRowSx}>
              <Button
                testId="View"
                component={LinkBehavior}
                href={getHistoryDetailsRoute(sync.digestId)}
                variant="text"
                size="small"
              >
                View
              </Button>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );

  const renderSkeletonCell = () => (
    <TableCell>
      <Skeleton />
    </TableCell>
  );

  const skeletonTableBody = (
    <TableBody>
      {Array.from(Array(3).keys()).map((x) => (
        <TableRow key={x}>
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <Card component={Box} sx={{ ...sx }}>
      <CardHeader>
        <Typography variant="h5" testId="History" sx={{ color: paperColor }}>
          History
        </Typography>
        {!isSyncHistoryFetching && Boolean(syncHistory?.length) && (
          <Button
            component={LinkBehavior}
            href={viewAllHistoryRoute}
            variant="text"
            testId="View All"
            sx={{ color: paperColor, ml: "auto" }}
          >
            View All
          </Button>
        )}
      </CardHeader>
      <TableContainer component={Box}>
        <Table testId="Sync History">
          <TableHead>
            <TableRow>{columnOptions.map(renderHeaderCell)}</TableRow>
          </TableHead>
          {isSyncHistoryFetching ? skeletonTableBody : historyDataTableBody}
        </Table>
      </TableContainer>
    </Card>
  );
};

export default SyncHistoryCard;
