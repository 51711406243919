import { GetPartnerConfigurationResponseV2 } from "api/client.generated";
import usePartnerConfiguration from "hooks/api/usePartnerConfiguration";
import useRouteDetector from "hooks/useRouteDetector";
import React, { createContext, useContext, useMemo } from "react";

interface IPartnerConfigContext {
  partnerConfig: GetPartnerConfigurationResponseV2 | undefined;
  isPartnerConfigFetching: boolean;
  invalidatePartnerConfig: () => void;
}

const PartnerConfigContext = createContext<IPartnerConfigContext | null>(null);

interface PartnerConfigContextProviderProps {
  children: React.ReactNode;
}

export const PartnerConfigContextProvider = ({ children }: PartnerConfigContextProviderProps) => {
  const { isSelectPartnerRoute, isSignInRoute, isTermsRoute } = useRouteDetector();
  const { partnerConfig, isPartnerConfigFetching, invalidatePartnerConfig } =
    usePartnerConfiguration(!isSelectPartnerRoute && !isSignInRoute && !isTermsRoute);

  const ctxValue = useMemo(
    () => ({ partnerConfig, isPartnerConfigFetching, invalidatePartnerConfig }),
    [partnerConfig, isPartnerConfigFetching, invalidatePartnerConfig],
  );

  // prettier-ignore
  return (
    <PartnerConfigContext.Provider value={ctxValue}>
      {children}
    </PartnerConfigContext.Provider>
  );
};

export const usePartnerConfigContext = (): IPartnerConfigContext => {
  const context = useContext(PartnerConfigContext);
  if (!context) {
    throw new Error("usePartnerConfigContext must be used within a PartnerConfigContextProvider");
  }

  return context;
};
