import { useTheme } from "@crayon/design-system-react";
import { SyncOutcome } from "api/client.generated";
import { ErrorCircleIcon, WarningCirceIcon } from "images/MuiIcons";

interface SyncOutcomeIconProps {
  syncOutcome: SyncOutcome;
  isAnyWarnings?: boolean;
}

const SyncOutcomeIcon = ({ syncOutcome, isAnyWarnings = false }: SyncOutcomeIconProps) => {
  const theme = useTheme();

  if (syncOutcome === SyncOutcome.Error)
    return <ErrorCircleIcon sx={{ color: theme.palette.error.main }} />;

  if (syncOutcome === SyncOutcome.Warning || isAnyWarnings)
    return <WarningCirceIcon sx={{ color: theme.palette.warning.main }} />;

  return null;
};

export default SyncOutcomeIcon;
