import { Box, Paper, Stack } from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { SaveSyncroConfigurationRequestModel } from "api/client.generated";
import { ValidationException } from "api/client.generated.extensions";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useApi from "hooks/api/useApi";
import useSyncroConfiguration from "hooks/api/useSyncroConfiguration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import * as yup from "yup";

const SyncroConnectionForm = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { syncroConfig, isSyncroConfigFetching, isSyncroConfigFetchingError } =
    useSyncroConfiguration();

  const { syncroConfigurationClient } = useApi();
  const navigate = useNavigate();
  const { raiseSuccessNotification, raiseWarningNotification, raiseErrorNotification } =
    useNotificationContext();
  const { invalidatePartnerConfig } = usePartnerConfigContext();

  const [formErrorMsg, setFormErrorMsg] = useState("");

  const formSchema = yup.object().shape({
    companyUrl: yup.string().required("Required"),
    apiToken: yup.string().required("Required"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const { control, handleSubmit, reset } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
  });

  // to update form values when sourceConfig is loaded
  useEffect(() => {
    if (!syncroConfig) return;

    reset({ companyUrl: syncroConfig.companyUrl });
  }, [syncroConfig, reset]);

  useEffect(() => {
    if (isSyncroConfigFetchingError)
      raiseWarningNotification(NotificationMessage.FAILED_TO_GET_SYNCRO_CFG);
  }, [isSyncroConfigFetchingError, raiseWarningNotification]);

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);
    try {
      await syncroConfigurationClient.saveSyncroConfiguration({
        companyUrl: formData.companyUrl,
        apiToken: formData.apiToken,
      } as SaveSyncroConfigurationRequestModel);

      raiseSuccessNotification(NotificationMessage.CONNECTION_SAVED);
      invalidatePartnerConfig();
      navigate(AppRoutes.connections.route);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      if (validationError) {
        setFormErrorMsg(validationError.getFirstErrorMessage());
      } else {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      }
      raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_CONNECTION);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={2}>
          <FormTextField
            control={control}
            bindSchemaFieldName="companyUrl"
            label="Company URL"
            testId="Company URL"
            isLoading={isSyncroConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            label="API Token"
            testId="API Token"
            control={control}
            bindSchemaFieldName="apiToken"
            isLoading={isSyncroConfigFetching}
            disabled={isSaving}
            sensitive
            required
          />
          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons
            cancelRoute={AppRoutes.connections.route}
            isSaveDisable={isSyncroConfigFetching}
            isSaveLoading={isSaving}
          />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default SyncroConnectionForm;
