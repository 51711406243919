import { Box, Paper, Stack } from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { SaveCloudIqConfigurationRequestModel } from "api/client.generated";
import { ValidationException } from "api/client.generated.extensions";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useApi from "hooks/api/useApi";
import useCloudIqConfiguration from "hooks/api/useCloudIqConfiguration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import * as yup from "yup";

const CloudIqConnectionForm = () => {
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { cloudIqConfig, isCloudIqConfigFetching } = useCloudIqConfiguration();
  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();
  const { invalidatePartnerConfig } = usePartnerConfigContext();

  const { cloudIqConfigurationClient } = useApi();
  const navigate = useNavigate();

  const formSchema = yup.object().shape({
    clientId: yup.string().required("Required"),
    clientSecret: yup.string().required("Required"),
    organizationId: yup
      .number()
      // this is to prioritize 'Required' vs 'Type error'
      .transform((value, origin) => (origin.toString() === "" ? undefined : value))
      .typeError("Must be a number")
      .min(0, "Must be positive")
      .required("Required"),
    userName: yup.string().required("Required"),
    userPassword: yup.string().required("Required"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const { control, handleSubmit, reset } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
  });

  // to update form values when sourceConfig is loaded
  useEffect(() => {
    if (!cloudIqConfig) return;

    reset({
      clientId: cloudIqConfig.clientId,
      organizationId: cloudIqConfig.organizationId,
      userName: cloudIqConfig.clientUsername,
    });
  }, [cloudIqConfig, reset]);

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);
    try {
      await cloudIqConfigurationClient.saveCloudIqConfiguration({
        clientId: formData.clientId,
        clientSecret: formData.clientSecret,
        organizationId: formData.organizationId,
        username: formData.userName,
        password: formData.userPassword,
      } as SaveCloudIqConfigurationRequestModel);

      raiseSuccessNotification(NotificationMessage.CONNECTION_SAVED);
      invalidatePartnerConfig();
      navigate(AppRoutes.connections.route);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      if (validationError) {
        setFormErrorMsg(validationError.getFirstErrorMessage());
      } else {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      }
      raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_CONNECTION);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={2}>
          <FormTextField
            control={control}
            bindSchemaFieldName="organizationId"
            label="Organization ID"
            testId="Organization ID"
            isLoading={isCloudIqConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            control={control}
            bindSchemaFieldName="clientId"
            label="Client ID"
            testId="Client ID"
            isLoading={isCloudIqConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            label="Client Secret"
            testId="Client Secret"
            control={control}
            bindSchemaFieldName="clientSecret"
            isLoading={isCloudIqConfigFetching}
            disabled={isSaving}
            sensitive
            required
          />
          <FormTextField
            control={control}
            bindSchemaFieldName="userName"
            label="User Name"
            testId="User Name"
            isLoading={isCloudIqConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            control={control}
            bindSchemaFieldName="userPassword"
            label="User Password"
            testId="User Password"
            isLoading={isCloudIqConfigFetching}
            disabled={isSaving}
            sensitive
            required
          />
          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons
            cancelRoute={AppRoutes.connections.route}
            isSaveDisable={isCloudIqConfigFetching}
            isSaveLoading={isSaving}
          />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default CloudIqConnectionForm;
