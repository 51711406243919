import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Skeleton,
  SxProps,
  Theme,
} from "@crayon/design-system-react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface FormCheckboxProps<TField extends FieldValues> {
  bindSchemaFieldName: Path<TField>;
  control: Control<TField>;
  label: string;
  sxRoot?: SxProps<Theme>;
  disabled?: boolean;
  isLoading?: boolean;
  testId?: string;
}

const FormCheckbox = <TField extends FieldValues>({
  bindSchemaFieldName,
  control,
  label,
  sxRoot = undefined,
  disabled = false,
  isLoading = false,
  testId = undefined,
}: FormCheckboxProps<TField>) => (
  <Controller
    control={control}
    name={bindSchemaFieldName}
    render={({ field, fieldState }) => (
      <FormControl error={Boolean(fieldState.error)} sx={{ ...sxRoot }}>
        {isLoading ? (
          <Skeleton variant="text" height={25} width={100} />
        ) : (
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                {...field}
                checked={field.value}
                disabled={disabled}
                {...(testId && { testId })}
              />
            }
          />
        )}
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      </FormControl>
    )}
  />
);

export default FormCheckbox;
