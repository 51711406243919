import { Box, Collapse, ListItem, ListItemButton, ListItemText } from "@crayon/design-system-react";
import { ConfiguredProgramsV2 } from "api/client.generated";
import useToggle from "hooks/useToggle";
import {
  ArrowRightOutlinedIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  Inventory2OutlinedIcon,
  ReceiptOutlinedIcon,
  SyncDisabledIcon,
} from "images/MuiIcons";
import AppRoutes from "routes/app-routes";
import { Endpoint } from "types/Endpoint";
import SideMenuItem from "./SideMenuItem";

interface SideMenuItemSyncGroupProps {
  configuredPrograms: ConfiguredProgramsV2;
}

const SideMenuItemSyncGroup = ({ configuredPrograms }: SideMenuItemSyncGroupProps) => {
  const [isGroupOpen, toggleGroupOpen] = useToggle(false);
  return (
    <>
      {/* Source => Target ListItem */}
      <ListItemButton onClick={() => toggleGroupOpen()}>
        <Box component="img" src={Endpoint.getLogo(configuredPrograms.source)} width="90px" />
        <ArrowRightOutlinedIcon />
        <Box component="img" src={Endpoint.getLogo(configuredPrograms.target)} width="90px" />
        <Box ml="auto">{isGroupOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
      </ListItemButton>
      <Collapse in={isGroupOpen}>
        {configuredPrograms.programs?.map((program) => (
          <Box key={program.type}>
            <ListItem key={program.name}>
              <ListItemText
                sx={{ pl: 2 }}
                primaryTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: "medium",
                }}
                primary={program.name}
              />
            </ListItem>
            <SideMenuItem
              text="Billing"
              testId="Billing"
              menuIcon={<ReceiptOutlinedIcon />}
              to={AppRoutes.billingSync.buildRoute(
                configuredPrograms.source,
                configuredPrograms.target,
                program.type,
              )}
              sx={{ pl: 6 }}
              trailingIcon={program.isBillingSyncing ? undefined : <SyncDisabledIcon />}
            />
            <SideMenuItem
              text="Product"
              testId="Product"
              menuIcon={<Inventory2OutlinedIcon />}
              to={AppRoutes.productSync.buildRoute(
                configuredPrograms.source,
                configuredPrograms.target,
                program.type,
              )}
              sx={{ pl: 6 }}
              trailingIcon={program.isProductsSyncing ? undefined : <SyncDisabledIcon />}
            />
          </Box>
        ))}
      </Collapse>
    </>
  );
};

export default SideMenuItemSyncGroup;
