import { Box, useTheme } from "@crayon/design-system-react";
import React from "react";

interface CardHeaderProps {
  statusSuccess?: boolean;
  showStatus?: boolean;
  children: React.ReactNode;
}

const CardHeader = ({ statusSuccess = false, showStatus = false, children }: CardHeaderProps) => {
  const theme = useTheme();
  const height = showStatus ? "64px" : "69px";
  return (
    <>
      {showStatus && (
        <Box
          sx={{
            width: "100%",
            minHeight: "5px",
            maxHeight: "5px",
            bgcolor: statusSuccess ? theme.palette.success.main : theme.palette.error.main,
          }}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        sx={{
          width: "100%",
          maxHeight: height,
          minHeight: height,
          bgcolor: theme.palette.secondary.main,
          px: 2,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default CardHeader;
