import { AppBar, Box, Theme, Toolbar, Typography } from "@crayon/design-system-react";
import Logo from "images/Logo/Logo.svg";
import { ArrowRightOutlinedIcon } from "images/MuiIcons";
import React from "react";

interface TopbarProps {
  partnerName?: string;
  children?: React.ReactNode;
  beforeLogoElement?: React.ReactNode;
}

const Topbar = ({ partnerName, children, beforeLogoElement }: TopbarProps) => (
  <AppBar
    enableColorOnDark
    position="fixed"
    sx={{
      zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
      opacity: "100%",
    }}
  >
    <Toolbar>
      {beforeLogoElement}
      <Box display="flex" alignItems="center" gap={1}>
        <Box component="img" src={Logo} height="24px" />
        <Typography variant="h6">Billing Sync</Typography>
        {partnerName && (
          <>
            <ArrowRightOutlinedIcon color="primary" />
            <Typography variant="h6" testId="selected-partner">{`${partnerName}`}</Typography>
          </>
        )}
      </Box>
      <Box sx={{ ml: "auto" }} display="flex" gap={1}>
        {children}
      </Box>
    </Toolbar>
  </AppBar>
);

export default Topbar;
