import { useQuery } from "@tanstack/react-query";
import { IPriceRuleAllowedAction } from "api/client.generated";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import useApi from "./useApi";

interface UsePriceRuleAllowedActionsReturn {
  allowedActions: IPriceRuleAllowedAction[] | undefined;
  isAllowedActionsFetching: boolean;
}

const usePriceRuleAllowedActions = (): UsePriceRuleAllowedActionsReturn => {
  const { priceRulesClient } = useApi();
  const { partner } = useSelectedPartnerContext();

  // TODO: refactor API to get partnerId from header
  const loadData = async (): Promise<IPriceRuleAllowedAction[] | undefined> => {
    const response = await priceRulesClient.getAllowedActions(
      partner?.id ?? "",
    );
    return response.priceRuleAllowedActions;
  };

  const { data: allowedActions, isFetching: isAllowedActionsFetching } =
    useQuery({
      queryKey: ["getAllowedActions"],
      queryFn: loadData,
    });

  return { allowedActions, isAllowedActionsFetching };
};

export default usePriceRuleAllowedActions;
