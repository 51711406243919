import { Box, Button, Stack } from "@crayon/design-system-react";
import { SyncRounded } from "@mui/icons-material";
import { ProgramType, Source, Target } from "api/client.generated";
import LastSyncCard from "components/hoc/Sync/LastSyncCard";
import SyncHistoryCard from "components/hoc/Sync/SyncHistoryCard";
import TextHeader from "components/primitives/TextHeader";
import { useNotificationContext } from "context/notificationContext";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import { SyncOverviewContextProvider } from "context/syncOverviewContext";
import useApi from "hooks/api/useApi";
import { useMemo } from "react";
import SyncType from "types/sync-type";
import BillingMappingOverview from "./BillingMappingOverview";
import ProductMappingOverview from "./ProductMappingOverview";

export interface SyncOverviewProps {
  syncType: SyncType;
  source: Source;
  target: Target;
  program: ProgramType;
}

const SyncOverview = (props: SyncOverviewProps) => {
  const { source, target, program, syncType } = props;

  const { partnerConfig } = usePartnerConfigContext();
  const { customerMappingsClient, productsClient } = useApi();
  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();

  const isSyncEnable = useMemo<boolean>(() => {
    const programConfig = partnerConfig?.configuredPrograms
      ?.find((p) => p.source === source && p.target === target)
      ?.programs?.find((p) => p.type === program);

    switch (syncType) {
      case "Billing":
        return Boolean(programConfig?.isBillingSyncing);
      case "Product":
        return Boolean(programConfig?.isProductsSyncing);
      default:
        throw new Error("Unsupported sync type");
    }
  }, [partnerConfig, source, target, program, syncType]);

  const onSyncClick = async () => {
    try {
      if (syncType === "Billing") {
        await customerMappingsClient.syncCustomers(source, target, program);
        raiseSuccessNotification("Syncing billing queued");
      } else {
        // Products sync
        await productsClient.syncProducts(source, target, program);
        raiseSuccessNotification("Syncing products queued");
      }
    } catch (e: unknown) {
      raiseErrorNotification("Failed to queue sync request");
    }
  };

  const HeaderSlice = (
    <Box display="flex" alignItems="center">
      <TextHeader
        testId="page-header"
        label={`${syncType} Sync | ${source} > ${target} > ${program}`}
      />
      <Button
        variant="contained"
        sx={{ ml: "auto" }}
        startIcon={<SyncRounded />}
        onClick={onSyncClick}
        disabled={!isSyncEnable}
      >
        Sync
      </Button>
    </Box>
  );

  return (
    <SyncOverviewContextProvider
      source={source}
      target={target}
      program={program}
      syncType={syncType}
    >
      <Stack spacing={3}>
        {HeaderSlice}
        <Box display="flex" gap={3} flexWrap="wrap">
          <LastSyncCard sx={{ flex: 1, minWidth: "350px" }} />
          <SyncHistoryCard sx={{ flex: 1, minWidth: "350px" }} />
        </Box>
        {syncType === "Billing" && <BillingMappingOverview />}
        {syncType === "Product" && <ProductMappingOverview />}
      </Stack>
    </SyncOverviewContextProvider>
  );
};

export default SyncOverview;
