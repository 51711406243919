import { Box, CircularProgress, Typography } from "@crayon/design-system-react";
import ThemeProviderWrapper from "components/layout/ThemeProviderWrapper";

const LoadingScreen = () => (
  <ThemeProviderWrapper>
    <Box
      height="95vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      <CircularProgress />
      <Typography variant="body1">Loading, please wait</Typography>
    </Box>
  </ThemeProviderWrapper>
);

export default LoadingScreen;
