import {
  Button,
  Card,
  CardActions,
  CardContent,
  LinkBehavior,
  Paper,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import CardHeader from "components/primitives/CardHeader";
import { useSyncOverviewContext } from "context/syncOverviewContext";
import useSyncHistory from "hooks/api/useSyncHistory";
import useSyncTypeGuard from "hooks/useSyncTypeGuard";
import { useMemo } from "react";
import AppRoutes from "routes/app-routes";
import SyncStatistics from "./SyncStatistics";

interface LastSyncCardProps {
  sx?: SxProps<Theme>;
}

const LastSyncCard = ({ sx = undefined }: LastSyncCardProps) => {
  const { source, target, program, syncType } = useSyncOverviewContext();

  const { syncHistory, isSyncHistoryFetching } = useSyncHistory(
    source,
    target,
    program,
    syncType,
    0,
    1,
  );

  useSyncTypeGuard(syncType);

  const viewDigestRoute = useMemo<string>(() => {
    if (!syncHistory?.at(0)?.digestId.length) return "";

    return syncType === "Billing"
      ? AppRoutes.billingSyncHistoryDetails.buildRoute(
          source,
          target,
          program,
          syncHistory!.at(0)!.digestId,
        )
      : AppRoutes.productSyncHistoryDetails.buildRoute(
          source,
          target,
          program,
          syncHistory!.at(0)!.digestId,
        );
  }, [syncHistory, syncType, source, target, program]);

  const lastSyncStats = syncHistory?.at(0);

  const theme = useTheme();
  const paperColor = theme.palette.background.paper;

  return (
    <Card component={Paper} sx={{ ...sx }} testId="Last Sync">
      <CardHeader showStatus statusSuccess={(lastSyncStats?.errorCount ?? 0) === 0}>
        <Typography variant="h5" testId="LastSync" sx={{ color: paperColor }}>
          Last Sync
        </Typography>
        {!isSyncHistoryFetching && (
          <Typography variant="h5" testId="LastSyncDateTime" sx={{ color: paperColor, ml: "auto" }}>
            {lastSyncStats?.when?.extToDateTimeString()}
          </Typography>
        )}
      </CardHeader>
      <CardContent sx={{ py: 4, px: 6 }}>
        <SyncStatistics
          errorCount={lastSyncStats?.errorCount ?? 0}
          warningCount={lastSyncStats?.warningCount ?? 0}
          successCount={lastSyncStats?.syncCount ?? 0}
          isLoading={isSyncHistoryFetching}
          syncType={syncType}
          secondarySuccessCount={lastSyncStats?.secondarySyncCount}
          invoices={lastSyncStats?.invoices}
        />
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: 2,
          ...(syncType === "Product" && { marginTop: 2 }),
        }}
      >
        <Button
          component={LinkBehavior}
          href={viewDigestRoute}
          disabled={isSyncHistoryFetching || !lastSyncStats}
          testId="View"
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};

export default LastSyncCard;
