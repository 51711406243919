import {
  Box,
  Divider,
  FormSelect,
  FormSelectChangeEvent,
  FormSelectOption,
  Paper,
  Stack,
  TextField,
  useTheme,
} from "@crayon/design-system-react";
import { ProductMapping, TargetProductType } from "api/client.generated";

import ProductMappingTable, {
  ProductMappingTableRef,
} from "components/hoc/Sync/Product/ProductMappingTable";
import { useSyncOverviewContext } from "context/syncOverviewContext";
import useProductMappings from "hooks/api/useProductMappings";
import useDebounce from "hooks/useDebounce";
import { useEffect, useMemo, useRef, useState } from "react";

const ProductMappingOverview = () => {
  const theme = useTheme();
  const [targetTypeFilter, setTargetTypeFilter] = useState("All");
  const [searchFilter, setSearchFilter] = useState("");
  const searchFilterDebounced = useDebounce(searchFilter);

  const { source, target, program } = useSyncOverviewContext();
  const { productMappings, isProductMappingsFetching } = useProductMappings(
    source,
    target,
    program,
  );

  // prettier-ignore
  const [filteredMappings, setFilteredMappings] = useState<ProductMapping[]>([]);

  const targetTypeOptions = useMemo<FormSelectOption[]>(
    () => [
      { value: "All", label: "All" },
      { value: TargetProductType.Product, label: TargetProductType.Product },
      { value: TargetProductType.Service, label: TargetProductType.Service },
    ],
    [],
  );

  const tableRef = useRef<ProductMappingTableRef>(null);

  useEffect(() => {
    const filterMappings = (mapping: ProductMapping): boolean => {
      if (targetTypeFilter !== "All") {
        if (mapping.targetType !== targetTypeFilter) return false;
      }

      if (searchFilterDebounced !== "") {
        const filter = searchFilterDebounced.toLocaleLowerCase();
        const match =
          mapping.sku?.toLocaleLowerCase().includes(filter) ||
          mapping.sourceName?.toLocaleLowerCase().includes(filter) ||
          mapping.targetName?.toLocaleLowerCase().includes(filter) ||
          mapping.billingCycle.toLocaleLowerCase().includes(filter) ||
          `${mapping.billingTermDuration}`.toLocaleLowerCase().includes(filter) ||
          mapping.billingTerm?.toLocaleLowerCase().includes(filter) ||
          `${mapping.targetId}`.toLocaleLowerCase().includes(filter);

        return Boolean(match);
      }

      return true;
    };

    // See BillingMappingOverview for detailed explanation. Same applied here
    tableRef.current?.resetSelectedPage();

    setFilteredMappings(productMappings?.filter(filterMappings) ?? []);
  }, [productMappings, searchFilterDebounced, targetTypeFilter]);

  const FilterSlice = (
    <Box display="flex" gap={3} flexWrap="wrap">
      <TextField
        label="Search"
        testId="Search"
        onChange={(event) => setSearchFilter(event.target.value)}
        sx={{
          flex: 1,
          input: {
            color: theme.palette.secondary.main,
            "&::placeholder": { color: "grey" },
          },
        }}
      />
      <FormSelect
        id="target-type-lbl-id"
        testId="Target Type"
        label="Target Type"
        onChange={(event: FormSelectChangeEvent) => setTargetTypeFilter(event.target.value)}
        options={targetTypeOptions}
        sx={{ flex: 1, ".MuiSelect-select": { color: theme.palette.secondary.main } }}
        value={targetTypeFilter}
      />
    </Box>
  );

  return (
    <Stack component={Paper} spacing={3}>
      <Box px={3} pt={3}>
        {FilterSlice}
      </Box>
      <Divider />
      <Box sx={{ "&&": { mt: 0 } }}>
        <ProductMappingTable
          ref={tableRef}
          mappings={filteredMappings}
          isLoading={isProductMappingsFetching}
        />
      </Box>
    </Stack>
  );
};

export default ProductMappingOverview;
