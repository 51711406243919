import { Box, FormSelectOption } from "@crayon/design-system-react";
import ControllerFormSelect from "components/primitives/ControllerFormSelect";
import useAutotaskBillingCodes from "hooks/api/useAutotaskBillingCodes";
import useAutotaskVendors from "hooks/api/useAutotaskVendors";
import useScreen from "hooks/useScreen";
import { useEffect, useMemo } from "react";
import { Control, FieldValues, Path, UseFormResetField } from "react-hook-form";

export interface AutotaskExtraBillingSettingsRef {
  triggerSubmit: () => void;
  isValid: boolean;
}
interface AutotaskExtraBillingSettingsProps<TField extends FieldValues> {
  control: Control<TField>;
  resetField: UseFormResetField<FieldValues>;
  disabled: boolean;
  billingCodeId: string;
  vendorId: string;
}

const AutotaskExtraBillingSettings = <TField extends FieldValues>(
  props: AutotaskExtraBillingSettingsProps<TField>,
) => {
  const { disabled, control, billingCodeId, vendorId, resetField } = props;
  const { billingCodes, isBillingCodesFetching } = useAutotaskBillingCodes();
  const { vendors, isVendorsFetching } = useAutotaskVendors();
  const { smMatch } = useScreen();

  const billingCodeOptions = useMemo<FormSelectOption[]>(
    () =>
      billingCodes?.items?.map((x) => ({ value: `${x.id}`, label: x.name }) as FormSelectOption) ??
      [],
    [billingCodes],
  );

  useEffect(() => {
    if (billingCodes) resetField("autotaskBillingCodeId", { defaultValue: billingCodeId });
  }, [billingCodeId, billingCodes, resetField]);

  const vendorOptions = useMemo<FormSelectOption[]>(
    () =>
      vendors?.items?.map(
        (x) => ({ value: `${x.id}`, label: x.companyName }) as FormSelectOption,
      ) ?? [],
    [vendors],
  );

  useEffect(() => {
    if (vendors) resetField("autotaskVendorId", { defaultValue: vendorId });
  }, [vendorId, vendors, resetField]);

  return (
    <Box display="flex" gap={4} flexWrap="wrap">
      <ControllerFormSelect
        control={control}
        bindSchemaFieldName={"autotaskBillingCodeId" as Path<TField>}
        label="Billing Code"
        testId="Billing Code"
        options={billingCodeOptions}
        sxRoot={{ minWidth: 220, ...(!smMatch && { flex: 1 }) }}
        disabled={disabled}
        required={!disabled}
        isLoading={isBillingCodesFetching}
      />

      <ControllerFormSelect
        control={control}
        bindSchemaFieldName={"autotaskVendorId" as Path<TField>}
        label="Vendor"
        testId="Vendor"
        options={vendorOptions}
        sxRoot={{ minWidth: 220, ...(!smMatch && { flex: 1 }) }}
        disabled={disabled}
        isLoading={isVendorsFetching}
      />
    </Box>
  );
};

export default AutotaskExtraBillingSettings;
