import {
  FormControl,
  FormHelperText,
  Skeleton,
  Switch,
  SxProps,
  Theme,
} from "@crayon/design-system-react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface FormSwitchProps<TField extends FieldValues> {
  bindSchemaFieldName: Path<TField>;
  control: Control<TField>;
  sxRoot?: SxProps<Theme>;
  isLoading?: boolean;
  disabled?: boolean;
  testId?: string;
}

const FormSwitch = <TField extends FieldValues>({
  bindSchemaFieldName,
  control,
  sxRoot = undefined,
  isLoading = false,
  disabled = false,
  testId = undefined,
}: FormSwitchProps<TField>) => (
  <Controller
    control={control}
    name={bindSchemaFieldName}
    render={({ field, fieldState }) => (
      <FormControl error={Boolean(fieldState.error)} sx={{ ...sxRoot }}>
        {isLoading ? (
          <Skeleton height={30} width={40} />
        ) : (
          <Switch
            {...field}
            checked={field.value}
            disabled={disabled}
            {...(testId && { testId })}
          />
        )}
        <FormHelperText>{fieldState.error?.message}</FormHelperText>
      </FormControl>
    )}
  />
);

export default FormSwitch;
