import { CssBaseline, LightTheme, ThemeProvider } from "@crayon/design-system-react";

interface ThemeProviderWrapperProps {
  children: React.ReactNode;
}

const ThemeProviderWrapper = ({ children }: ThemeProviderWrapperProps) => (
  <ThemeProvider theme={LightTheme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default ThemeProviderWrapper;
