import { useTheme } from "@crayon/design-system-react";
import { SyncOutcome } from "api/client.generated";
import TableBodyCell from "./TableBodyCell";

interface TableBodySyncOutcomeCellProps {
  syncOutcome: SyncOutcome;
  align?: "inherit" | "left" | "center" | "right";
}

const TableBodySyncOutcomeCell = ({
  syncOutcome,
  align = "inherit",
}: TableBodySyncOutcomeCellProps) => {
  const theme = useTheme();
  const label = syncOutcome === SyncOutcome.NoChange ? "No Change" : `${syncOutcome}`;

  let color = "inherit";
  if (syncOutcome === SyncOutcome.Error) {
    color = theme.palette.error.main;
  } else if (syncOutcome === SyncOutcome.Warning) {
    color = theme.palette.warning.main;
  } else if (syncOutcome === SyncOutcome.Created || syncOutcome === SyncOutcome.Updated) {
    color = theme.palette.success.main;
  }
  return <TableBodyCell label={label} color={color} align={align} />;
};

export default TableBodySyncOutcomeCell;
