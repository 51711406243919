import { useQuery } from "@tanstack/react-query";
import { GetBillingCodesResponse } from "api/client.generated";
import useApi from "./useApi";

const useAutotaskBillingCodes = (): {
  billingCodes: GetBillingCodesResponse | undefined;
  isBillingCodesFetching: boolean;
} => {
  const { autotaskApiProxyClient } = useApi();

  const { data: billingCodes, isFetching: isBillingCodesFetching } = useQuery({
    queryKey: ["getBillingCodes"],
    queryFn: () => autotaskApiProxyClient.getBillingCodes(),
  });

  return { billingCodes, isBillingCodesFetching };
};

export default useAutotaskBillingCodes;
