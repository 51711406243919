import { useCallback, useState } from "react";

const useToggle = (defaultValue = false): [boolean, () => void] => {
  const [state, setState] = useState(defaultValue);

  const toggle = useCallback(() => setState((prev) => !prev), []);

  return [state, toggle];
};

export default useToggle;
