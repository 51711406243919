import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@crayon/design-system-react";
import { useEffect, useState } from "react";
import SortDirection from "types/sort-direction";

export interface SortableColumn<T> {
  label: string;
  field: keyof T;
  colSpan?: number;
  align?: "center" | "left" | "right";
  disableSort?: boolean;
}

interface TableHeadSortableProps<T> {
  columns: SortableColumn<T>[];
  onSortRequest: (newSortField: keyof T, newSortDirection: SortDirection) => void;

  // To set up initial sort column and direction.
  // No sort by default.
  initSortBy?: keyof T;
  initSortDirection?: SortDirection;

  // To setup start sort direction when user click on unsorted column.
  // By default - "asc"
  defaultSortDirection?: SortDirection;
}

function TableHeadSortable<PropsType>({
  columns,
  onSortRequest,
  initSortBy = undefined,
  initSortDirection = undefined,
  defaultSortDirection = "asc",
}: TableHeadSortableProps<PropsType>) {
  type FieldType = keyof PropsType;

  const [startSortDirection] = useState<SortDirection>(defaultSortDirection ?? "asc");
  const [sortBy, setSortBy] = useState<FieldType | undefined>(initSortBy);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    initSortDirection ?? startSortDirection,
  );

  useEffect(() => {
    if (sortBy !== undefined) {
      onSortRequest(sortBy, sortDirection);
    }
  }, [sortBy, sortDirection, onSortRequest]);

  const onColumnClick = (sortFiled: FieldType) => {
    let newSortDirection: SortDirection;
    if (sortBy === sortFiled) {
      newSortDirection = sortDirection === "asc" ? "desc" : "asc";
    } else {
      newSortDirection = startSortDirection;
    }

    setSortBy(sortFiled);
    setSortDirection(newSortDirection);
  };

  const cellContent = (label: string) => <Typography variant="subtitle2">{label}</Typography>;

  const sortableCellContent = (column: SortableColumn<PropsType>) => (
    <TableSortLabel
      active={sortBy === column.field}
      direction={sortBy === column.field ? sortDirection : startSortDirection}
      onClick={() => onColumnClick(column.field)}
    >
      {cellContent(column.label)}
    </TableSortLabel>
  );

  return (
    <TableHead>
      <TableRow>
        {columns.map((x, i) => (
          <TableCell
            key={x.label + String(i)}
            colSpan={x.colSpan ?? 1}
            align={x.align ?? "inherit"}
            size="small"
          >
            {x.disableSort ? cellContent(x.label) : sortableCellContent(x)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadSortable;
