import { Box, Paper, Stack } from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { SaveConnectWiseConfigurationRequestModel } from "api/client.generated";
import { ValidationException } from "api/client.generated.extensions";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useApi from "hooks/api/useApi";
import useConnectWiseConfiguration from "hooks/api/useConnectWiseConfiguration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import * as yup from "yup";

const ConnectWiseConnectionForm = () => {
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { connectWiseConfig, isConnectWiseConfigFetching, isConnectWiseConfigFetchingError } =
    useConnectWiseConfiguration();

  const { connectWiseConfigurationClient } = useApi();
  const navigate = useNavigate();
  const { raiseSuccessNotification, raiseWarningNotification, raiseErrorNotification } =
    useNotificationContext();
  const { invalidatePartnerConfig } = usePartnerConfigContext();

  const formSchema = yup.object().shape({
    companySite: yup.string().required("Required"),
    companyId: yup.string().required("Required"),
    apiPublicKey: yup.string().required("Required"),
    apiPrivateKey: yup.string().required("Required"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const { control, handleSubmit, reset } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
  });

  // to update form values when sourceConfig is loaded
  useEffect(() => {
    if (!connectWiseConfig) return;

    reset({
      companySite: connectWiseConfig.site,
      companyId: connectWiseConfig.companyId,
      apiPublicKey: connectWiseConfig.restApiPublicKey,
    });
  }, [connectWiseConfig, reset]);

  useEffect(() => {
    if (isConnectWiseConfigFetchingError)
      raiseWarningNotification(NotificationMessage.FAILED_TO_GET_CONNECTWISE_CFG);
  }, [isConnectWiseConfigFetchingError, raiseWarningNotification]);

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);
    try {
      await connectWiseConfigurationClient.saveConnectWiseConfiguration({
        site: formData.companySite,
        companyId: formData.companyId,
        restApiPublicKey: formData.apiPublicKey,
        restApiPrivateKey: formData.apiPrivateKey,
      } as SaveConnectWiseConfigurationRequestModel);

      raiseSuccessNotification(NotificationMessage.CONNECTION_SAVED);
      invalidatePartnerConfig();
      navigate(AppRoutes.connections.route);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      if (validationError) {
        setFormErrorMsg(validationError.getFirstErrorMessage());
      } else {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      }
      raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_CONNECTION);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={2}>
          <FormTextField
            control={control}
            bindSchemaFieldName="companySite"
            label="ConnectWise Site"
            testId="ConnectWise Site"
            isLoading={isConnectWiseConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            control={control}
            bindSchemaFieldName="companyId"
            label="ConnectWise Company ID"
            testId="ConnectWise Company ID"
            isLoading={isConnectWiseConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            control={control}
            bindSchemaFieldName="apiPublicKey"
            label="API Public Key"
            testId="API Public Key"
            isLoading={isConnectWiseConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            control={control}
            bindSchemaFieldName="apiPrivateKey"
            label="API Private Key"
            testId="API Private Key"
            isLoading={isConnectWiseConfigFetching}
            disabled={isSaving}
            sensitive
            required
          />
          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons
            cancelRoute={AppRoutes.connections.route}
            isSaveDisable={isConnectWiseConfigFetching}
            isSaveLoading={isSaving}
          />
        </Stack>
        <DevTool control={control} />
      </Box>
    </form>
  );
};

export default ConnectWiseConnectionForm;
