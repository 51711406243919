import { Skeleton, TableBody, TableCell, TableRow } from "@crayon/design-system-react";

interface TableBodySkeletonProps {
  rowsCount: number;
  colsCount: number;
}
const TableBodySkeleton = ({ rowsCount, colsCount }: TableBodySkeletonProps) => (
  <TableBody>
    {Array.from(Array(rowsCount).keys()).map((row) => (
      <TableRow key={row}>
        {Array.from(Array(colsCount).keys()).map((column) => (
          <TableCell key={column}>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
);

export default TableBodySkeleton;
