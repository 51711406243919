import {
  Box,
  Button,
  LinkBehavior,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@crayon/design-system-react";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodySkeleton from "components/primitives/TableBodySkeleton";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import { useSyncOverviewContext } from "context/syncOverviewContext";
import useSyncHistory from "hooks/api/useSyncHistory";
import useSyncTypeGuard from "hooks/useSyncTypeGuard";
import { useMemo, useState } from "react";
import AppRoutes from "routes/app-routes";
import SyncDigestStatsViewModel from "types/sync-digest-stats-vm";

const SyncHistoryTable = () => {
  const { source, target, program, syncType } = useSyncOverviewContext();
  const { syncHistory, isSyncHistoryFetching } = useSyncHistory(
    source,
    target,
    program,
    syncType,
    0,
    100,
  );

  useSyncTypeGuard(syncType);

  const [page, setPage] = useState(0);
  const rowsPerPageOptions = useMemo<number[]>(() => [10, 20, 50], []);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const theme = useTheme();

  const columnOptions = useMemo<string[]>(
    () =>
      syncType === "Product"
        ? ["When", "Status", "Products Synced", "Error Count", "Warning Count"]
        : ["When", "Status", "Customers Synced", "Subscriptions Synced", "Customers Failed"],
    [syncType],
  );

  const renderHeaderCell = (data: string, i: number) => (
    <TableHeaderCell
      key={data + String(i)}
      colSpan={i === columnOptions.length - 1 ? 2 : 1}
      columnName={data}
    />
  );

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderStatusCell = (digest: SyncDigestStatsViewModel) => {
    let label: string;
    let color: string;

    if (syncType === "Billing") {
      if (digest.errorCount > 0) {
        label = "Failure";
        color = theme.palette.error.main;
      } else if (digest.secondarySyncCount === 0) {
        label = "No Change";
        color = "inherit";
      } else {
        label = "Success";
        color = theme.palette.success.main;
      }
    }
    // Product history case
    else if (digest.errorCount > 0) {
      label = "Failure";
      color = theme.palette.error.main;
    } else if (digest.warningCount > 0) {
      label = "Warning";
      color = theme.palette.warning.main;
    } else {
      label = "Success";
      color = theme.palette.success.main;
    }

    return <TableBodyCell label={label} color={color} />;
  };
  // prettier-ignore
  const getHistoryDetailsRoute = (digestId: string) => syncType === "Billing"
    ? AppRoutes.billingSyncHistoryDetails.buildRoute(source, target, program, digestId)
    : AppRoutes.productSyncHistoryDetails.buildRoute(source, target, program, digestId);

  const DataTableBody = (
    <TableBody>
      {syncHistory?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((x) => (
        <TableRow key={x.digestId}>
          <TableBodyCell label={x.when.extToDateTimeString()} />
          {renderStatusCell(x)}
          <TableBodyCell label={`${x.syncCount}`} />
          <TableBodyCell
            label={syncType === "Billing" ? `${x.secondarySyncCount}` : `${x.errorCount}`}
          />
          <TableBodyCell label={syncType === "Billing" ? `${x.errorCount}` : `${x.warningCount}`} />
          <TableCell align="right">
            <Button component={LinkBehavior} href={getHistoryDetailsRoute(x.digestId)}>
              View
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <TableContainer component={Paper}>
      <Table testId="Sync History">
        <TableHead>
          <TableRow>{columnOptions.map(renderHeaderCell)}</TableRow>
        </TableHead>
        {isSyncHistoryFetching ? (
          <TableBodySkeleton rowsCount={rowsPerPage} colsCount={columnOptions.length} />
        ) : (
          DataTableBody
        )}
      </Table>
      {!isSyncHistoryFetching && Boolean(syncHistory?.length) && (
        <TablePagination
          component={Box}
          count={syncHistory!.length}
          page={page}
          onPageChange={(_: React.MouseEvent<HTMLButtonElement>, newPage: number) =>
            setPage(newPage)
          }
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{ my: 1 }}
        />
      )}
    </TableContainer>
  );
};

export default SyncHistoryTable;
