import { Box, Paper, Stack } from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { SavePrismConfigurationRequestModel } from "api/client.generated";
import { ValidationException } from "api/client.generated.extensions";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useApi from "hooks/api/useApi";
import usePrismConfiguration from "hooks/api/usePrismConfiguration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import * as yup from "yup";

const PrismConnectionForm = () => {
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { prismConfig, isPrismConfigFetching } = usePrismConfiguration();
  const { prismConfigurationClient } = useApi();
  const navigate = useNavigate();
  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();
  const { invalidatePartnerConfig } = usePartnerConfigContext();

  const formSchema = yup.object().shape({
    clientId: yup.string().required("Required"),
    clientSecret: yup.string().required("Required"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const { control, handleSubmit, reset } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
  });

  // to update form values when sourceConfig is loaded
  useEffect(() => {
    if (!prismConfig) return;

    reset({ clientId: prismConfig.clientId });
  }, [prismConfig, reset]);

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);
    try {
      await prismConfigurationClient.savePrismConfiguration({
        clientId: formData.clientId,
        clientSecret: formData.clientSecret,
      } as SavePrismConfigurationRequestModel);

      raiseSuccessNotification(NotificationMessage.CONNECTION_SAVED);
      invalidatePartnerConfig();
      navigate(AppRoutes.connections.route);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      if (validationError) {
        setFormErrorMsg(validationError.getFirstErrorMessage());
      } else {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      }
      raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_CONNECTION);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={2}>
          <FormTextField
            control={control}
            bindSchemaFieldName="clientId"
            label="Client ID"
            testId="Client ID"
            isLoading={isPrismConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            label="Client Secret"
            testId="Client Secret"
            control={control}
            bindSchemaFieldName="clientSecret"
            isLoading={isPrismConfigFetching}
            disabled={isSaving}
            sensitive
            required
          />
          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons
            cancelRoute={AppRoutes.connections.route}
            isSaveDisable={isPrismConfigFetching}
            isSaveLoading={isSaving}
          />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default PrismConnectionForm;
