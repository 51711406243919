import {
  Box,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import { MappedProduct } from "api/client.generated";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodySkeleton from "components/primitives/TableBodySkeleton";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import TextHeader from "components/primitives/TextHeader";
import useProductSyncPreview from "hooks/api/useProductSyncPreview";
import { ErrorCircleIcon } from "images/MuiIcons";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import SyncRouteParams from "types/sync-route-params";

const ProgramProductSyncPreviewPage = () => {
  const { source, target, program } = useParams<keyof SyncRouteParams>() as SyncRouteParams;
  const [matchedCount, setMatchedCount] = useState(0);
  const [unmatchedCount, setUnmatchedCount] = useState(0);
  const [errorsCount, setErrorsCount] = useState(0);
  const [tableData, setTableData] = useState<MappedProduct[]>([]);

  const [page, setPage] = useState(0);
  const rowsPerPageOptions = useMemo(() => [10, 20, 50], []);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const theme = useTheme();

  /* TODO: the backend should be refactored.
   * The UI should put ProductBillingMode options as a params for the API call.
   * At the moment API reads config from DB. So when the we have sync "InUseOnly" in
   * existing config and switch it to "AllProducts" => preview becomes available => click preview
   * => it makes API call => API reads EXISTING config which is "InUseOnly". The behavior is
   * disconnected from expectations in the UI. So now, to make it work, user should save the config,
   * get back to this page and click preview.
   */
  const { productSyncPreview, isProductSyncPreviewFetching } = useProductSyncPreview(
    source,
    target,
    program,
  );

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const productSyncData =
      productSyncPreview?.mappedProducts?.filter((p) => p.targetName != null) ?? [];

    const matched = productSyncData.filter((x) => !x.hasError).length ?? 0;
    const unmatched = (productSyncPreview?.mappedProducts?.length ?? 0) - productSyncData.length;
    const errors = productSyncData.filter((x) => x.hasError).length;

    setMatchedCount(matched);
    setUnmatchedCount(unmatched);
    setErrorsCount(errors);
    setTableData(productSyncData);
  }, [productSyncPreview]);

  const tableColumnOptions = useMemo<string[]>(() => {
    const columns = ["Sku", `${source} Name`, `${target} Name`];
    return tableData.some((x) => x.hasError)
      ? ["", ...columns] // extra column for error icon
      : columns;
  }, [tableData, source, target]);

  const statsSection = (
    <Stack spacing={1} p={4}>
      {isProductSyncPreviewFetching ? (
        <Skeleton width={250} />
      ) : (
        <Typography variant="body2">{`Number of products matched: ${matchedCount}`}</Typography>
      )}
      {isProductSyncPreviewFetching ? (
        <Skeleton width={250} />
      ) : (
        <Typography variant="body2">{`Number of products unmatched: ${unmatchedCount}`}</Typography>
      )}
      {errorsCount > 0 && (
        <Typography variant="body2" color="error">{`Number of errors: ${errorsCount}`}</Typography>
      )}
      {!!productSyncPreview?.errorMessage?.length && (
        <Typography variant="body2" color="error">
          {productSyncPreview?.errorMessage}
        </Typography>
      )}
    </Stack>
  );

  const DataTableBody = (
    <TableBody>
      {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((x, i) => (
        <TableRow key={x.sku + String(i)}>
          {x.hasError && (
            <TableBodyCell icon={<ErrorCircleIcon sx={{ color: theme.palette.error.main }} />} />
          )}
          <TableBodyCell label={x.sku} />
          <TableBodyCell label={x.sourceName} />
          <TableBodyCell label={x.targetName} />
        </TableRow>
      ))}
    </TableBody>
  );

  const tableSection = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {tableColumnOptions.map((c, i) => (
              <TableHeaderCell key={c + String(i)} columnName={c} />
            ))}
          </TableRow>
        </TableHead>
        {isProductSyncPreviewFetching ? (
          <TableBodySkeleton colsCount={tableColumnOptions.length} rowsCount={3} />
        ) : (
          DataTableBody
        )}
      </Table>
      {!isProductSyncPreviewFetching && (
        <Box p={2}>
          <TablePagination
            component={Box}
            count={tableData.length}
            page={page}
            onPageChange={(_: React.MouseEvent<HTMLButtonElement>, newPage: number) =>
              setPage(newPage)
            }
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Box>
      )}
    </TableContainer>
  );

  return (
    <Stack spacing={2}>
      <TextHeader testId="page-header" label="Preview Product Sync" />
      <Box component={Paper}>
        {statsSection}
        <Divider />
        {tableSection}
      </Box>
    </Stack>
  );
};

export default ProgramProductSyncPreviewPage;
