import { Box, Grid, Stack } from "@crayon/design-system-react";
import { ProgramStatus } from "api/client.generated";
import DashboardSyncCard from "components/hoc/DashboardSyncCard";
import NewsList from "components/hoc/NewsList";
import TextHeader from "components/primitives/TextHeader";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useScreen from "hooks/useScreen";
import { SyncIcon } from "images/MuiIcons";
import SyncType from "types/sync-type";

const DashboardPage = () => {
  const { mdMatch, xsMatch } = useScreen();
  const { partnerConfig } = usePartnerConfigContext();

  return (
    <Grid container spacing={0}>
      {/* Left side - syncs */}
      <Grid item xs={12} md={8}>
        <Stack spacing={2}>
          <TextHeader testId="page-header" label="Syncs" icon={<SyncIcon />} />
          <Box display="flex" flexWrap="wrap" gap="20px">
            {partnerConfig?.configuredPrograms?.map((config) => {
              const { programs, ...restConfig } = config;
              return programs
                ?.filter((p) => p.status === ProgramStatus.Syncing)
                .map((p) =>
                  (["Billing", "Product"] as SyncType[]).map((syncType) => (
                    <DashboardSyncCard
                      source={restConfig.source}
                      target={restConfig.target}
                      program={p.type}
                      syncType={syncType}
                      key={restConfig.source + restConfig.target + p.type + syncType}
                    />
                  )),
                );
            })}
          </Box>
        </Stack>
      </Grid>
      {/* Right side - Notifications, Updates */}
      <Grid item xs={12} md={4}>
        <Stack spacing={3} sx={{ ...(mdMatch && { ml: 2 }), ...(xsMatch && { mt: 3 }) }}>
          <NewsList mode="Notifications" />
          <NewsList mode="Updates" />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
