import { Stack, Typography } from "@crayon/design-system-react";
import TextHeader from "components/primitives/TextHeader";
import { NotificationsIcon, UpdatesIcon } from "images/MuiIcons";

type NewsListMode = "Notifications" | "Updates";

interface INewsList {
  mode: NewsListMode;
}

const NewsList = ({ mode }: INewsList) => (
  <Stack spacing={2}>
    {mode === "Notifications" && (
      <TextHeader
        testId="page-header"
        label="Notifications"
        icon={<NotificationsIcon />}
      />
    )}
    {mode === "Updates" && (
      <TextHeader testId="page-header" label="Updates" icon={<UpdatesIcon />} />
    )}
    <Typography variant="body2" align="justify">
      {mode === "Notifications" ? "No notifications" : "No updates"}
    </Typography>
  </Stack>
);

export default NewsList;
