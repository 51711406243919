import { useQuery } from "@tanstack/react-query";
import { GetAutotaskConfigurationResponse } from "api/client.generated";
import useApi from "./useApi";

interface UseAutotaskConfigurationReturn {
  autotaskConfig: GetAutotaskConfigurationResponse | undefined;
  isAutotaskConfigFetching: boolean;
  isAutotaskConfigFetchingError: boolean;
}

const useAutotaskConfiguration = (): UseAutotaskConfigurationReturn => {
  const { autotaskConfigurationClient } = useApi();

  const { data, isFetching, isLoadingError } = useQuery({
    queryKey: ["getAutotaskConfiguration"],
    queryFn: () => autotaskConfigurationClient.getAutotaskConfiguration(),
  });

  return {
    autotaskConfig: data,
    isAutotaskConfigFetching: isFetching,
    isAutotaskConfigFetchingError: isLoadingError,
  };
};

export default useAutotaskConfiguration;
