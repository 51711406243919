import { Stack } from "@crayon/design-system-react";
import ProgramEditForm from "components/hoc/Programs/ProgramEditForm";
import TextHeader from "components/primitives/TextHeader";
import { useParams } from "react-router-dom";
import SyncRouteParams from "types/sync-route-params";

const EditProgramPage = () => {
  const { source, target, program } = useParams<
    keyof SyncRouteParams
  >() as SyncRouteParams;

  return (
    <Stack spacing={4}>
      <TextHeader
        testId="page-header"
        label={`Edit ${program} | ${source} > ${target}`}
      />
      <ProgramEditForm source={source} target={target} program={program} />
    </Stack>
  );
};

export default EditProgramPage;
