import { useQuery } from "@tanstack/react-query";
import {
  GetProductSyncPreviewResponse,
  ProgramType,
  Source,
  Target,
} from "api/client.generated";
import useApi from "./useApi";

interface useProductSyncPreviewReturn {
  productSyncPreview: GetProductSyncPreviewResponse | undefined;
  isProductSyncPreviewFetching: boolean;
}

const useProductSyncPreview = (
  source: Source,
  target: Target,
  program: ProgramType,
): useProductSyncPreviewReturn => {
  const { productsClient } = useApi();

  const { data: productSyncPreview, isFetching: isProductSyncPreviewFetching } =
    useQuery({
      queryKey: ["previewProductSync", source, target, program],
      queryFn: () => productsClient.previewProductSync(source, target, program),
    });

  return { productSyncPreview, isProductSyncPreviewFetching };
};

export default useProductSyncPreview;
