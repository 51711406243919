import { Alert, Collapse } from "@crayon/design-system-react";

interface FormErrorMessageProps {
  message: string;
}

const FormErrorMessage = ({ message }: FormErrorMessageProps) => (
  <Collapse
    in={Boolean(message)}
    /* This is to override margin top which (by usage in forms) set by 'stack spacing'
     * <Stack> treats this component as item and adds spacing event if there is no message.
     */
    sx={{ ...(message.length === 0 && { "&&": { mt: 0 } }) }}
  >
    <Alert severity="error">{message}</Alert>
  </Collapse>
);

export default FormErrorMessage;
