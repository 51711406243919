import PriceRuleAddEditLayout from "components/layout/PriceRuleAddEditLayout";
import { useParams } from "react-router-dom";

interface RouteParams {
  ruleId: string;
}

const EditPriceRulePage = () => {
  const { ruleId } = useParams<keyof RouteParams>() as RouteParams;

  return <PriceRuleAddEditLayout ruleId={ruleId} />;
};

export default EditPriceRulePage;
