import { useQuery } from "@tanstack/react-query";
import {
  GetCustomerMappingsResponse,
  ProgramType,
  Source,
  Target,
} from "api/client.generated";
import useApi from "./useApi";

const useCustomerMappings = (
  source: Source,
  target: Target,
  program: ProgramType,
): {
  customerMappings: GetCustomerMappingsResponse | undefined;
  isCustomerMappingsFetching: boolean;
} => {
  const { customerMappingsClient } = useApi();

  const { data: customerMappings, isFetching: isCustomerMappingsFetching } =
    useQuery({
      queryKey: ["getCustomerMappings", source, target, program],
      queryFn: () =>
        customerMappingsClient.getCustomerMappings(source, target, program),
    });

  return { customerMappings, isCustomerMappingsFetching };
};

export default useCustomerMappings;
