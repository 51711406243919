import useLocalStorage from "hooks/useLocalStorage";
import useRouteDetector from "hooks/useRouteDetector";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { Navigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";

interface IPartner {
  id: string;
  name: string;
}

interface ISelectedPartnerContext {
  partner: IPartner | undefined;
  setPartner: React.Dispatch<React.SetStateAction<IPartner | undefined>>;
}

const SelectedPartnerContext = createContext<ISelectedPartnerContext | null>(null);

interface SelectedPartnerContextProviderProps {
  children: React.ReactNode;
}

export const SelectedPartnerContextProvider = ({
  children,
}: SelectedPartnerContextProviderProps) => {
  // Init from local storage. Default is 'undefined'
  const { setItem, getItem } = useLocalStorage("partner");
  const [partner, setPartner] = useState<IPartner | undefined>(getItem());
  const { isSelectPartnerRoute, isSignInRoute } = useRouteDetector();

  // Save to local storage on change
  useEffect(() => {
    setItem(partner);
  }, [partner, setItem]);

  // setup context value
  const ctxValue = useMemo<ISelectedPartnerContext>(
    () => ({ partner, setPartner }),
    [partner, setPartner],
  );

  // =====================
  // This block checks that partnerId has value if it's not the SelectPartnerPage
  // and not the SignInPage. Because of the SelectPartnerPage and SignInPage are rendered
  // in the App 'template', we must skip the 'check & redirect' action.
  // Otherwise - infinite loop of redirection.
  //

  if (!isSelectPartnerRoute && !isSignInRoute && !partner)
    return <Navigate to={AppRoutes.selectPartner.route} />;
  //
  // ======================

  // prettier-ignore
  return (
    <SelectedPartnerContext.Provider value={ctxValue}>
      {children}
    </SelectedPartnerContext.Provider>
  );
};

export const useSelectedPartnerContext = (): ISelectedPartnerContext => {
  const context = useContext(SelectedPartnerContext);
  if (!context) {
    throw new Error(
      "useSelectedPartnerContext must be used within a SelectedPartnerContextProvider",
    );
  }

  return context;
};
