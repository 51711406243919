import { useQuery } from "@tanstack/react-query";
import { GetConnectWiseConfigurationResponse } from "api/client.generated";
import useApi from "./useApi";

interface UseConnectWiseConfigurationReturn {
  connectWiseConfig: GetConnectWiseConfigurationResponse | undefined;
  isConnectWiseConfigFetching: boolean;
  isConnectWiseConfigFetchingError: boolean;
}

const useConnectWiseConfiguration = (): UseConnectWiseConfigurationReturn => {
  const { connectWiseConfigurationClient } = useApi();

  const { data, isFetching, isLoadingError } = useQuery({
    queryKey: ["getConnectWiseConfiguration"],
    queryFn: () => connectWiseConfigurationClient.getConnectWiseConfiguration(),
  });

  return {
    connectWiseConfig: data,
    isConnectWiseConfigFetching: isFetching,
    isConnectWiseConfigFetchingError: isLoadingError,
  };
};

export default useConnectWiseConfiguration;
