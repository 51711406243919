import { useQuery } from "@tanstack/react-query";
import { Product, ProgramType, Source } from "api/client.generated";
import { PriceRuleModelStringConstants } from "types/price-rule-model";
import useApi from "./useApi";

interface UseSourceProductsReturn {
  products: Product[] | undefined;
  isProductsFetching: boolean;
}

const useSourceProducts = (source: Source, program: ProgramType): UseSourceProductsReturn => {
  const { sourceProductsClient } = useApi();

  const loadData = async (): Promise<Product[] | undefined> => {
    const response = await sourceProductsClient.getSourceProducts(source, program);
    return response.products;
  };
  const { data: products, isFetching: isProductsFetching } = useQuery({
    queryKey: ["getSourceProducts", source, program],
    queryFn: loadData,
    enabled:
      `${source}` !== PriceRuleModelStringConstants.ALL_SOURCES &&
      `${program}` !== PriceRuleModelStringConstants.ALL_PROGRAMS,
  });

  return { products, isProductsFetching };
};

export default useSourceProducts;
