import { Box, Stack } from "@crayon/design-system-react";
import { Source, Target } from "api/client.generated";
import CloudIqConnectionForm from "components/hoc/Connections/Source/CloudIqConnectionForm";
import PrismConnectionForm from "components/hoc/Connections/Source/PrismConnectionForm";
import AutotaskConnectionForm from "components/hoc/Connections/Target/AutotaskConnectionForm";
import ConnectWiseConnectionForm from "components/hoc/Connections/Target/ConnectWiseConnectionForm";
import SyncroConnectionForm from "components/hoc/Connections/Target/SyncroConnectionForm";
import TextHeader from "components/primitives/TextHeader";
import { Navigate, useParams } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import { isValidEnumValue } from "utils/enum-flags";

type EditConnectionPageParams = {
  endpointName: Source | Target;
};

const EditConnectionPage = () => {
  const { endpointName } = useParams<
    keyof EditConnectionPageParams
  >() as EditConnectionPageParams;

  // technically there is can be garbage in the endpointName
  // but the route will be valid from router perspective
  if (
    !isValidEnumValue(Source, endpointName) &&
    !isValidEnumValue(Target, endpointName)
  )
    return <Navigate to={AppRoutes.connections.route} />;

  return (
    <Stack spacing={2}>
      <TextHeader
        testId="page-header"
        label={`Edit ${endpointName} connection`}
      />
      <Box minWidth="500px" width="40%">
        {endpointName === Source.Prism && <PrismConnectionForm />}
        {endpointName === Source.CloudIq && <CloudIqConnectionForm />}
        {endpointName === Target.Autotask && <AutotaskConnectionForm />}
        {endpointName === Target.ConnectWise && <ConnectWiseConnectionForm />}
        {endpointName === Target.Syncro && <SyncroConnectionForm />}
      </Box>
    </Stack>
  );
};

export default EditConnectionPage;
