import { Paper, Table, TableBody, TableContainer } from "@crayon/design-system-react";
import { SyncOperation } from "api/client.generated";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableRowCollapsible from "components/primitives/TableRowCollapsible";
import UsageSyncOperationDetailsTable from "./UsageSyncOperationDetailsTable";

interface UsageSyncOperationsTableProps {
  syncOperations: SyncOperation[] | undefined;
}

const UsageSyncOperationsTable = (props: UsageSyncOperationsTableProps) => {
  const { syncOperations } = props;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {syncOperations?.map((x, i) => (
            <TableRowCollapsible
              key={x.id + String(i)}
              columnCount={1}
              oneRow={false}
              collapsibleContent={<UsageSyncOperationDetailsTable syncOperation={x} />}
            >
              <TableBodyCell label={x.operationDescription} />
            </TableRowCollapsible>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsageSyncOperationsTable;
