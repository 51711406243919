import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Customer,
  CustomerMapping,
  SaveCustomerMappingCommand,
  SetLastProcessedInvoiceDateCommand,
} from "api/client.generated";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormAutocomplete, { FormAutocompleteOption } from "components/primitives/FormAutocomplete";
import FormDatePicker from "components/primitives/FormDatePicker";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormSwitch from "components/primitives/FormSwitch";
import { useConfirmationDialogContext } from "context/confirmationDialogContext";
import { useNotificationContext } from "context/notificationContext";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import useApi from "hooks/api/useApi";
import useToggle from "hooks/useToggle";
import { ExpandLessIcon, ExpandMoreIcon } from "images/MuiIcons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import SyncRouteParams from "types/sync-route-params";
import filterTargetAgreementsByBillingMotion from "utils/target-agreement-filter";
import {
  getTargetAgreementLabel,
  getTargetCustomerLabel,
} from "utils/target-customer-agreement-labels";
import * as yup from "yup";

export interface BillingMappingAddEditNavState {
  mapping: CustomerMapping;
  targetCustomers: Customer[];
}

const BillingMappingForm = () => {
  const { source, target, program } = useParams<keyof SyncRouteParams>() as SyncRouteParams;
  const [isAdvancedSettingsOpen, toggleAdvancedSettingOpen] = useToggle(false);

  const { state } = useLocation();
  const { mapping, targetCustomers } = state as BillingMappingAddEditNavState;
  const theme = useTheme();
  const navigate = useNavigate();
  const { raiseConfirmationDialog } = useConfirmationDialogContext();
  const [isSaving, setIsSaving] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState("");

  const { customerMappingsClient } = useApi();
  const { partner } = useSelectedPartnerContext();
  const { raiseSuccessNotification, raiseErrorNotification } = useNotificationContext();

  const formSchema = yup.object().shape({
    syncEnabled: yup.boolean().required(),
    // repeats `options` prop type for <FormAutocomplete> which is FormAutocompleteOption
    targetCustomer: yup
      .object()
      .shape({
        id: yup.string().required(),
        label: yup.string(),
      })
      .nullable()
      // can't use build-in 'required()' because it overrides 'nullable()' which adds 'null' type
      .test("test-required", "Required", (value) => Boolean(value)),
    targetAgreement: yup
      .object()
      .shape({
        id: yup.string().required(),
        label: yup.string(),
      })
      .nullable()
      .test("test-required", "Required", (value) => Boolean(value)),
    lastProcessedInvoiceDate: yup.date().notRequired(),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const {
    control,
    getValues,
    watch,
    resetField,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      syncEnabled: mapping.isSyncing,
      // See FormAutocompleteOption description
      targetCustomer: !mapping.targetCustomer
        ? null
        : {
            id: mapping.targetCustomer.id,
            label: mapping.targetCustomer.name,
          },
      targetAgreement: !mapping.targetCustomer?.agreement
        ? null
        : {
            id: mapping.targetCustomer.agreement.id,
            label: mapping.targetCustomer.agreement.name,
          },
      lastProcessedInvoiceDate: null,
    },
  });

  const [isTargetAgreementDisabled, setIsTargetAgreementDisabled] = useState<boolean>(
    !mapping.targetCustomer?.id,
  );

  const targetCustomerOptions = useMemo<FormAutocompleteOption[]>(
    () => targetCustomers.map((x) => ({ id: x.id, label: x.name }) as FormAutocompleteOption),
    [targetCustomers],
  );

  const findTargetAgreementOptions = useCallback(
    (targetCustomerId: string | undefined): FormAutocompleteOption[] => {
      const customer = targetCustomers.find((x) => x.id === targetCustomerId);
      return (
        customer?.agreements
          ?.filter((x) => filterTargetAgreementsByBillingMotion(x, target, mapping.billingMotion))
          ?.map((x) => ({ id: x.id, label: x.name }) as FormAutocompleteOption) ?? []
      );
    },
    [targetCustomers, mapping, target],
  );

  const initTargetAgreementOptions = useMemo<FormAutocompleteOption[]>(() => {
    const { targetCustomer } = getValues();
    return findTargetAgreementOptions(targetCustomer?.id);
  }, [getValues, findTargetAgreementOptions]);

  const [targetAgreementOptions, setTargetAgreementOptions] = useState<FormAutocompleteOption[]>(
    initTargetAgreementOptions,
  );

  const onTargetCustomerIdChange = useCallback(
    (newTargetCustomerId: string | undefined) => {
      const newContractOptions = findTargetAgreementOptions(newTargetCustomerId);
      setTargetAgreementOptions(newContractOptions);
      resetField("targetAgreement", { defaultValue: null });
      setIsTargetAgreementDisabled(!newTargetCustomerId);
    },
    [findTargetAgreementOptions, setTargetAgreementOptions, resetField],
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "targetCustomer":
          onTargetCustomerIdChange(value.targetCustomer?.id);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onTargetCustomerIdChange]);

  const targetCustomerLabel = useMemo<string>(() => getTargetCustomerLabel(target), [target]);

  const targetAgreementLabel = useMemo<string>(() => getTargetAgreementLabel(target), [target]);

  const billingSyncOverviewRoute = useMemo<string>(
    () => AppRoutes.billingSync.buildRoute(source, target, program),
    [source, target, program],
  );

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);

    let mappingId = mapping.id;
    let submitSucceeded = true;

    // save mapping changes if there is any
    if (dirtyFields.syncEnabled || dirtyFields.targetCustomer || dirtyFields.targetAgreement) {
      const saveMappingCommand: SaveCustomerMappingCommand = {
        billingMotion: mapping.billingMotion,
        id: mapping.id,
        isSyncing: formData.syncEnabled,
        partnerId: partner?.id ?? "",
        pricingSyncStrategy: mapping.pricingSyncStrategy,
        programType: program,
        source,
        target,
        sourceCustomerId: mapping.sourceCustomer?.id ?? "",
        sourceAgreementId: mapping.sourceCustomer?.agreement?.id ?? "",
        targetCustomerId: formData.targetCustomer!.id,
        targetAgreementId: formData.targetAgreement!.id,
        startSyncDate: undefined,
      };

      try {
        const response = await customerMappingsClient.saveCustomerMapping(
          source,
          target,
          program,
          saveMappingCommand,
        );
        mappingId = response.id;
        raiseSuccessNotification(NotificationMessage.MAPPING_SAVED);
      } catch (e: unknown) {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
        raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_MAPPING);
        submitSucceeded = false;
      }
    }

    // update lastProcessedInvoiceDate if changed
    if (dirtyFields.lastProcessedInvoiceDate && formData.lastProcessedInvoiceDate && mappingId) {
      const setCommand: SetLastProcessedInvoiceDateCommand = {
        customerMappingId: mappingId,
        lastProcessedInvoiceDate: formData.lastProcessedInvoiceDate,
        partnerId: partner?.id ?? "",
      };
      try {
        await customerMappingsClient.updateLastProcessedInvoiceDate(
          source,
          target,
          program,
          setCommand,
        );
        raiseSuccessNotification(NotificationMessage.LAST_INV_DATE_UPDATED);
      } catch (e: unknown) {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
        raiseErrorNotification(NotificationMessage.FAILED_TO_UPDATE_LAST_INV_DATE);
        submitSucceeded = false;
      }
    }
    setIsSaving(false);

    if (submitSucceeded) navigate(billingSyncOverviewRoute);
  };

  const onDeleteMapping = async (): Promise<void> => {
    if (!mapping.id) return;

    const isConfirmed = await raiseConfirmationDialog(
      "Delete mapping",
      "Please confirm mapping deletion",
    );
    if (!isConfirmed) return;

    setIsSaving(true);

    try {
      await customerMappingsClient.deleteCustomerMapping(mapping.id, source, target, program);
      raiseSuccessNotification(NotificationMessage.MAPPING_DELETED);
      navigate(billingSyncOverviewRoute);
    } catch (e: unknown) {
      setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      raiseErrorNotification(NotificationMessage.FAILED_TO_DELETE_MAPPING);
    }
    setIsSaving(false);
  };

  const onResetMapping = async (): Promise<void> => {
    if (!mapping.id) return;

    const isConfirmed = await raiseConfirmationDialog(
      "Reset sync status",
      "Please confirm sync status resetting",
    );
    if (!isConfirmed) return;

    setIsSaving(true);

    try {
      await customerMappingsClient.resetCustomerMapping(mapping.id, source, target, program);
      raiseSuccessNotification(NotificationMessage.SYNC_STATUS_WAS_RESET);
      navigate(billingSyncOverviewRoute);
    } catch (e: unknown) {
      setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      raiseErrorNotification(NotificationMessage.FAILED_TO_RESET_SYNC_STATUS);
    }
    setIsSaving(false);
  };

  const EnableSettingSlice = (
    <Box display="flex" alignItems="center">
      <Typography variant="h6">{mapping.sourceCustomer?.name}</Typography>
      <FormSwitch
        control={control}
        bindSchemaFieldName="syncEnabled"
        sxRoot={{ ml: "auto" }}
        disabled={isSaving}
        testId="syncEnabled"
      />
    </Box>
  );

  const SourceSettingSlice = (
    <Box display="flex" flex="wrap" gap={5}>
      <TextField
        label="Tenant"
        testId="Tenant"
        defaultValue={mapping.sourceCustomer?.agreement?.name}
        InputProps={{ readOnly: true }}
        sx={{ flex: 1 }}
        disabled={isSaving}
      />
      <TextField
        label="Billing Motion"
        testId="Billing Motion"
        defaultValue={mapping.billingMotion}
        InputProps={{ readOnly: true }}
        sx={{ flex: 1 }}
        disabled={isSaving}
      />
    </Box>
  );

  const TargetSettingSlice = (
    <Box display="flex" flex="wrap" gap={5}>
      <FormAutocomplete
        control={control}
        bindSchemaFieldName="targetCustomer"
        label={targetCustomerLabel}
        testId={targetCustomerLabel}
        options={targetCustomerOptions}
        sxRoot={{ flex: 1 }}
        disabled={isSaving}
        clearable
        required
      />
      <FormAutocomplete
        control={control}
        bindSchemaFieldName="targetAgreement"
        label={targetAgreementLabel}
        testId={targetAgreementLabel}
        options={targetAgreementOptions}
        sxRoot={{ flex: 1 }}
        disabled={isTargetAgreementDisabled || isSaving}
        clearable
        required
      />
    </Box>
  );

  const AdvancedOptionsSlice = (
    <List>
      <ListItemButton
        disableRipple
        disableGutters
        onClick={toggleAdvancedSettingOpen}
        sx={{ "&:hover": { bgcolor: theme.palette.action.hover } }}
      >
        <ListItemText
          primaryTypographyProps={{ variant: "subtitle1" }}
          primary="Advanced Options"
        />
        {isAdvancedSettingsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={isAdvancedSettingsOpen}>
        <Box display="flex" alignItems="center" gap={4} sx={{ py: 2 }}>
          <FormDatePicker
            id="last-processed-invoice-date"
            control={control}
            bindSchemaFieldName="lastProcessedInvoiceDate"
            label="Last Processed Invoice Date"
            sxRoot={{ flex: 1 }}
            disabled={isSaving}
          />
          <Box flex={1}>
            <Box display="flex" justifyContent="space-around">
              <Button
                color="error"
                variant="outlined"
                onClick={onResetMapping}
                disabled={!mapping.id || isSaving}
                testId="Reset Sync Status"
              >
                Reset Sync Status
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={onDeleteMapping}
                disabled={!mapping.id || isSaving}
                testId="Delete Mapping"
              >
                Delete Mapping
              </Button>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </List>
  );

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={4}>
          {EnableSettingSlice}
          {SourceSettingSlice}
          {TargetSettingSlice}
          <Divider />

          <Box sx={{ "&&": { mt: 0 } }}>{AdvancedOptionsSlice}</Box>
          <Divider sx={{ "&&": { mt: 0 } }} />

          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons cancelRoute={billingSyncOverviewRoute} isSaveLoading={isSaving} />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default BillingMappingForm;
