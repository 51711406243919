import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@crayon/design-system-react";
import { SyncOperation } from "api/client.generated";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodyDateCell from "components/primitives/TableBodyDateCell";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import { useMemo } from "react";

interface UsageSyncOperationDetailsTableProps {
  syncOperation: SyncOperation;
}

const UsageSyncOperationDetailsTable = (props: UsageSyncOperationDetailsTableProps) => {
  const { syncOperation } = props;

  const columnOptions = useMemo<string[]>(
    () => [
      "Contract Service Id",
      "Adjusted Cost Price",
      "Adjusted Retail Price",
      "Effective Date",
      "Unit Change",
    ],
    [],
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columnOptions.map((x, i) => (
              <TableHeaderCell key={x + String(i)} columnName={x} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableBodyCell label={syncOperation.data?.contractServiceId} />
            <TableBodyCell label={syncOperation.data?.adjustedUnitCost} />
            <TableBodyCell label={syncOperation.data?.adjustedUnitPrice} />
            <TableBodyDateCell date={syncOperation.data?.effectiveDate} />
            <TableBodyCell label={syncOperation.data?.unitChange} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsageSyncOperationDetailsTable;
