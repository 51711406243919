import NotificationMessage from "types/notification-message";
import {
  IProgramConfigurationData,
  ValidationProblemDetails,
} from "./client.generated";

export interface MicrosoftCspProgramConfiguration
  extends IProgramConfigurationData {
  taxableProducts: boolean;
}

export interface AutotaskMicrosoftCspProgramConfiguration
  extends IProgramConfigurationData {
  vendorId: number | undefined;
  billingCodeId: number;
}

export class ValidationException implements ValidationProblemDetails {
  // implements IValidationException{
  errors: { [key: string]: string[] };
  title: string;

  constructor(errors: { [key: string]: string[] }, title: string) {
    this.errors = errors;
    this.title = title;

    /// unused
    this.extensions = {};
  }

  //// unused fields that we get from nswag model
  type: string | undefined;
  status: number | undefined;
  detail: string | undefined;
  instance: string | undefined;
  extensions: { [key: string]: any };

  public getFirstErrorMessage(): string {
    for (const errorKey in this.errors) {
      if (this.errors[errorKey]?.length > 0) return this.errors[errorKey][0];
    }

    return NotificationMessage.UNKNOWN_ERROR;
  }

  public static parse = (e: unknown): ValidationException | null => {
    if (
      (e as ValidationException).errors !== undefined &&
      (e as ValidationException).title !== undefined
    ) {
      return new ValidationException(
        (e as ValidationException).errors,
        (e as ValidationException).title,
      );
    }

    return null;
  };
}
