import ConfirmationDialog from "components/hoc/ConfirmationDialog";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface IConfirmationDialogContext {
  raiseConfirmationDialog: (title: string, content: string) => Promise<boolean>;
}

const ConfirmationDialogContext =
  createContext<IConfirmationDialogContext | null>(null);

interface ConfirmationDialogContextProviderProps {
  children: React.ReactNode;
}

const ConfirmationDialogContextProvider = ({
  children,
}: ConfirmationDialogContextProviderProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");

  // Can't save resolve as is because it's called
  // on saving to the state: https://github.com/facebook/react/issues/22822
  // So save it as object.
  const [promiseTrigger, setPromiseTrigger] = useState<{
    resolve: (value: boolean) => void;
  } | null>(null);

  const raiseConfirmationDialog = useCallback(
    (title: string, content: string): Promise<boolean> =>
      new Promise((resolve) => {
        setDialogTitle(title);
        setDialogContent(content);
        setPromiseTrigger({ resolve });
        setIsDialogOpen(true);
      }),
    [],
  );

  const ctxValue = useMemo<IConfirmationDialogContext>(
    () => ({ raiseConfirmationDialog }),
    [raiseConfirmationDialog],
  );

  const onConfirm = () => {
    setIsDialogOpen(false);
    if (promiseTrigger?.resolve) {
      promiseTrigger.resolve(true);
      setPromiseTrigger(null);
    }
  };
  const onCancel = () => {
    setIsDialogOpen(false);
    if (promiseTrigger?.resolve) {
      promiseTrigger.resolve(false);
      setPromiseTrigger(null);
    }
  };

  return (
    <ConfirmationDialogContext.Provider value={ctxValue}>
      {children}
      <ConfirmationDialog
        open={isDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialogContext = (): IConfirmationDialogContext => {
  const context = useContext(ConfirmationDialogContext);
  if (!context)
    throw new Error(
      "useConfirmationDialogContext must be used within a ConfirmationDialogContextProvider",
    );

  return context;
};

export default ConfirmationDialogContextProvider;
