import { useLocation } from "react-router-dom";
import AppRoutes from "routes/app-routes";

interface UseRouteDetectorReturn {
  isSignInRoute: boolean;
  isSelectPartnerRoute: boolean;
  isTermsRoute: boolean;
}

const useRouteDetector = (): UseRouteDetectorReturn => {
  const { pathname } = useLocation();

  // prettier-ignore
  return {
    isSignInRoute:
      pathname.toLowerCase() === AppRoutes.signIn.route.toLowerCase(),
    isSelectPartnerRoute:
      pathname.toLowerCase() === AppRoutes.selectPartner.route.toLocaleLowerCase(),
    isTermsRoute:
      pathname.toLocaleLowerCase() === AppRoutes.terms.route.toLocaleLowerCase(),
  };
};

export default useRouteDetector;
