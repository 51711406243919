import { Box, Collapse, TableCell, TableRow, useTheme } from "@crayon/design-system-react";
import useToggle from "hooks/useToggle";

interface TableRowCollapsibleProps {
  children: React.ReactNode;
  collapsibleContent: React.ReactNode;
  columnCount: number;
  // specify that collapsible content should be a single piece with the row data
  // from hover:background and clickable point of view
  oneRow: boolean;
}

const TableRowCollapsible = (props: TableRowCollapsibleProps) => {
  const { children, collapsibleContent, columnCount, oneRow } = props;
  const [isOpen, toggleOpen] = useToggle();
  const theme = useTheme();
  const hoverColor = theme.palette.action.hover;

  return (
    <>
      <TableRow
        sx={{
          "&:hover": {
            cursor: "pointer",
            background: hoverColor,
          },
          ...(oneRow && {
            "&:hover + .messageRow": {
              background: hoverColor,
            },
          }),
          ...(oneRow && {
            "&:has(+ .messageRow:hover)": {
              background: hoverColor,
            },
          }),
          ...(collapsibleContent && {
            "& > .MuiTableCell-root": { borderBottom: "unset" },
          }),
        }}
        onClick={toggleOpen}
      >
        {children}
      </TableRow>
      {collapsibleContent && (
        <TableRow
          className="messageRow"
          sx={{
            ...(oneRow && {
              "&:hover": {
                cursor: "pointer",
                background: hoverColor,
              },
            }),
          }}
          onClick={() => (oneRow ? toggleOpen() : undefined)}
        >
          <TableCell sx={{ py: 0 }} colSpan={columnCount}>
            <Collapse in={isOpen} unmountOnExit>
              <Box pb={2}>{collapsibleContent}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableRowCollapsible;
