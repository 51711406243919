import { useQuery } from "@tanstack/react-query";
import { PriceRule } from "api/client.generated";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import { PriceRuleModel } from "types/price-rule-model";
import useApi from "./useApi";

interface UsePriceRulesReturn {
  priceRules: PriceRuleModel[] | undefined;
  isPriceRulesFetching: boolean;
}

const usePriceRules = (): UsePriceRulesReturn => {
  const { priceRulesClient } = useApi();
  const { partner } = useSelectedPartnerContext();

  const loadData = async (): Promise<PriceRuleModel[]> => {
    const pageSize = 100;
    let result: PriceRule[] = [];
    for (let page = 1; ; page += 1) {
      const response = await priceRulesClient.getPriceRules(page, pageSize, partner?.id);

      if (response.records) result = [...result, ...response.records];

      if (response.totalRecords <= page * pageSize) break;
    }

    return result.map((rule) => new PriceRuleModel(rule)) ?? [];
  };

  const { data: priceRules, isFetching: isPriceRulesFetching } = useQuery({
    queryKey: ["getPriceRules"],
    queryFn: loadData,
  });

  return { priceRules, isPriceRulesFetching };
};

export default usePriceRules;
