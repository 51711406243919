import SyncHistoryOverview from "components/layout/Sync/SyncHistoryOverview";
import { SyncOverviewContextProvider } from "context/syncOverviewContext";
import { useParams } from "react-router-dom";
import SyncRouteParams from "types/sync-route-params";

const BillingSyncHistoryPage = () => {
  // prettier-ignore
  const { source, target, program } = useParams<keyof SyncRouteParams>() as SyncRouteParams;
  return (
    <SyncOverviewContextProvider
      source={source}
      target={target}
      program={program}
      syncType="Billing"
    >
      <SyncHistoryOverview />
    </SyncOverviewContextProvider>
  );
};

export default BillingSyncHistoryPage;
