import { Target } from "api/client.generated";

export const getTargetCustomerLabel = (target: Target) => {
  switch (target) {
    case Target.ConnectWise:
      return "ConnectWise Customer";
    case Target.Autotask:
      return "Autotask Company";
    case Target.Syncro:
      return "Syncro Customer";
    default:
      return "Target Customer";
  }
};

export const getTargetAgreementLabel = (target: Target) => {
  switch (target) {
    case Target.ConnectWise:
      return "ConnectWise Agreement";
    case Target.Autotask:
      return "Autotask Contract";
    case Target.Syncro:
      return "Syncro Invoice Template";
    default:
      return "Target Agreement";
  }
};
