import {
  Box,
  CancelButton,
  LinkBehavior,
  NormalButton,
  PrimaryButton,
  Skeleton,
  Stack,
} from "@crayon/design-system-react";
import TextHeader from "components/primitives/TextHeader";
import { useTermsContext } from "context/termsContext";
import useApi from "hooks/api/useApi";
import useGetTermsPdf from "hooks/api/useTermsPdf";
import useScreen from "hooks/useScreen";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";

const TermsPage = () => {
  const { isAccepted, latestVersion, invalidateTermsQuery } = useTermsContext();
  const { termsPdf, isTermsPdfFetching } = useGetTermsPdf(latestVersion);
  const [isAccepting, setIsAccepting] = useState(false);
  const [pdfObjectURL, setPdfObjectURL] = useState<string>();
  const { isBigScreen } = useScreen();
  const { termsClient } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (termsPdf) setPdfObjectURL(URL.createObjectURL(termsPdf.data));
  }, [termsPdf]);

  const onAccept = async () => {
    setIsAccepting(true);
    try {
      const response = await termsClient.acceptTerms(latestVersion);
      if (response.accepted) {
        invalidateTermsQuery();
        navigate(AppRoutes.dashboard.route);
      }
    } finally {
      setIsAccepting(false);
    }
  };

  const onDownload = () => {
    if (!pdfObjectURL) return;

    const a = document.createElement("a");
    a.href = pdfObjectURL;
    a.download = `BillingSync Terms v${latestVersion}.pdf`;
    document.body.appendChild(a);
    a.click();
  };

  const TermsAcceptedActions = (
    <PrimaryButton component={LinkBehavior} href={AppRoutes.dashboard.route}>
      To Dashboard
    </PrimaryButton>
  );

  const TermsNotAcceptedActions = (
    <>
      <CancelButton
        component={LinkBehavior}
        href={AppRoutes.selectPartner.route}
        disabled={isAccepting}
      >
        Decline
      </CancelButton>
      <PrimaryButton onClick={onAccept} loading={isAccepting}>
        Accept
      </PrimaryButton>
    </>
  );

  return (
    <Box display="flex" justifyContent="center">
      <Stack gap={2} width={isBigScreen ? "70%" : "100%"}>
        <Box display="flex" justifyContent="center">
          <TextHeader testId="page-header" label="Terms and conditions" />
        </Box>
        {isTermsPdfFetching && <Skeleton variant="rounded" height={500} />}
        {!isTermsPdfFetching && pdfObjectURL && (
          <embed src={`${pdfObjectURL}#toolbar=0&navpanes=0&scrollbar=0`} height="1000px" />
        )}
        {!isTermsPdfFetching && pdfObjectURL && (
          <Box display="flex" gap={2}>
            <NormalButton onClick={onDownload} sx={{ mr: "auto" }}>
              Download
            </NormalButton>
            {isAccepted ? TermsAcceptedActions : TermsNotAcceptedActions}
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default TermsPage;
