import { Agreement, BillingMotion, Target } from "api/client.generated";

const connectWiseFilter = (
  agreement: Agreement,
  billingMotion: BillingMotion,
): boolean => {
  switch (billingMotion) {
    case BillingMotion.SeatBased:
    case BillingMotion.AzurePlan:
      return agreement.billingType?.toLowerCase() === "monthly";
    case BillingMotion.SeatBasedAnnual:
      return agreement.billingType?.toLowerCase() === "one time";
    default:
      return false;
  }
};

const autotaskFilter = (
  agreement: Agreement,
  billingMotion: BillingMotion,
): boolean => {
  switch (billingMotion) {
    case BillingMotion.SeatBased:
    case BillingMotion.AzurePlan:
      return agreement.billingType?.toLowerCase() === "recurring service";
    case BillingMotion.SeatBasedAnnual:
      return agreement.billingType?.toLowerCase() === "fixed price";
    default:
      return false;
  }
};

const filterTargetAgreementsByBillingMotion = (
  agreement: Agreement,
  target: Target,
  billingMotion: BillingMotion,
): boolean => {
  switch (target) {
    case Target.ConnectWise:
      return connectWiseFilter(agreement, billingMotion);
    case Target.Autotask:
      return autotaskFilter(agreement, billingMotion);
    case Target.Syncro:
      return true;
    default:
      throw Error(`Unsupported target value: ${target}`);
  }
};

export default filterTargetAgreementsByBillingMotion;
