import { useQuery } from "@tanstack/react-query";
import { GetVendorsResponse } from "api/client.generated";
import useApi from "./useApi";

const useAutotaskVendors = (): {
  vendors: GetVendorsResponse | undefined;
  isVendorsFetching: boolean;
} => {
  const { autotaskApiProxyClient } = useApi();

  const { data: vendors, isFetching: isVendorsFetching } = useQuery({
    queryKey: ["getVendors"],
    queryFn: () => autotaskApiProxyClient.getVendors(),
  });

  return { vendors, isVendorsFetching };
};

export default useAutotaskVendors;
