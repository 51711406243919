import {
  Box,
  List,
  ListItemButton,
  Paper,
  Skeleton,
  Stack,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import { PartnerModel } from "api/client.generated";
import TextHeader from "components/primitives/TextHeader";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import useListPartners from "hooks/api/useListPartners";
import useDebounce from "hooks/useDebounce";
import { ArrowRightOutlinedIcon } from "images/MuiIcons";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";

const SelectPartnerPage = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const searchFilterDebounced = useDebounce(searchFilter);
  const { pagedPartnersResult, isPagedPartnersResultFetching } =
    useListPartners(searchFilterDebounced);

  const theme = useTheme();
  const { setPartner } = useSelectedPartnerContext();
  const { invalidatePartnerConfig } = usePartnerConfigContext();
  const navigate = useNavigate();

  const rowsPerPageOptions = useMemo<number[]>(() => [5, 10, 15], []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const onPageChange = (_event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const listItemContent = (partner: PartnerModel) => (
    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
      <Box>
        <Typography variant="h6" testId={partner.name}>
          {partner.name}
        </Typography>
        <Typography variant="body2" color={theme.palette.grey[500]} testId={partner.id}>
          {partner.id}
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="body2">{partner.distributor}</Typography>
        <ArrowRightOutlinedIcon />
      </Box>
    </Box>
  );

  const onPartnerClick = (partner: PartnerModel) => {
    setPartner({ id: partner.id, name: partner.name ?? "" });

    setTimeout(() => {
      invalidatePartnerConfig();
      navigate(AppRoutes.dashboard.route);
    }, 300);
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
      <TextHeader testId="page-header" label="Choose a company" />
      <Stack component={Paper} p={3} style={{ width: "50%", minWidth: "500px" }} gap={2}>
        <TextField
          label="Search"
          placeholder="Partner name or ID"
          testId="Search"
          onChange={(event) => setSearchFilter(event.target.value)}
          sx={{
            width: "100%",
            input: {
              color: theme.palette.secondary.main,
              "&::placeholder": { color: theme.palette.secondary.main },
            },
          }}
        />
        <List sx={{ minHeight: rowsPerPage * 80.5 }}>
          {isPagedPartnersResultFetching
            ? Array.from(Array(rowsPerPage).keys()).map((x) => (
                <Skeleton key={x} variant="rounded" sx={{ height: 69, marginTop: 1 }} />
              ))
            : pagedPartnersResult
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((p, i) => (
                  <ListItemButton
                    key={p.id + String(i)}
                    onClick={() => onPartnerClick(p)}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: theme.palette.divider,
                      borderRadius: "4px",
                      marginTop: 1,
                    }}
                  >
                    {listItemContent(p)}
                  </ListItemButton>
                ))}
        </List>
        <TablePagination
          component={Box}
          count={pagedPartnersResult?.length ?? 0}
          page={page}
          onPageChange={onPageChange}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{ mt: "auto" }}
        />
      </Stack>
    </Box>
  );
};

export default SelectPartnerPage;
