import { useQuery } from "@tanstack/react-query";
import { FileResponse } from "api/client.generated";
import useApi from "./useApi";

interface UseTermsPdfReturn {
  termsPdf: FileResponse | undefined;
  isTermsPdfFetching: boolean;
}

const useTermsPdf = (version: number): UseTermsPdfReturn => {
  const { termsClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["getTermsPdf"],
    queryFn: () => termsClient.getTermPdf(version),
  });

  return { termsPdf: data, isTermsPdfFetching: isFetching };
};

export default useTermsPdf;
