import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetTermsDataResponse } from "api/client.generated";
import useApi from "./useApi";

interface UseTermsReturn {
  terms: GetTermsDataResponse | undefined;
  isTermsFetching: boolean;
  invalidateTermsQuery: () => void;
}

const useTerms = (isQueryEnable: boolean): UseTermsReturn => {
  const { termsClient } = useApi();
  const queryKeys = ["getTerms"];

  const { data, isFetching } = useQuery({
    queryKey: queryKeys,
    queryFn: () => termsClient.getTerms(),
    enabled: isQueryEnable,
  });

  const queryClient = useQueryClient();
  const invalidateTermsQuery = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys });
  };

  return { terms: data, isTermsFetching: isFetching, invalidateTermsQuery };
};

export default useTerms;
