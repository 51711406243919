import { useQuery } from "@tanstack/react-query";
import { ListPartnerState, PartnerModel } from "api/client.generated";
import useApi from "./useApi";

interface UseListPartnersReturn {
  pagedPartnersResult: PartnerModel[] | undefined;
  isPagedPartnersResultFetching: boolean;
}

const useListPartners = (search: string): UseListPartnersReturn => {
  const { partnersClient } = useApi();

  const loadData = async (): Promise<PartnerModel[] | undefined> => {
    const take = 100;
    let result;
    for (let i = 0; ; i += 1) {
      const response = await partnersClient.listPartners(
        search,
        ListPartnerState.Active,
        i * take,
        take,
      );

      result = !result ? response.items : [...result, ...response!.items!];

      if (response.paging!.count <= take + i * take) break;
    }

    return result;
  };

  const {
    data: pagedPartnersResult,
    isFetching: isPagedPartnersResultFetching,
  } = useQuery({
    queryKey: ["listPartners", search],
    queryFn: loadData,
  });

  return { pagedPartnersResult, isPagedPartnersResultFetching };
};

export default useListPartners;
