import SyncOverview from "components/layout/Sync/SyncOverview";
import { useParams } from "react-router-dom";
import SyncRouteParams from "types/sync-route-params";

const BillingSyncPage = () => {
  const { source, target, program } = useParams<
    keyof SyncRouteParams
  >() as SyncRouteParams;

  return (
    <SyncOverview
      syncType="Billing"
      source={source}
      target={target}
      program={program}
    />
  );
};

export default BillingSyncPage;
