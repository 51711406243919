import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  useTheme,
} from "@crayon/design-system-react";
import { useSidebarContext } from "context/sideBarContext";
import useScreen from "hooks/useScreen";
import { NavLink } from "react-router-dom";

interface SideMenuItemProps {
  text: string;
  menuIcon: React.ReactNode;
  to: string;
  sx?: SxProps<Theme>;
  trailingIcon?: React.ReactNode;
  testId?: string;
}

const SideMenuItem = ({
  text,
  menuIcon,
  to,
  sx = undefined,
  trailingIcon = undefined,
  testId = undefined,
}: SideMenuItemProps) => {
  const theme = useTheme();
  const { toggleSidebarOpen } = useSidebarContext();
  const { isBigScreen } = useScreen();

  const onClick = () => {
    if (!isBigScreen) toggleSidebarOpen();
  };

  return (
    <NavLink to={to} style={{ textDecoration: "none", color: "inherit" }}>
      {({ isActive }) => (
        <ListItemButton
          selected={isActive}
          sx={{
            [`&.Mui-selected`]: { color: theme.palette.grey[900] },
            color: theme.palette.secondary.main,
            borderRadius: 2,
            ml: 1,
            mr: 1,
            pl: 1,
            pr: 1,
            ...sx,
          }}
          onClick={onClick}
          {...(testId && { testId })}
        >
          <ListItemIcon
            sx={{
              color: theme.palette.secondary.main,
              ...(isActive && { color: theme.palette.grey[900] }),
            }}
          >
            {menuIcon}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: "subtitle2",
              fontWeight: "medium",
            }}
            primary={text}
          />
          <ListItemIcon sx={{ direction: "rtl" }}>{trailingIcon}</ListItemIcon>
        </ListItemButton>
      )}
    </NavLink>
  );
};

export default SideMenuItem;
