import { CustomerMapping } from "api/client.generated";

interface CustomerMappingViewModel extends CustomerMapping {
  statusIcon: React.ReactElement;
}

export const ConvertToCustomerMapping = (
  mapping: CustomerMappingViewModel,
): CustomerMapping =>
  ({
    billingMotion: mapping.billingMotion,
    errorMessage: mapping.errorMessage,
    hasNoInitialSync: mapping.hasNoInitialSync,
    id: mapping.id,
    isLastSyncError: mapping.isLastSyncError,
    isLastSyncSuccess: mapping.isLastSyncSuccess,
    isSyncing: mapping.isSyncing,
    lastProcessedInvoice: mapping.lastProcessedInvoice,
    lastProcessedInvoiceApprovalDate: mapping.lastProcessedInvoiceApprovalDate,
    lastSuccessfulSync: mapping.lastSuccessfulSync,
    lastSync: mapping.lastSync,
    pricingSyncStrategy: mapping.pricingSyncStrategy,
    programType: mapping.programType,
    sourceAgreementMissing: mapping.sourceAgreementMissing,
    sourceCustomer: mapping.sourceCustomer,
    startSyncDate: mapping.startSyncDate,
    targetAgreementMissing: mapping.targetAgreementMissing,
    targetCustomer: mapping.targetCustomer,
    warnings: mapping.warnings,
  }) as CustomerMapping;

export default CustomerMappingViewModel;
