import { ProgramType, Source, Target } from "api/client.generated";
import { createContext, useContext, useMemo } from "react";
import SyncType from "types/sync-type";

interface ISyncOverviewContext {
  source: Source;
  target: Target;
  program: ProgramType;
  syncType: SyncType;
}

const SyncOverviewContext = createContext<ISyncOverviewContext | null>(null);

interface SyncOverviewContextProviderProps extends ISyncOverviewContext {
  children: React.ReactNode;
}

export const SyncOverviewContextProvider = (
  props: SyncOverviewContextProviderProps,
) => {
  const { children, source, target, program, syncType } = props;
  const contextValue = useMemo<ISyncOverviewContext>(
    () => ({ source, target, program, syncType }),
    [source, target, program, syncType],
  );
  return (
    <SyncOverviewContext.Provider value={contextValue}>
      {children}
    </SyncOverviewContext.Provider>
  );
};

export const useSyncOverviewContext = (): ISyncOverviewContext => {
  const context = useContext(SyncOverviewContext);
  if (!context)
    throw new Error(
      "useSyncOverviewContext must be used within a SyncOverviewContextProvider",
    );

  return context;
};
