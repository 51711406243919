import {
  Avatar,
  Box,
  Chip,
  IconButton,
  LinkBehavior,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@crayon/design-system-react";
import { ProductMapping, TargetProductType } from "api/client.generated";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodySkeleton from "components/primitives/TableBodySkeleton";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import { useSyncOverviewContext } from "context/syncOverviewContext";
import { EditIcon } from "images/MuiIcons";
import { EditProductMappingNavState } from "pages/Sync/Product/EditProductMappingPage";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import AppRoutes from "routes/app-routes";
import DefaultPaginationOptions from "types/defaultPaginationOptions";

export interface ProductMappingTableRef {
  resetSelectedPage: () => void;
}
interface ProductMappingTableProps {
  mappings: ProductMapping[];
  isLoading: boolean;
}

const ProductMappingTable = forwardRef<ProductMappingTableRef, ProductMappingTableProps>(
  (props, ref) => {
    const { mappings, isLoading } = props;
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const rowsPerPageOptions = useMemo<number[]>(() => DefaultPaginationOptions, []);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const { source, target, program } = useSyncOverviewContext();

    useImperativeHandle(ref, () => ({
      resetSelectedPage: () => setPage(0),
    }));

    const columnOptions = useMemo<string[]>(
      () => [
        "SKU",
        `${source} Name`,
        `${target} Name`,
        "Billing Cycle",
        "Billing Term Duration",
        "Billing Term Units",
        "Target ID",
      ],
      [source, target],
    );

    const onRowsPerPageChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const renderHeaderCell = (data: string, i: number) => (
      <TableHeaderCell
        key={data + String(i)}
        colSpan={i === columnOptions.length - 1 ? 2 : 1}
        columnName={data}
      />
    );

    const renderTargetIdChip = (mapping: ProductMapping) => (
      <TableCell>
        <Chip
          label={mapping.targetId}
          avatar={<Avatar>{mapping.targetType === TargetProductType.Product ? "P" : "S"}</Avatar>}
          sx={{
            ".MuiChip-label": {
              color: theme.palette.secondary.main,
            },
            ".MuiChip-avatar": {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              background: "none",
            },
          }}
        />
      </TableCell>
    );

    const renderEditButton = (mapping: ProductMapping) => (
      <TableCell align="right">
        <IconButton
          color="primary"
          component={LinkBehavior}
          href={AppRoutes.productSyncEditMapping.buildRoute(
            source,
            target,
            program,
            `${mapping.id}`,
          )}
          state={{ mapping } as EditProductMappingNavState}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    );

    const DataTableBody = (
      <TableBody>
        {mappings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((mapping, i) => (
          <TableRow key={(mapping.sourceName ?? "") + (mapping.targetName ?? "") + String(i)}>
            <TableBodyCell label={mapping.sku} />
            <TableBodyCell label={mapping.sourceName} />
            <TableBodyCell label={mapping.targetName} />
            <TableBodyCell label={mapping.billingCycle} />
            <TableBodyCell label={`${mapping.billingTermDuration}`} />
            <TableBodyCell label={mapping.billingTerm} />
            {renderTargetIdChip(mapping)}
            {renderEditButton(mapping)}
          </TableRow>
        ))}
      </TableBody>
    );

    return (
      <TableContainer>
        <Table testId="Product Mapping">
          <TableHead>
            <TableRow>{columnOptions.map(renderHeaderCell)}</TableRow>
          </TableHead>
          {isLoading ? (
            <TableBodySkeleton colsCount={columnOptions.length} rowsCount={3} />
          ) : (
            DataTableBody
          )}
        </Table>
        <TablePagination
          component={Box}
          count={mappings.length}
          page={page}
          onPageChange={(_: React.MouseEvent<HTMLButtonElement>, newPage: number) =>
            setPage(newPage)
          }
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{ my: 1 }}
        />
      </TableContainer>
    );
  },
);

ProductMappingTable.displayName = "ProductMappingTable";

export default ProductMappingTable;
