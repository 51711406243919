import { Box, Stack } from "@crayon/design-system-react";
import { ProgramStatus } from "api/client.generated";
import ProgramCard from "components/hoc/Programs/ProgramCard";
import TextHeader from "components/primitives/TextHeader";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import { ArrowRightIcon } from "images/MuiIcons";
import AppRoutes from "routes/app-routes";
import { Endpoint } from "types/Endpoint";

const ProgramsPage = () => {
  const { partnerConfig } = usePartnerConfigContext();

  return (
    <Stack spacing={4}>
      <TextHeader testId="page-header" label="Programs" />

      {partnerConfig?.configuredPrograms?.map((x) => (
        <Stack spacing={2} key={String(x.source) + String(x.target)}>
          <Box display="flex" alignItems="center">
            <Box component="img" src={Endpoint.getLogo(x.source)} width={120} />
            <ArrowRightIcon />
            <Box component="img" src={Endpoint.getLogo(x.target)} width={143} />
          </Box>
          {x.programs?.map((program) => (
            <ProgramCard
              key={String(x.source) + String(x.target) + String(program.type)}
              name={program.name ?? ""}
              isConfigured={program.status !== ProgramStatus.NotConfigured}
              isActive={program.status === ProgramStatus.Syncing}
              editUrl={AppRoutes.programEdit.buildRoute(
                x.source,
                x.target,
                program.type,
              )}
            />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default ProgramsPage;
