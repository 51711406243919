import { Stack, Typography } from "@crayon/design-system-react";
import { SyncOperation } from "api/client.generated";
import UsageSyncOperationsTable from "components/hoc/Sync/Billing/Digest/UsageSyncOperationsTable";

interface UsageSyncOperationsLayoutProps {
  syncOperations: SyncOperation[] | undefined;
  errorMessage: string | undefined;
  warnings: string[] | undefined;
}

const UsageSyncOperationsLayout = (props: UsageSyncOperationsLayoutProps) => {
  const { syncOperations, errorMessage, warnings } = props;

  const ErrorSlice = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Error</Typography>
      <Typography variant="body2" fontWeight="medium">
        {errorMessage}
      </Typography>
    </Stack>
  );

  const WarningSlice = (
    <Stack spacing={1}>
      <Typography variant="subtitle2">Warnings</Typography>
      {warnings?.map((w, i) => (
        <Typography key={w + String(i)} variant="body2">{`- ${w}`}</Typography>
      ))}
    </Stack>
  );

  return (
    <Stack p={1} spacing={1}>
      {errorMessage && ErrorSlice}
      {Boolean(warnings?.length) && WarningSlice}

      {Boolean(syncOperations?.length) && (
        <Typography variant="subtitle1">Operations</Typography>
      )}

      {Boolean(syncOperations?.length) && (
        <UsageSyncOperationsTable syncOperations={syncOperations} />
      )}
    </Stack>
  );
};

export default UsageSyncOperationsLayout;
