import { useQuery } from "@tanstack/react-query";
import {
  ProgramConfigurationResponse,
  ProgramType,
  Source,
  Target,
} from "api/client.generated";
import useApi from "./useApi";

interface UseProgramConfigurationReturn {
  programConfig: ProgramConfigurationResponse | undefined;
  isProgramConfigFetching: boolean;
}

const useProgramConfiguration = (
  source: Source,
  target: Target,
  program: ProgramType,
): UseProgramConfigurationReturn => {
  const { programsClient } = useApi();

  const loadData = async (): Promise<
    ProgramConfigurationResponse | undefined
  > => {
    const response = await programsClient.getProgramConfiguration(
      source,
      target,
    );
    return response.configurations?.find((x) => x.programType === program);
  };

  const { data: programConfig, isFetching: isProgramConfigFetching } = useQuery(
    {
      queryKey: ["getProgramConfiguration", source, target],
      queryFn: loadData,
    },
  );

  return { programConfig, isProgramConfigFetching };
};

export default useProgramConfiguration;
