import {
  Box,
  NormalButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { PricingAction } from "api/client.generated";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import { useSamplePricingContext } from "context/samplePricingContext";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import useApi from "hooks/api/useApi";
import useScreen from "hooks/useScreen";
import { useState } from "react";
import { useForm } from "react-hook-form";
import NotificationMessage from "types/notification-message";
import * as yup from "yup";

const SamplePricingForm = () => {
  const [customerPrice, setCustomerPrice] = useState("");
  const [margin, setMargin] = useState("");
  const theme = useTheme();
  const { priceRulesClient } = useApi();
  const { partner } = useSelectedPartnerContext();

  const [isTesting, setIsTesting] = useState(false);

  const { action, rate, isActionRateValidFn } = useSamplePricingContext();
  const { raiseErrorNotification } = useNotificationContext();
  const { mdMatch } = useScreen();

  const numberRegex = /^[0-9]*[.]{0,1}[0-9]*$/;

  const isTestDisabled = (): boolean => {
    // action should be selected
    if (!action) return true;

    // rate should be a specified number for non 'At' actions
    if (action.pricingAction !== PricingAction.At) {
      if (!`${rate}`.match(numberRegex)) return true;
    }

    return false;
  };

  // prettier-ignore
  const formSchema = yup.object().shape({
    retailPrice: yup
      .string()
      .required("Required")
      .matches(numberRegex, "Price is not valid"),
    costPrice: yup
      .string()
      .required("Required")
      .matches(numberRegex, "Price is not valid"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const { handleSubmit, control } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      retailPrice: "25.00",
      costPrice: "17.50",
    },
  });

  const onTest = async (data: SchemaType) => {
    // Will trigger validation of 'action' and 'rate'
    // price rule form fields. If invalid, fields will be highlighted
    // with validation error message.
    if ((await isActionRateValidFn?.()) === false) {
      setCustomerPrice("");
      setMargin("");
      return;
    }

    let rawRate = 0;
    if (rate) rawRate = action?.isPercentage ? rate / 100 : rate;

    try {
      setIsTesting(true);
      const response = await priceRulesClient.testPriceRule(
        partner?.id ?? "",
        +data.costPrice,
        +data.retailPrice,
        rawRate,
        action?.id,
      );

      setCustomerPrice(response.customerPrice.toFixed(2));
      setMargin((response.margin * 100).toFixed(2));
    } catch (e) {
      raiseErrorNotification(NotificationMessage.FAILED_TO_TEST_PRICE_RULE);
    } finally {
      setIsTesting(false);
    }
  };

  return (
    <Box component="form" display="flex">
      <Paper width="430px" p={2} component={Stack} spacing={4}>
        <Typography variant="body1" color={theme.palette.text.secondary}>
          Sample Pricing
        </Typography>
        <FormTextField
          control={control}
          bindSchemaFieldName="retailPrice"
          label="Retail Price"
          testId="Retail Price"
        />
        <FormTextField
          control={control}
          bindSchemaFieldName="costPrice"
          label="Cost Price"
          testId="Cost Price"
        />
        <TextField
          label="Customer Price"
          testId="Customer Price"
          value={customerPrice}
          InputProps={{ readOnly: true }}
          sx={{
            input: {
              color: theme.palette.secondary.main,
            },
          }}
        />
        <TextField
          label="Margin %"
          testId="Margin %"
          value={margin}
          InputProps={{ readOnly: true }}
          sx={{
            input: {
              color: theme.palette.secondary.main,
            },
          }}
        />

        <Box display="flex" sx={{ ...(mdMatch && { "&&": { mt: "auto" } }) }}>
          <NormalButton
            testId="test-pricing"
            onClick={handleSubmit(onTest)}
            loading={isTesting}
            sx={{ ml: "auto" }}
            disabled={isTestDisabled()}
          >
            Test
          </NormalButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default SamplePricingForm;
