import { Box, Snackbar } from "@crayon/design-system-react";
import { useNotificationContext } from "context/notificationContext";
import SnackbarToast from "./SnackbarToast";

const SnackbarToastContainer = () => {
  const { notifications } = useNotificationContext();

  return (
    <Snackbar open={!!notifications.length}>
      <Box display="flex" flexDirection="column" gap={1}>
        {notifications.map((message) => (
          <SnackbarToast key={message.id} message={message} />
        ))}
      </Box>
    </Snackbar>
  );
};

export default SnackbarToastContainer;
