import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@crayon/design-system-react";
import { SubscriptionUsageRecordSyncResult, SyncOutcome } from "api/client.generated";
import UsageSyncOperationsLayout from "components/layout/Sync/UsageSyncOperationsLayout";
import SyncOutcomeIcon from "components/primitives/SyncOutcomeIcon";

import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodyDateCell from "components/primitives/TableBodyDateCell";
import TableBodySyncOutcomeCell from "components/primitives/TableBodySyncOutcomeCell";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import TableRowCollapsible from "components/primitives/TableRowCollapsible";
import { useMemo } from "react";

interface UsageSyncDetailsTableProps {
  usageSyncResults: SubscriptionUsageRecordSyncResult[] | undefined;
}

const UsageSyncDetailsTable = (props: UsageSyncDetailsTableProps) => {
  const { usageSyncResults } = props;

  const isExtraIconColumn = useMemo<boolean>(
    () =>
      usageSyncResults?.some(
        (x) =>
          x.outcome === SyncOutcome.Error ||
          x.outcome === SyncOutcome.Warning ||
          Boolean(x.warnings?.length),
      ) ?? false,
    [usageSyncResults],
  );

  const baseColumnOptions = useMemo<string[]>(
    () => ["Start Date", "End Date", "Quantity", "Cost Price", "Retail Price", "Outcome"],
    [],
  );

  const columnOptions = useMemo<string[]>(
    () => (isExtraIconColumn ? ["", ...baseColumnOptions] : baseColumnOptions),
    [baseColumnOptions, isExtraIconColumn],
  );

  const collapsibleContent = (syncResult: SubscriptionUsageRecordSyncResult) => {
    if (!syncResult.errorMessage && !syncResult.operations?.length && !syncResult.warnings?.length)
      return undefined;

    return (
      <UsageSyncOperationsLayout
        syncOperations={syncResult.operations}
        errorMessage={syncResult.errorMessage}
        warnings={syncResult.warnings}
      />
    );
  };

  const dataTableBody = (usageResults: SubscriptionUsageRecordSyncResult[]) => (
    <TableBody>
      {usageResults.map((syncResult, i) => (
        <TableRowCollapsible
          key={(syncResult.sourceUsageRecord?.id ?? "") + String(i)}
          columnCount={columnOptions.length}
          oneRow={false}
          collapsibleContent={collapsibleContent(syncResult)}
        >
          {isExtraIconColumn && (
            <TableBodyCell
              icon={
                <SyncOutcomeIcon
                  syncOutcome={syncResult.outcome}
                  isAnyWarnings={Boolean(syncResult.warnings?.length)}
                />
              }
              sxRoot={{ maxWidth: "30px", padding: 0, paddingLeft: 2 }}
            />
          )}
          <TableBodyDateCell date={syncResult.sourceUsageRecord?.startDate} />
          <TableBodyDateCell date={syncResult.sourceUsageRecord?.endDate} />
          <TableBodyCell label={syncResult.sourceUsageRecord?.quantity} />
          <TableBodyCell label={syncResult.sourceUsageRecord?.costUnitPrice} />
          <TableBodyCell label={syncResult.sourceUsageRecord?.retailUnitPrice} />
          <TableBodySyncOutcomeCell syncOutcome={syncResult.outcome} />
        </TableRowCollapsible>
      ))}
    </TableBody>
  );

  const noDataTableBody = () => (
    <TableBody>
      <TableRow>
        <TableBodyCell label="No data available" align="center" colSpan={columnOptions.length} />
      </TableRow>
    </TableBody>
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columnOptions.map((c, i) => (
              <TableHeaderCell key={c + String(i)} columnName={c} />
            ))}
          </TableRow>
        </TableHead>
        {usageSyncResults?.length ? dataTableBody(usageSyncResults!) : noDataTableBody()}
      </Table>
    </TableContainer>
  );
};

export default UsageSyncDetailsTable;
