/*
  The idea is to wrap all used Mui icons and have:
   1. Clear understanding what's the icon from it's name in the context of BSP portal.
      Example: WarningIcon in exclamation mark but Mui icon name is 'ErrorIcon'.
   
      2. Ability to quick&easy update icons across the project
      (update here vs update the same icon in each place where it's used)
 */
import MuiAccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import MuiAddIcon from "@mui/icons-material/Add";
import MuiAppsRoundedIcon from "@mui/icons-material/AppsRounded";
import MuiArrowRightIcon from "@mui/icons-material/ArrowRight";
import MuiArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import MuiBookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import MuiBuildRoundedIcon from "@mui/icons-material/BuildRounded";
import MuiCancelIcon from "@mui/icons-material/Cancel";
import MuiCardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import MuiCheckIcon from "@mui/icons-material/Check";
import MuiCheckCircleIcon from "@mui/icons-material/CheckCircle";
import MuiCheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MuiContactsIcon from "@mui/icons-material/Contacts";
import MuiContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";
import MuiContentCopyIcon from "@mui/icons-material/ContentCopy";
import MuiEditRoundedIcon from "@mui/icons-material/EditRounded";
import MuiEmailIcon from "@mui/icons-material/Email";
import MuiErrorIcon from "@mui/icons-material/Error";
import MuiErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MuiExpandLess from "@mui/icons-material/ExpandLess";
import MuiExpandMore from "@mui/icons-material/ExpandMore";
import MuiGavelIcon from "@mui/icons-material/Gavel";
import MuiInventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MuiLanRoundedIcon from "@mui/icons-material/LanRounded";
import MuiLogoutIcon from "@mui/icons-material/Logout";
import MuiMenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import MuiMenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MuiNotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import MuiPriceChangeRoundedIcon from "@mui/icons-material/PriceChangeRounded";
import MuiQuizIcon from "@mui/icons-material/Quiz";
import MuiReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MuiSettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import MuiSwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import MuiSyncIcon from "@mui/icons-material/Sync";
import MuiSyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import MuiSyncLockIcon from "@mui/icons-material/SyncLock";

export const CheckCircleIcon = MuiCheckCircleIcon;
export const CheckCircleOutlineIcon = MuiCheckCircleOutlineIcon;
export const CheckIcon = MuiCheckIcon;
export const WarningCirceIcon = MuiErrorIcon;
export const WarningOutlineIcon = MuiErrorOutlineIcon;
export const ErrorCircleIcon = MuiCancelIcon;
export const BookmarkIcon = MuiBookmarkRoundedIcon;
export const EditIcon = MuiEditRoundedIcon;
export const NotificationsIcon = MuiNotificationsRoundedIcon;
export const UpdatesIcon = MuiBuildRoundedIcon;
export const SyncIcon = MuiSyncIcon;
export const SyncLockIcon = MuiSyncLockIcon;
export const SyncDisabledIcon = MuiSyncDisabledIcon;
export const ExpandLessIcon = MuiExpandLess;
export const ExpandMoreIcon = MuiExpandMore;
export const UserGuideIcon = MuiMenuBookRoundedIcon;
export const ContactSupportIcon = MuiContactSupportRoundedIcon;
export const UserAccountIcon = MuiAccountCircleRoundedIcon;
export const MenuIcon = MuiMenuRoundedIcon;
export const DashboardIcon = MuiAppsRoundedIcon;
export const ConfigurationIcon = MuiSettingsRoundedIcon;
export const ConnectionsIcon = MuiLanRoundedIcon;
export const ProgramsIcon = MuiCardMembershipRoundedIcon;
export const PriceRulesIcon = MuiPriceChangeRoundedIcon;
export const TestIcon = MuiQuizIcon;
export const ArrowRightIcon = MuiArrowRightIcon;
export const AddIcon = MuiAddIcon;
export const ArrowRightOutlinedIcon = MuiArrowRightOutlinedIcon;
export const ReceiptOutlinedIcon = MuiReceiptOutlinedIcon;
export const Inventory2OutlinedIcon = MuiInventory2OutlinedIcon;
export const LogoutIcon = MuiLogoutIcon;
export const SwitchAccountIcon = MuiSwitchAccountIcon;
export const GavelIcon = MuiGavelIcon;
export const ContactsIcon = MuiContactsIcon;
export const EmailIcon = MuiEmailIcon;
export const ContentCopyIcon = MuiContentCopyIcon;
