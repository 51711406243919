import {
  Box,
  Button,
  FormSelect,
  FormSelectChangeEvent,
  FormSelectOption,
  LinkBehavior,
  Stack,
  TextField,
  useTheme,
} from "@crayon/design-system-react";
import PriceRulesTable from "components/hoc/PriceRules/PriceRulesTable";
import TextHeader from "components/primitives/TextHeader";
import usePriceRules from "hooks/api/usePriceRules";
import useDebounce from "hooks/useDebounce";
import { AddIcon } from "images/MuiIcons";
import { useEffect, useMemo, useState } from "react";
import AppRoutes from "routes/app-routes";
import { PriceRuleTableModel } from "types/price-rule-model";
import SortDirection from "types/sort-direction";

const PriceRulesPage = () => {
  const theme = useTheme();
  const [priceRuleTableModels, setPriceRulesTableModels] = useState<PriceRuleTableModel[]>([]);
  const [searchText, setSearchText] = useState("");
  const searchTextDebounced = useDebounce(searchText);
  const [statusFilter, setStatusFilter] = useState("All");
  const statusFilterOptions: FormSelectOption[] = useMemo(
    () => [
      { value: "All", label: "All" },
      { value: "Active", label: "Active" },
      { value: "Expired", label: "Expired" },
      { value: "Future", label: "Future" },
    ],
    [],
  );

  const { priceRules, isPriceRulesFetching } = usePriceRules();

  useEffect(() => {
    if (priceRules?.length) {
      setPriceRulesTableModels(priceRules.map((x) => x.toTableModel()));
    }
  }, [priceRules]);

  const filterRules = (rule: PriceRuleTableModel): boolean => {
    if (statusFilter !== "All") {
      if (statusFilter !== rule.status) return false;
    }

    if (searchTextDebounced !== "") {
      const searchLowerCase = searchTextDebounced.toLocaleLowerCase();

      const match =
        rule.action.toLowerCase().includes(searchLowerCase) ||
        rule.source.toLowerCase().includes(searchLowerCase) ||
        rule.program.toLowerCase().includes(searchLowerCase) ||
        rule.customer.toLowerCase().includes(searchLowerCase) ||
        rule.product.toLowerCase().includes(searchLowerCase);

      return Boolean(match);
    }

    return true;
  };

  const onSortRequest = (
    newSortField: keyof PriceRuleTableModel,
    newSortDirection: SortDirection,
  ) => {
    const comparePriceRuleTableModels = (
      a: PriceRuleTableModel,
      b: PriceRuleTableModel,
    ): number => {
      const valueA = a[newSortField] ?? "";
      const valueB = b[newSortField] ?? "";

      if (valueA < valueB) return newSortDirection === "asc" ? -1 : 1;
      if (valueA > valueB) return newSortDirection === "asc" ? 1 : -1;
      return 0;
    };

    setPriceRulesTableModels([...priceRuleTableModels.sort(comparePriceRuleTableModels)]);
  };

  const HeaderSlice = (
    <Box display="flex">
      <TextHeader testId="page-header" label="Price Rules" />
      <Button
        component={LinkBehavior}
        testId="Add"
        href={AppRoutes.priceRuleAdd.route}
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ ml: "auto" }}
      >
        Add
      </Button>
    </Box>
  );

  const FiltersSlice = (
    <Box display="flex" gap={6}>
      <TextField
        label="Search"
        testId="Search"
        placeholder="Search in Action, Source, Program, Customer and Product"
        onChange={(event) => setSearchText(event.target.value)}
        sx={{
          flex: 1,
          input: {
            color: theme.palette.secondary.main,
            "&::placeholder": { color: theme.palette.secondary.main },
          },
        }}
      />
      <FormSelect
        id="pricerule-status"
        label="Status"
        testId="Status"
        onChange={(event: FormSelectChangeEvent<string>) => setStatusFilter(event.target.value)}
        options={statusFilterOptions}
        sx={{ flex: 1 }}
        value={statusFilter}
      />
    </Box>
  );

  return (
    <Stack spacing={2}>
      {HeaderSlice}
      {FiltersSlice}
      <PriceRulesTable
        priceRules={priceRuleTableModels?.filter(filterRules) ?? []}
        isLoading={isPriceRulesFetching}
        onSortRequest={onSortRequest}
      />
    </Stack>
  );
};

export default PriceRulesPage;
