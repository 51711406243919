import { Box, Skeleton, Stack, Typography, useTheme } from "@crayon/design-system-react";
import useSyncTypeGuard from "hooks/useSyncTypeGuard";
import { CheckCircleIcon, ErrorCircleIcon, WarningCirceIcon } from "images/MuiIcons";
import SyncType from "types/sync-type";
import pluralize from "utils/pluralize";

interface SyncStatisticsProps {
  errorCount: number;
  warningCount: number;
  successCount: number;
  secondarySuccessCount?: number;
  isLoading?: boolean;
  syncType?: SyncType;
  invoices?: string[];
}

const SyncStatistics = ({
  errorCount,
  warningCount,
  secondarySuccessCount = undefined,
  successCount,
  invoices = [],
  isLoading = false,
  syncType = "Product",
}: SyncStatisticsProps) => {
  const theme = useTheme();

  useSyncTypeGuard(syncType);

  const loadingSkeleton = <Skeleton width={85} />;

  const getBottomSectionText = () => {
    if (syncType === "Billing") {
      const invoicesText = !invoices?.length ? "" : `(invoices: ${invoices.join(", ")})`;

      return `${pluralize(successCount, "customer")} synced ${invoicesText}`;
    }

    // Product case
    return `${pluralize(successCount, "product")} synced`;
  };

  const ErrorSlice = (
    <Box display="flex" alignItems="center" gap={1}>
      <ErrorCircleIcon sx={{ color: theme.palette.error.main }} />
      {isLoading ? (
        loadingSkeleton
      ) : (
        <Typography testId="errorCount" variant="body2">
          {pluralize(errorCount, "error")}
        </Typography>
      )}
    </Box>
  );

  const WarningSlice = (
    <Box display="flex" alignItems="center" gap={1}>
      <WarningCirceIcon sx={{ color: theme.palette.warning.main }} />
      {isLoading ? (
        loadingSkeleton
      ) : (
        <Typography testId="warningCount" variant="body2">
          {pluralize(warningCount, "warning")}
        </Typography>
      )}
    </Box>
  );

  const SecondarySyncCount = (
    <Box display="flex" alignItems="center" gap={1}>
      <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
      {isLoading ? (
        loadingSkeleton
      ) : (
        <Typography testId="secondarySuccessCount" variant="body2">
          {`${pluralize(secondarySuccessCount ?? 0, "subscription")} synced`}
        </Typography>
      )}
    </Box>
  );

  const MainSyncCount = (
    <Box display="flex" alignItems="top" maxHeight="30px" gap={1}>
      <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
      {isLoading ? (
        loadingSkeleton
      ) : (
        <Typography testId="mainSyncCount" variant="body2" sx={{ mt: 0.2 }}>
          {getBottomSectionText()}
        </Typography>
      )}
    </Box>
  );

  return (
    <Stack spacing={2}>
      {ErrorSlice}
      {WarningSlice}
      {syncType === "Billing" && SecondarySyncCount}
      {MainSyncCount}
    </Stack>
  );
};

export default SyncStatistics;
