import {
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import { Source, Target } from "api/client.generated";
import ConnectionCard from "components/hoc/Connections/ConnectionCard";
import TextHeader from "components/primitives/TextHeader";
import usePartnerConnections from "hooks/api/usePartnerConnections";

const ConnectionsPage = () => {
  const { partnerConnections, isPartnerConnectionsFetching } =
    usePartnerConnections();
  const theme = useTheme();

  const LoadingSkeleton = <Skeleton variant="rounded" height={80} />;

  return (
    <Stack spacing={3}>
      <TextHeader testId="page-header" label="Connections" />

      {/* Sources */}
      <Stack spacing={2}>
        <Typography variant="h6" color={theme.palette.text.secondary}>
          Sources
        </Typography>
        {isPartnerConnectionsFetching
          ? LoadingSkeleton
          : Object.keys(partnerConnections?.configuredSources ?? {}).map(
              (source: Source, i) => (
                <ConnectionCard
                  key={
                    String(i) +
                    String(partnerConnections?.configuredSources[source])
                  }
                  status={partnerConnections?.configuredSources[source]}
                  endpointName={source}
                />
              ),
            )}
      </Stack>

      {/* Targets */}
      <Stack spacing={2}>
        <Typography variant="h6" color={theme.palette.text.secondary}>
          Targets
        </Typography>
        {isPartnerConnectionsFetching
          ? LoadingSkeleton
          : Object.keys(partnerConnections?.configuredTargets ?? {}).map(
              (target: Target, i) => (
                <ConnectionCard
                  key={
                    String(i) +
                    String(partnerConnections?.configuredTargets[target])
                  }
                  status={partnerConnections?.configuredTargets[target]}
                  endpointName={target}
                />
              ),
            )}
      </Stack>
    </Stack>
  );
};

export default ConnectionsPage;
