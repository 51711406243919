import { useQuery } from "@tanstack/react-query";
import { ProgramType, Source, Target } from "api/client.generated";
import useSyncTypeGuard from "hooks/useSyncTypeGuard";
import SyncDigestStatsViewModel from "types/sync-digest-stats-vm";
import SyncType from "types/sync-type";
import useApi from "./useApi";

interface UseSyncHistoryReturn {
  syncHistory: SyncDigestStatsViewModel[] | undefined;
  isSyncHistoryFetching: boolean;
}

const useSyncHistory = (
  source: Source,
  target: Target,
  program: ProgramType,
  syncType: SyncType,
  skip: number,
  take: number,
): UseSyncHistoryReturn => {
  useSyncTypeGuard(syncType);

  const { digestClient } = useApi();

  const loadData = async (): Promise<SyncDigestStatsViewModel[]> => {
    const syncDigestStats =
      syncType === "Billing"
        ? (
            await digestClient.getCustomerSyncHistory(
              source,
              target,
              program,
              skip,
              take,
            )
          )?.stats
        : (
            await digestClient.getProductSyncHistory(
              source,
              target,
              program,
              skip,
              take,
            )
          )?.stats;

    return syncDigestStats?.map((x) => new SyncDigestStatsViewModel(x)) ?? [];
  };

  const { data: syncHistory, isFetching: isSyncHistoryFetching } = useQuery({
    queryKey: ["syncHistory", source, target, program, syncType, skip, take],
    queryFn: loadData,
  });

  return { syncHistory, isSyncHistoryFetching };
};

export default useSyncHistory;
