import { Stack } from "@crayon/design-system-react";
import BillingMappingForm from "components/hoc/Sync/Billing/BillingMappingForm";
import TextHeader from "components/primitives/TextHeader";
import { useParams } from "react-router-dom";
import SyncRouteParams from "types/sync-route-params";

const AddEditBillingMappingPage = () => {
  const { source, target, program } = useParams<
    keyof SyncRouteParams
  >() as SyncRouteParams;

  return (
    <Stack spacing={2}>
      <TextHeader
        testId="page-header"
        label={`Billing Sync | ${source} ${">"} ${target} ${">"} ${program}`}
      />

      <BillingMappingForm />
    </Stack>
  );
};

export default AddEditBillingMappingPage;
