import { Box, Typography, useTheme } from "@crayon/design-system-react";

interface TextHeaderProps {
  label: string;
  icon?: React.ReactElement;
  testId?: string;
}

const TextHeader = ({ label, icon = undefined, testId = undefined }: TextHeaderProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      color={theme.palette.text.secondary}
      {...(testId && { testId })}
    >
      {icon}
      <Typography variant="h5">{label}</Typography>
    </Box>
  );
};

export default TextHeader;
