import { Box, CancelButton, LinkBehavior, PrimaryButton } from "@crayon/design-system-react";

interface FormActionButtonsProps {
  cancelRoute: string;
  isSaveLoading?: boolean;
  isSaveDisable?: boolean;
}

const FormActionButtons = ({
  cancelRoute,
  isSaveDisable = false,
  isSaveLoading = false,
}: FormActionButtonsProps) => (
  <Box display="flex" gap={2} justifyContent="flex-end">
    <CancelButton
      testId="Cancel"
      component={LinkBehavior}
      href={cancelRoute}
      disabled={isSaveLoading}
    >
      Cancel
    </CancelButton>
    <PrimaryButton type="submit" testId="Save" loading={isSaveLoading} disabled={isSaveDisable}>
      Save
    </PrimaryButton>
  </Box>
);

export default FormActionButtons;
