import { Stack } from "@crayon/design-system-react";
import { ProductMapping } from "api/client.generated";
import ProductMappingForm from "components/hoc/Sync/Product/ProductMappingForm";
import TextHeader from "components/primitives/TextHeader";
import { useLocation } from "react-router-dom";

export interface EditProductMappingNavState {
  mapping: ProductMapping;
}

const EditProductMappingPage = () => {
  const { state } = useLocation();
  const {
    mapping: { targetType },
  } = state as EditProductMappingNavState;

  return (
    <Stack spacing={2}>
      <TextHeader testId="page-header" label={`Edit ${targetType} Mapping`} />
      <ProductMappingForm />
    </Stack>
  );
};

export default EditProductMappingPage;
