import {
  Box,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@crayon/design-system-react";
import { useNotificationContext } from "context/notificationContext";
import { ContactsIcon, ContactSupportIcon, ContentCopyIcon, EmailIcon } from "images/MuiIcons";
import { BaseSyntheticEvent, useState } from "react";

const SupportMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { raiseSuccessNotification } = useNotificationContext();

  const onCopyEmailClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // to prevent handling click on Link
    e.preventDefault();

    await navigator.clipboard.writeText(process.env.REACT_APP_CRAYON_SUPPORT_EMAIL ?? "");

    raiseSuccessNotification("Email address copied to the clipboard");
  };

  return (
    <>
      <Tooltip title="Support">
        <IconButton
          aria-controls={open ? "account-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          backgroundColor="primary"
          onClick={(e: BaseSyntheticEvent) => setAnchorEl(e.currentTarget)}
          showBackground
          showBorder={false}
          testId="Support"
        >
          <ContactSupportIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 2,
            sx: {
              overflow: "visible",
              mt: 0.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => window.open(process.env.REACT_APP_CRAYON_CONTACT_URL)}>
          <ListItemIcon>
            <ContactsIcon fontSize="small" />
          </ListItemIcon>
          Contacts
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <Link
            href={`mailto:${process.env.REACT_APP_CRAYON_SUPPORT_EMAIL}`}
            underline="none"
            color="inherit"
          >
            <Box display="flex" gap={2} alignItems="center">
              Email Us
              <Tooltip title="Copy email">
                <IconButton size="small" ml={1} onClick={onCopyEmailClick}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SupportMenu;
