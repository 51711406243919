import useToggle from "hooks/useToggle";
import { createContext, useContext, useMemo } from "react";

interface ISideBarContext {
  isSidebarOpen: boolean;
  toggleSidebarOpen: () => void;
}

const SidebarContext = createContext<ISideBarContext | null>(null);

interface SidebarContextProviderProps {
  children: React.ReactNode;
}

export const SidebarContextProvider = ({
  children,
}: SidebarContextProviderProps) => {
  const [isSidebarOpen, toggleSidebarOpen] = useToggle(false);

  return (
    <SidebarContext.Provider
      value={useMemo(
        () => ({
          isSidebarOpen,
          toggleSidebarOpen,
        }),
        [isSidebarOpen, toggleSidebarOpen],
      )}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = (): ISideBarContext => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error(
      "useSidebarContext must be used within a SidebarContextProvider",
    );
  }

  return context;
};
