import { useQuery } from "@tanstack/react-query";
import { GetSyncroConfigurationResponse } from "api/client.generated";
import useApi from "./useApi";

interface UseSyncroConfigurationReturn {
  syncroConfig: GetSyncroConfigurationResponse | undefined;
  isSyncroConfigFetching: boolean;
  isSyncroConfigFetchingError: boolean;
}

const useSyncroConfiguration = (): UseSyncroConfigurationReturn => {
  const { syncroConfigurationClient } = useApi();

  const { data, isFetching, isLoadingError } = useQuery({
    queryKey: ["getSyncroConfiguration"],
    queryFn: () => syncroConfigurationClient.getSyncroConfiguration(),
  });

  return {
    syncroConfig: data,
    isSyncroConfigFetching: isFetching,
    isSyncroConfigFetchingError: isLoadingError,
  };
};

export default useSyncroConfiguration;
