import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import {
  GetProductSyncResultsResponse,
  ProductSyncResult,
  SyncOutcome,
} from "api/client.generated";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodySyncOutcomeCell from "components/primitives/TableBodySyncOutcomeCell";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import TableRowCollapsible from "components/primitives/TableRowCollapsible";
import { useMemo } from "react";

interface ProductSyncDetailsTableProps {
  productDigest: GetProductSyncResultsResponse | undefined;
  isProductDigestFetching: boolean;
}

const ProductSyncDetailsTable = (props: ProductSyncDetailsTableProps) => {
  const { productDigest, isProductDigestFetching } = props;

  const theme = useTheme();

  const columnOptions = useMemo<string[]>(
    () => [
      "Source Product",
      "SKU",
      "Cost Unit Price",
      "Retail Unit Price",
      "Target Product",
      "Status",
    ],
    [],
  );

  // the logic is taken from old UI
  const getSourceProductData = (syncResult: ProductSyncResult): string => {
    if (syncResult?.sourceProduct?.name) return syncResult?.sourceProduct?.name;

    if (syncResult.targetProduct?.id) return syncResult.targetProduct?.id;

    return "";
  };

  const getSkuData = (syncResult: ProductSyncResult): string => {
    if (syncResult?.sourceProduct?.sku) return syncResult?.sourceProduct?.sku;

    if (syncResult.sourceProduct?.sku) return syncResult.sourceProduct?.sku;

    return "";
  };

  const SkeletonTableBody = (
    <TableBody>
      {Array.from(Array(3).keys()).map((row) => (
        <TableRow key={row}>
          {columnOptions.map((col) => (
            <TableCell key={col}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );

  const syncErrorDetails = (syncResult: ProductSyncResult) => {
    if (syncResult.outcome !== SyncOutcome.Error && syncResult.outcome !== SyncOutcome.Warning)
      return undefined;

    return (
      <Typography
        variant="body2"
        fontWeight="medium"
        color={syncResult.outcome === SyncOutcome.Error ? theme.palette.error.main : "inherit"}
      >
        {syncResult.errorMessage}
      </Typography>
    );
  };

  const DataTableBody = (
    <TableBody>
      {productDigest?.digest?.results?.map((r, i) => (
        <TableRowCollapsible
          key={(r.sourceProduct?.id ?? "") + (r.targetProduct?.id ?? "") + String(i)}
          columnCount={columnOptions.length}
          collapsibleContent={syncErrorDetails(r)}
          oneRow
        >
          <TableBodyCell label={getSourceProductData(r)} />
          <TableBodyCell label={getSkuData(r)} />
          <TableBodyCell label={r.sourceProduct?.costUnitPrice ?? ""} />
          <TableBodyCell label={r.sourceProduct?.retailUnitPrice ?? ""} />
          <TableBodyCell label={r.targetProduct?.name ?? ""} />
          <TableBodySyncOutcomeCell syncOutcome={r.outcome} />
        </TableRowCollapsible>
      ))}
    </TableBody>
  );

  return (
    <TableContainer>
      <Table testId="Product Sync Details">
        <TableHead>
          <TableRow>
            {columnOptions.map((c, i) => (
              <TableHeaderCell key={c + String(i)} columnName={c} />
            ))}
          </TableRow>
        </TableHead>
        {isProductDigestFetching ? SkeletonTableBody : DataTableBody}
      </Table>
    </TableContainer>
  );
};

export default ProductSyncDetailsTable;
