import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  Skeleton,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from "@crayon/design-system-react";
import { Control, Controller, ControllerFieldState, FieldValues, Path } from "react-hook-form";

interface FormTextWithChipsProps<TField extends FieldValues> {
  label: string;
  bindSchemaFieldName: Path<TField>;
  control: Control<TField>;
  disabled?: boolean;
  sxRoot?: SxProps<Theme>;
  isLoading?: boolean;
  testId?: string;
}

const FormTextWithChips = <TField extends FieldValues>({
  label,
  bindSchemaFieldName,
  control,
  disabled = false,
  sxRoot = undefined,
  isLoading = false,
  testId = undefined,
}: FormTextWithChipsProps<TField>) => {
  const theme = useTheme();

  const isError = (fieldState: ControllerFieldState): boolean =>
    Boolean(Array.isArray(fieldState.error) && fieldState.error?.length);

  return (
    <Controller
      name={bindSchemaFieldName}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl error={isError(fieldState)} sx={{ ...sxRoot }} {...(testId && { testId })}>
          {isLoading ? (
            <Skeleton height={56} />
          ) : (
            <Autocomplete
              clearIcon={false}
              options={[]}
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              freeSolo
              multiple
              disabled={disabled}
              renderTags={(tagValue: Array<string>, getTagProps) =>
                tagValue.map((option: string, index: number) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    key={option + String(index)}
                    onDelete={() =>
                      field.onChange([
                        ...field.value.filter((_: unknown, i: number) => i !== index),
                      ])
                    }
                    sx={{
                      color: theme.palette.secondary.main,
                      fontSize: "16px",
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  error={isError(fieldState)}
                  label={label}
                  placeholder="Type and press enter"
                  {...params}
                  sx={{
                    input: {
                      color: theme.palette.secondary.main,
                      "&::placeholder": { color: "grey" },
                    },
                  }}
                  {...(testId && { testId })}
                />
              )}
              {...(testId && { testId })}
            />
          )}
          <FormHelperText {...(testId && { testId: `${testId}-helper-text` })}>
            {Array.isArray(fieldState.error)
              ? fieldState.error?.find((x) => Boolean(x?.message))?.message
              : fieldState.error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormTextWithChips;
