import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme,
} from "@crayon/design-system-react";
import { ExpandLessIcon, ExpandMoreIcon } from "images/MuiIcons";

interface ISideMenuItemCollapsible {
  text: string;
  menuIcon: React.ReactNode;
  onClick: () => void;
  isExpanded: boolean;
  isLoading?: boolean;
  testId?: string;
}
const SideMenuItemCollapsible = ({
  menuIcon,
  text,
  onClick,
  isExpanded,
  isLoading = false,
  testId = undefined,
}: ISideMenuItemCollapsible) => {
  const theme = useTheme();

  if (isLoading) return <Skeleton variant="rounded" sx={{ height: 46, m: 1 }} />;

  return (
    <ListItemButton
      sx={{
        color: theme.palette.secondary.main,
        borderRadius: 2,
        ml: 1,
        mr: 1,
        pl: 1,
        pr: 1,
      }}
      onClick={onClick}
      {...(testId && { testId })}
    >
      <ListItemIcon sx={{ color: theme.palette.secondary.main }}>{menuIcon}</ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ variant: "subtitle2", fontWeight: "medium" }}
        primary={text}
      />
      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </ListItemButton>
  );
};

export default SideMenuItemCollapsible;
