import { useQuery } from "@tanstack/react-query";
import {
  ProductMapping,
  ProgramType,
  Source,
  Target,
} from "api/client.generated";
import useApi from "./useApi";

interface UseProductMappingsReturn {
  productMappings: ProductMapping[] | undefined;
  isProductMappingsFetching: boolean;
}

const useProductMappings = (
  source: Source,
  target: Target,
  program: ProgramType,
): UseProductMappingsReturn => {
  const { productsClient } = useApi();

  const loadData = (): Promise<ProductMapping[]> =>
    productsClient.getProductMappings(source, target, program);

  const { data: productMappings, isFetching: isProductMappingsFetching } =
    useQuery({
      queryKey: ["getProductMappings", source, target, program],
      queryFn: loadData,
    });

  return { productMappings, isProductMappingsFetching };
};

export default useProductMappings;
