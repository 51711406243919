/* eslint-disable max-classes-per-file */
import { Source, Target } from "api/client.generated";

const enum RoutePath {
  DASHBOARD = "/",
  SIGN_IN = "/signIn",
  SELECT_PARTNER = "/selectPartner",
  TERMS = "/terms",
  CONNECTIONS = "/connections",
  CONNECTION_EDIT = "/connections/:endpointName/edit",
  PROGRAMS = "/programs",
  PROGRAM_EDIT = "/programs/:source/:target/:program/edit",
  PROGRAM_PRODUCT_SYNC_PREVIEW = "/programs/:source/:target/:program/productSyncPreview",
  PRICERULES = "/price-rules",
  PRICERULE_ADD = "/price-rules/add",
  PRICERULE_EDIT = "/price-rules/edit/:ruleId",
  BILLING_SYNC = "/sync/:source/:target/:program/billing",
  BILLING_SYNC_ADD_MAPPING = "/sync/:source/:target/:program/billing/mappings/add",
  BILLING_SYNC_EDIT_MAPPING = "/sync/:source/:target/:program/billing/mappings/:id/edit",
  BILLING_SYNC_HISTORY = "/sync/:source/:target/:program/billing/history",
  BILLING_SYNC_HISTORY_DETAILS = "/sync/:source/:target/:program/billing/history/:id",
  PRODUCT_SYNC = "/sync/:source/:target/:program/product",
  PRODUCT_SYNC_EDIT_MAPPING = "/sync/:source/:target/:program/product/mappings/:id/edit",
  PRODUCT_SYNC_HISTORY = "/sync/:source/:target/:program/product/history",
  PRODUCT_SYNC_HISTORY_DETAILS = "/sync/:source/:target/:program/product/history/:id",
}

class BaseRoute {
  public route: RoutePath;

  constructor(route: RoutePath) {
    this.route = route;
  }
}

class PriceRuleEditRoute extends BaseRoute {
  public buildRoute = (ruleId: string): string => this.route.replace(":ruleId", ruleId);
}

class SourceTargetProgramRoute extends BaseRoute {
  public buildRoute = (source: string, target: string, program: string): string =>
    this.route.replace(":source", source).replace(":target", target).replace(":program", program);
}

class IdSourceTargetProgramRoute extends BaseRoute {
  public buildRoute = (source: string, target: string, program: string, id: string): string =>
    this.route
      .replace(":source", source)
      .replace(":target", target)
      .replace(":program", program)
      .replace(":id", id);
}

class EditConnectionRoute extends BaseRoute {
  public buildRoute = (endpointName: Source | Target): string =>
    this.route.replace(":endpointName", endpointName);
}

const AppRoutes = {
  dashboard: new BaseRoute(RoutePath.DASHBOARD),
  signIn: new BaseRoute(RoutePath.SIGN_IN),
  selectPartner: new BaseRoute(RoutePath.SELECT_PARTNER),
  terms: new BaseRoute(RoutePath.TERMS),
  connections: new BaseRoute(RoutePath.CONNECTIONS),
  connectionEdit: new EditConnectionRoute(RoutePath.CONNECTION_EDIT),
  programs: new BaseRoute(RoutePath.PROGRAMS),
  programEdit: new SourceTargetProgramRoute(RoutePath.PROGRAM_EDIT),
  programProductSyncPreview: new SourceTargetProgramRoute(RoutePath.PROGRAM_PRODUCT_SYNC_PREVIEW),
  priceRules: new BaseRoute(RoutePath.PRICERULES),
  priceRuleAdd: new BaseRoute(RoutePath.PRICERULE_ADD),
  priceRuleEdit: new PriceRuleEditRoute(RoutePath.PRICERULE_EDIT),
  billingSync: new SourceTargetProgramRoute(RoutePath.BILLING_SYNC),
  billingSyncAddMapping: new SourceTargetProgramRoute(RoutePath.BILLING_SYNC_ADD_MAPPING),
  billingSyncEditMapping: new IdSourceTargetProgramRoute(RoutePath.BILLING_SYNC_EDIT_MAPPING),
  billingSyncHistory: new SourceTargetProgramRoute(RoutePath.BILLING_SYNC_HISTORY),
  billingSyncHistoryDetails: new IdSourceTargetProgramRoute(RoutePath.BILLING_SYNC_HISTORY_DETAILS),
  productSync: new SourceTargetProgramRoute(RoutePath.PRODUCT_SYNC),
  productSyncEditMapping: new IdSourceTargetProgramRoute(RoutePath.PRODUCT_SYNC_EDIT_MAPPING),
  productSyncHistory: new SourceTargetProgramRoute(RoutePath.PRODUCT_SYNC_HISTORY),
  productSyncHistoryDetails: new IdSourceTargetProgramRoute(RoutePath.PRODUCT_SYNC_HISTORY_DETAILS),
};

export default AppRoutes;
