import { useQuery } from "@tanstack/react-query";
import { GetPrismConfigurationResponse } from "api/client.generated";
import useApi from "./useApi";

const usePrismConfiguration = (): {
  prismConfig: GetPrismConfigurationResponse | undefined;
  isPrismConfigFetching: boolean;
} => {
  const { prismConfigurationClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["getPrismConfiguration"],
    queryFn: () => prismConfigurationClient.getPrismConfiguration(),
  });

  return { prismConfig: data, isPrismConfigFetching: isFetching };
};

export default usePrismConfiguration;
