import { useQuery } from "@tanstack/react-query";
import { Source } from "api/client.generated";
import UserService from "api/userService";
import LoadingScreen from "components/primitives/LoadingScreen";
import useRouteDetector from "hooks/useRouteDetector";
import { createContext, useCallback, useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";

interface IUserContext {
  signIn: (source: Source) => void;
  signOut: () => void;
  userName: string;
  userEmail: string;
}

const UserContext = createContext<IUserContext | null>(null);

interface UserContextProviderProps {
  children: React.ReactElement;
}

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { data: userClaims, isFetching } = useQuery({
    queryKey: ["getUserClaims"],
    queryFn: () => UserService.getUserClaims(),
    initialData: null,
  });

  const { isSignInRoute } = useRouteDetector();

  const signIn = useCallback((source: Source) => {
    UserService.redirectToLogin(source);
  }, []);

  const signOut = useCallback(() => {
    UserService.logout(userClaims);
  }, [userClaims]);

  const userName = useMemo<string>(() => {
    const givenName = userClaims?.find((c) => c.type === "given_name")?.value ?? "";
    const familyName = userClaims?.find((c) => c.type === "family_name")?.value ?? "";
    return `${givenName} ${familyName}`;
  }, [userClaims]);

  const userEmail = useMemo<string>(() => {
    const email = userClaims?.find((c) => c.type === "name")?.value ?? "";
    return `${email}`;
  }, [userClaims]);

  const ctxValue = useMemo<IUserContext>(
    () => ({ signIn, signOut, userName, userEmail }),
    [signIn, signOut, userName, userEmail],
  );

  // any fancy loading screen can be here
  if (isFetching) return <LoadingScreen />;

  // redirect logic
  if (!isSignInRoute && !userClaims) {
    return <Navigate to={AppRoutes.signIn.route} />;
  }

  // prettier-ignore
  return (
    <UserContext.Provider value={ctxValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): IUserContext => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }

  return context;
};

export default UserContextProvider;
