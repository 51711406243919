import {
  Box,
  Chip,
  IconButton,
  LinkBehavior,
  Paper,
  SuccessChip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  WarningChip,
} from "@crayon/design-system-react";
import { PriceRuleStatus } from "api/client.generated";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodyDateCell from "components/primitives/TableBodyDateCell";
import TableBodySkeleton from "components/primitives/TableBodySkeleton";
import TableHeadSortable, { SortableColumn } from "components/primitives/TableHeadSortable";
import { EditIcon } from "images/MuiIcons";
import { useMemo, useState } from "react";
import AppRoutes from "routes/app-routes";
import DefaultPaginationOptions from "types/defaultPaginationOptions";
import { PriceRuleTableModel } from "types/price-rule-model";
import SortDirection from "types/sort-direction";

interface PriceRulesTableProps {
  priceRules: PriceRuleTableModel[];
  isLoading: boolean;
  onSortRequest: (newSortField: keyof PriceRuleTableModel, newSortDirection: SortDirection) => void;
}

const PriceRulesTable = ({ priceRules, isLoading, onSortRequest }: PriceRulesTableProps) => {
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = useMemo<number[]>(() => DefaultPaginationOptions, []);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const onPageChange = (_event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columnsOptions = useMemo<SortableColumn<PriceRuleTableModel>[]>(
    () => [
      { label: "Action", field: "action" },
      { label: "Rate", field: "rate", disableSort: true },
      { label: "Source", field: "source" },
      { label: "Program", field: "program" },
      { label: "Customer", field: "customer" },
      { label: "Product", field: "product" },
      { label: "Status", field: "status", align: "center" },
      { label: "Start Date", field: "startDate" },
      { label: "End Date", field: "endDate" },
      { label: "Modified Date", field: "lastModifiedDate", colSpan: 2 },
    ],
    [],
  );

  const getStatusChip = (status: PriceRuleStatus) => {
    switch (status) {
      case PriceRuleStatus.Active:
        return <SuccessChip label={status} />;
      case PriceRuleStatus.Future:
        return <WarningChip label={status} />;
      // Expired OR Unknown
      default:
        return <Chip label={status} />;
    }
  };

  const DataTableBody = (
    <TableBody>
      {priceRules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((x, i) => (
        <TableRow key={x.id + String(i)} data-price-rule-id={x.id}>
          <TableBodyCell label={x.action} />
          <TableBodyCell label={x.rate} />
          <TableBodyCell label={x.source} />
          <TableBodyCell label={x.program} />
          <TableBodyCell label={x.customer} />
          <TableBodyCell label={x.product} />
          <TableCell align="center">{getStatusChip(x.status)}</TableCell>
          <TableBodyDateCell date={x.startDate} />
          <TableBodyDateCell date={x.endDate} />
          <TableBodyDateCell date={x.lastModifiedDate} />
          <TableCell align="right">
            <IconButton
              component={LinkBehavior}
              href={AppRoutes.priceRuleEdit.buildRoute(x.id)}
              color="primary"
              disabled={x.status === PriceRuleStatus.Expired}
            >
              <EditIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHeadSortable
          columns={columnsOptions}
          onSortRequest={onSortRequest}
          initSortBy="lastModifiedDate"
          initSortDirection="desc"
          defaultSortDirection="desc"
        />
        {isLoading ? (
          <TableBodySkeleton colsCount={columnsOptions.length} rowsCount={3} />
        ) : (
          DataTableBody
        )}
      </Table>
      {!isLoading && (
        <TablePagination
          component={Box}
          count={priceRules.length}
          page={page}
          onPageChange={onPageChange}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          sx={{ my: 1 }}
        />
      )}
    </TableContainer>
  );
};

export default PriceRulesTable;
