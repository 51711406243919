import {
  Box,
  Chip,
  FormSelect,
  FormSelectOption,
  SxProps,
  Theme,
} from "@crayon/design-system-react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface ControllerFormSelectProps<TField extends FieldValues> {
  label: string;
  bindSchemaFieldName: Path<TField>;
  control: Control<TField>;
  options: FormSelectOption[];
  sxRoot?: SxProps<Theme>;
  disabled?: boolean;
  multiple?: boolean;
  isLoading?: boolean;
  testId?: string;
  required?: boolean;
}

const ControllerFormSelect = <TField extends FieldValues>({
  label,
  bindSchemaFieldName,
  options,
  control,
  sxRoot = null,
  disabled = false,
  multiple = false,
  isLoading = false,
  testId = undefined,
  required = undefined,
}: ControllerFormSelectProps<TField>) => {
  const renderSelectedValue = (value: string | string[]): React.ReactNode => {
    if (Array.isArray(value))
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {value.map((v) => (
            <Chip
              key={v}
              label={options.find((option) => option.value === v)?.label}
              size="small"
            />
          ))}
        </Box>
      );

    return options.find((x) => x.value === value)?.label;
  };

  return (
    <Controller
      control={control}
      name={bindSchemaFieldName}
      render={({ field, fieldState }) => (
        <FormSelect
          {...field}
          disabled={disabled}
          error={Boolean(fieldState.error)}
          id={label}
          label={label}
          loading={isLoading}
          multiple={multiple}
          options={options}
          message={fieldState.error?.message}
          renderValue={renderSelectedValue}
          sx={{ ...sxRoot }}
          {...(testId && { testId })}
          required={required}
        />
      )}
    />
  );
};

export default ControllerFormSelect;
