import { AxiosRequestConfig } from "axios";
import ClientConfig from "./clientConfig";

export default class ClientBase {
  private config: ClientConfig;

  constructor(config: ClientConfig) {
    this.config = config;
  }

  protected transformOptions = (options: AxiosRequestConfig) => {
    options.headers = {
      ...options.headers,
      "X-CSRF": "1",
      ...(Boolean(this.config.getPartnerId().length) && {
        PartnerId: this.config.getPartnerId(),
      }),
    };

    options.transformResponse = (data: any) => data;

    return Promise.resolve(options);
  };

  protected transformResult = (
    _url: string,
    response: Response,
    processor: (_response: Response) => any,
  ) => {
    return processor(response);
  };
}
