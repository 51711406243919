import { Collapse, Drawer, List, Toolbar, Typography, useTheme } from "@crayon/design-system-react";
import {
  ConfigurationIcon,
  ConnectionsIcon,
  DashboardIcon,
  PriceRulesIcon,
  ProgramsIcon,
} from "images/MuiIcons";

import { ProgramStatus } from "api/client.generated";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import { useSidebarContext } from "context/sideBarContext";
import useRouteDetector from "hooks/useRouteDetector";
import useScreen from "hooks/useScreen";
import useToggle from "hooks/useToggle";
import { useMemo } from "react";
import AppRoutes from "routes/app-routes";
import SideMenuItem from "./SideMenuItem";
import SideMenuItemCollapsible from "./SideMenuItemCollapsible";
import SideMenuItemSyncGroup from "./SideMenuItemSyncGroup";

const Sidebar = () => {
  const drawerWidth = 280;
  const [isConfigOpen, toggleConfigOpen] = useToggle(false);
  const theme = useTheme();
  const { isBigScreen } = useScreen();
  const { isSidebarOpen, toggleSidebarOpen } = useSidebarContext();

  const { partnerConfig, isPartnerConfigFetching } = usePartnerConfigContext();

  const { isSignInRoute, isSelectPartnerRoute, isTermsRoute } = useRouteDetector();
  const sidebarVariant = useMemo(() => {
    if (isSignInRoute || isSelectPartnerRoute || isTermsRoute) {
      return "temporary";
    }

    return isBigScreen ? "permanent" : "temporary";
  }, [isSignInRoute, isSelectPartnerRoute, isBigScreen, isTermsRoute]);

  const SideBarContent = (
    <>
      {/* To compensate the topbar height */}
      <Toolbar />
      <List component="nav">
        <SideMenuItem
          menuIcon={<DashboardIcon />}
          text="Dashboard"
          testId="Dashboard"
          to={AppRoutes.dashboard.route}
        />
        <SideMenuItemCollapsible
          menuIcon={<ConfigurationIcon />}
          text="Configuration"
          testId="Configuration"
          onClick={toggleConfigOpen}
          isExpanded={isConfigOpen}
          isLoading={isPartnerConfigFetching}
        />
        <Collapse in={isConfigOpen && !isPartnerConfigFetching}>
          <SideMenuItem
            text="Connections"
            testId="Connections"
            menuIcon={<ConnectionsIcon />}
            to={AppRoutes.connections.route}
            sx={{ pl: 3 }}
          />
          <SideMenuItem
            text="Programs"
            testId="Programs"
            menuIcon={<ProgramsIcon />}
            to={AppRoutes.programs.route}
            sx={{ pl: 3 }}
          />
          <SideMenuItem
            text="Price Rules"
            testId="Price Rules"
            menuIcon={<PriceRulesIcon />}
            to={AppRoutes.priceRules.route}
            sx={{ pl: 3 }}
          />
        </Collapse>
        {!isPartnerConfigFetching &&
          partnerConfig?.configuredPrograms
            ?.filter((p) => p.programs?.some((x) => x.status === ProgramStatus.Syncing))
            ?.map((p, i) => (
              <SideMenuItemSyncGroup
                key={String(p.source) + String(p.target) + String(i)}
                configuredPrograms={p}
              />
            ))}
      </List>
      <Typography
        variant="body2"
        fontSize="small"
        align="center"
        color={theme.palette.text.secondary}
        mt="auto"
        mb={1}
      >
        {process.env.REACT_APP_VERSION}
      </Typography>
    </>
  );

  return (
    <Drawer
      variant={sidebarVariant}
      open={isSidebarOpen}
      onClose={toggleSidebarOpen}
      PaperProps={{ elevation: 0 }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      {SideBarContent}
    </Drawer>
  );
};

export default Sidebar;
