import { AdapterLuxon, Box, LocalizationProvider, Toolbar } from "@crayon/design-system-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Sidebar from "components/layout/Sidebar/Sidebar";
import ThemeProviderWrapper from "components/layout/ThemeProviderWrapper";
import AppTopbar from "components/layout/Topbar/AppTopbar";
import ConfirmationDialogContextProvider from "context/confirmationDialogContext";
import NotificationContextProvider from "context/notificationContext";
import { PartnerConfigContextProvider } from "context/partnerConfigContext";
import { SelectedPartnerContextProvider } from "context/selectedPartnerContext";
import { SidebarContextProvider } from "context/sideBarContext";
import { TermsContextProvider } from "context/termsContext";
import UserContextProvider from "context/userContextProvider";
import { Outlet } from "react-router-dom";
import "utils/luxon-extensions";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    {/* Check if user is authenticated. If not - redirect to SignInPage */}
    <UserContextProvider>
      {/* Check if partner is selected. If not - redirect to SelectPartnerPage */}
      <SelectedPartnerContextProvider>
        {/* Check if terms accepted. If not - redirect to TermsPage */}
        <TermsContextProvider>
          {/* Query partner config. If 'Forbidden' is returned - redirect to SelectPartnerPage */}
          <PartnerConfigContextProvider>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-au">
              <ThemeProviderWrapper>
                {/* Wrapper for notifications + placeholder for them */}
                <NotificationContextProvider>
                  {/* Wrapper for confirmation dialog + placeholder for it */}
                  <ConfirmationDialogContextProvider>
                    <Box id="app-layout" display="flex">
                      {/* Topbar and Sidebar */}
                      <SidebarContextProvider>
                        <AppTopbar />
                        <Sidebar />
                      </SidebarContextProvider>
                      <Box id="page-layout" flex={1}>
                        {/* <Toolbar /> here is to compensate Topbar heigh */}
                        <Toolbar />
                        <Box id="page-content" p={3}>
                          <Outlet />
                        </Box>
                      </Box>
                    </Box>
                  </ConfirmationDialogContextProvider>
                </NotificationContextProvider>
              </ThemeProviderWrapper>
            </LocalizationProvider>
          </PartnerConfigContextProvider>
        </TermsContextProvider>
      </SelectedPartnerContextProvider>
    </UserContextProvider>
  </QueryClientProvider>
);

export default App;
