import { useQuery } from "@tanstack/react-query";
import { GetPartnerConnectionsResponse } from "api/client.generated";
import useApi from "./useApi";

const usePartnerConnections = (): {
  partnerConnections: GetPartnerConnectionsResponse | undefined;
  isPartnerConnectionsFetching: boolean;
} => {
  const { partnerConfigurationV2Client } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["partnerConnections"],
    queryFn: () => partnerConfigurationV2Client.getConnections(),
  });

  return { partnerConnections: data, isPartnerConnectionsFetching: isFetching };
};

export default usePartnerConnections;
