export default class ClientConfig {
  private partnerId: string;

  constructor(partnerId: string) {
    this.partnerId = partnerId;
  }

  getPartnerId(): string {
    return this.partnerId;
  }
}
