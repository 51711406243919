import { Box, Skeleton, Stack } from "@crayon/design-system-react";
import PriceRuleForm from "components/hoc/PriceRules/PriceRuleForm";
import SamplePricingForm from "components/hoc/PriceRules/SamplePricingForm";
import TextHeader from "components/primitives/TextHeader";
import { SamplePricingContextProvider } from "context/samplePricingContext";
import usePriceRule from "hooks/api/usePriceRule";

interface PriceRuleAddEditLayoutProps {
  ruleId?: string;
}

const PriceRuleAddEditLayout = ({ ruleId = undefined }: PriceRuleAddEditLayoutProps) => {
  const { priceRule, isPriceRuleFetching } = usePriceRule(ruleId);

  const loadingSkeleton = <Skeleton variant="rounded" width={430} height={627} />;

  return (
    <Stack spacing={2}>
      <TextHeader testId="page-header" label={priceRule ? "Edit Price Rule" : "Add Price Rule"} />

      <Box display="flex" gap={2} flexWrap="wrap">
        {/* prettier-ignore */}
        <SamplePricingContextProvider>
          {isPriceRuleFetching ? loadingSkeleton : <PriceRuleForm priceRule={priceRule} />}
          {isPriceRuleFetching ? loadingSkeleton : <SamplePricingForm />}
        </SamplePricingContextProvider>
      </Box>
    </Stack>
  );
};

export default PriceRuleAddEditLayout;
