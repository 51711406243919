import { Source, Target } from "api/client.generated";
import AutotaskLogo from "images/Logo/Autotask.svg";
import CloudIqLogo from "images/Logo/CloudIq.svg";
import ConnectWiseLogo from "images/Logo/ConnectWise.svg";
import PrismLogo from "images/Logo/PRISM.svg";
import SyncroLogo from "images/Logo/Syncro.svg";

export type EndpointName = Source | Target;

export class Endpoint {
  public static getLogo(endpointName: EndpointName): string {
    switch (endpointName) {
      case Source.Prism:
        return PrismLogo;
      case Source.CloudIq:
        return CloudIqLogo;
      case Target.Autotask:
        return AutotaskLogo;
      case Target.ConnectWise:
        return ConnectWiseLogo;
      case Target.Syncro:
        return SyncroLogo;
      default:
        throw new Error(`Can't get the logo for '${this.name}' value`);
    }
  }

  public static isSource(endpointName: EndpointName): boolean {
    switch (endpointName) {
      case Source.Prism:
      case Source.CloudIq:
        return true;
      default:
        return false;
    }
  }
}
