import { Box, Stack, Typography, useTheme } from "@crayon/design-system-react";
import { SubscriptionSyncResult } from "api/client.generated";
import SubscriptionsSyncDetailsTable from "components/hoc/Sync/Billing/Digest/SubscriptionsSyncDetailsTable";
import { ErrorCircleIcon } from "images/MuiIcons";

interface SubscriptionsSyncDetailsLayoutProps {
  subscriptionSyncResults: SubscriptionSyncResult[] | undefined;
  errorMessage: string | undefined;
}

const SubscriptionsSyncDetailsLayout = (
  props: SubscriptionsSyncDetailsLayoutProps,
) => {
  const { subscriptionSyncResults, errorMessage } = props;
  const theme = useTheme();

  const ErrorSlice = (
    <Box display="flex" alignItems="center" gap={1}>
      <ErrorCircleIcon sx={{ color: theme.palette.error.main }} />
      <Typography
        variant="body2"
        color={theme.palette.error.main}
        fontWeight="medium"
      >
        {errorMessage}
      </Typography>
    </Box>
  );

  return (
    <Stack p={1} spacing={1}>
      {errorMessage && ErrorSlice}

      {Boolean(subscriptionSyncResults?.length) && (
        <Typography variant="subtitle1">Subscriptions</Typography>
      )}

      {Boolean(subscriptionSyncResults?.length) && (
        <SubscriptionsSyncDetailsTable
          subscriptionSyncResults={subscriptionSyncResults}
        />
      )}
    </Stack>
  );
};

export default SubscriptionsSyncDetailsLayout;
