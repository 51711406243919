import { useQuery } from "@tanstack/react-query";
import { GetCloudIqConfigurationResponse } from "api/client.generated";
import useApi from "./useApi";

const useCloudIqConfiguration = (): {
  cloudIqConfig: GetCloudIqConfigurationResponse | undefined;
  isCloudIqConfigFetching: boolean;
} => {
  const { cloudIqConfigurationClient } = useApi();

  const { data, isFetching } = useQuery({
    queryKey: ["getCloudIqConfiguration"],
    queryFn: () => cloudIqConfigurationClient.getCloudIqConfiguration(),
  });

  return { cloudIqConfig: data, isCloudIqConfigFetching: isFetching };
};

export default useCloudIqConfiguration;
