import { Box, Paper, PrimaryButton } from "@crayon/design-system-react";
import { Source } from "api/client.generated";
import TextHeader from "components/primitives/TextHeader";
import { useUserContext } from "context/userContextProvider";
import { Endpoint } from "types/Endpoint";

interface SingInOptionBoxProps {
  source: Source;
}

const SingInOptionBox = ({ source }: SingInOptionBoxProps) => {
  const { signIn } = useUserContext();
  return (
    <Box
      component={Paper}
      width={300}
      height={250}
      px={4}
      py={4}
      display="flex"
      flexDirection="column"
    >
      <Box component="img" src={Endpoint.getLogo(source)} mb="auto" />
      <PrimaryButton testId={source} onClick={() => signIn(source)}>
        {source === Source.CloudIq && "Sign in with Cloud-iQ"}
        {source === Source.Prism && "Sign in with PRISM"}
      </PrimaryButton>
    </Box>
  );
};

const SignInPage = () => (
  <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
    <TextHeader testId="page-header" label="Sign in to Billing Sync" />
    <Box display="flex" flexWrap="wrap" gap={4} justifyContent="center">
      <SingInOptionBox source={Source.CloudIq} />
      <SingInOptionBox source={Source.Prism} />
    </Box>
  </Box>
);

export default SignInPage;
