import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  LinkBehavior,
  Stack,
  Typography,
  useTheme,
} from "@crayon/design-system-react";
import { ProgramType, Source, Target } from "api/client.generated";
import CardHeader from "components/primitives/CardHeader";
import useSyncHistory from "hooks/api/useSyncHistory";
import useSyncTypeGuard from "hooks/useSyncTypeGuard";
import { Inventory2OutlinedIcon, ReceiptOutlinedIcon } from "images/MuiIcons";
import { DateTime } from "luxon";
import { useMemo } from "react";
import AppRoutes from "routes/app-routes";
import { Endpoint } from "types/Endpoint";
import SyncType from "types/sync-type";
import pluralize from "utils/pluralize";
import SyncStatistics from "./Sync/SyncStatistics";

interface DashboardSyncCardProps {
  source: Source;
  target: Target;
  program: ProgramType;
  syncType: SyncType;
}

const DashboardSyncCard = (props: DashboardSyncCardProps) => {
  const { source, target, program, syncType } = props;
  const theme = useTheme();
  const paperColor = theme.palette.background.paper;

  useSyncTypeGuard(syncType);

  const { syncHistory, isSyncHistoryFetching } = useSyncHistory(
    source,
    target,
    program,
    syncType,
    0,
    1,
  );

  const viewDigestRoute = useMemo<string>(() => {
    if (!syncHistory?.at(0)?.digestId.length) return "";

    return syncType === "Billing"
      ? AppRoutes.billingSyncHistoryDetails.buildRoute(
          source,
          target,
          program,
          syncHistory!.at(0)!.digestId,
        )
      : AppRoutes.productSyncHistoryDetails.buildRoute(
          source,
          target,
          program,
          syncHistory!.at(0)!.digestId,
        );
  }, [syncHistory, syncType, source, target, program]);

  const lastSyncStats = syncHistory?.at(0);

  const getLastSyncTimeDisplay = (): string => {
    if (!lastSyncStats || !lastSyncStats.when) return "";

    const now = DateTime.now();

    // The the smallest measuring unit is returned as decimal so put 'seconds' to get
    // integers for weeks, days, hours and minutes.
    const diffObject = now
      .diff(lastSyncStats.when, ["weeks", "days", "hours", "minutes", "seconds"])
      .toObject();

    if (diffObject.weeks !== 0) return `${pluralize(diffObject.weeks!, "week")} ago`;
    if (diffObject.days !== 0) return `${pluralize(diffObject.days!, "day")} ago`;
    if (diffObject.hours !== 0) return `${pluralize(diffObject.hours!, "hour")} ago`;
    return diffObject.minutes! > 0 ? `${pluralize(diffObject.minutes!, "minute")} ago` : "Just now";
  };

  const HeaderSlice = (
    <CardHeader showStatus statusSuccess={!lastSyncStats?.errorCount}>
      {syncType === "Billing" ? (
        <ReceiptOutlinedIcon sx={{ color: paperColor, mr: 1 }} />
      ) : (
        <Inventory2OutlinedIcon sx={{ color: paperColor, mr: 1 }} />
      )}
      <Typography variant="h5" color={paperColor}>
        {syncType}
      </Typography>
      <Typography variant="body1" color={paperColor} sx={{ ml: "auto" }}>
        {getLastSyncTimeDisplay()}
      </Typography>
    </CardHeader>
  );

  const SourceTargetProgramSlice = (
    <Stack spacing={1} alignItems="center" sx={{ px: 6, py: 2 }}>
      <Box component="img" src={Endpoint.getLogo(source)} maxHeight="55px" />
      <Box component="img" src={Endpoint.getLogo(target)} />
      <Typography variant="h5">{program}</Typography>
    </Stack>
  );

  const StatsSlice = (
    <CardContent sx={{ py: 2, px: 4 }}>
      <SyncStatistics
        errorCount={lastSyncStats?.errorCount ?? 0}
        warningCount={lastSyncStats?.warningCount ?? 0}
        successCount={lastSyncStats?.syncCount ?? 0}
        isLoading={isSyncHistoryFetching}
        syncType={syncType}
        secondarySuccessCount={lastSyncStats?.secondarySyncCount}
      />
    </CardContent>
  );

  // This is to prevent displaying sync cards with empty sync stats
  // Can't do it from Dashboard because it involves separate API request.
  if (!isSyncHistoryFetching && !syncHistory?.length) return null;

  return (
    <Card
      sx={{
        width: "275px",
        height: "475px",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={2}
    >
      {HeaderSlice}
      {SourceTargetProgramSlice}
      <Divider />
      {StatsSlice}
      <Box sx={{ mt: "auto", mr: 4, mb: 2, ml: "auto" }}>
        <Button component={LinkBehavior} href={viewDigestRoute}>
          View
        </Button>
      </Box>
    </Card>
  );
};

export default DashboardSyncCard;
