import { TableCell, Typography } from "@crayon/design-system-react";

interface TableHeaderCellProps {
  columnName: string;
  colSpan?: number;
}
const TableHeaderCell = ({ columnName, colSpan = 1 }: TableHeaderCellProps) => (
  <TableCell colSpan={colSpan} size="small">
    <Typography variant="subtitle2">{columnName}</Typography>
  </TableCell>
);

export default TableHeaderCell;
