import LoadingScreen from "components/primitives/LoadingScreen";
import useTerms from "hooks/api/useTerms";
import useRouteDetector from "hooks/useRouteDetector";
import { createContext, useContext, useMemo } from "react";
import { Navigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";

interface ITermsContext {
  latestVersion: number;
  isAccepted: boolean;
  invalidateTermsQuery: () => void;
}

const TermsContext = createContext<ITermsContext | null>(null);

interface TermsVerifierProps {
  children: React.ReactNode;
}

export const TermsContextProvider = ({ children }: TermsVerifierProps) => {
  const { isTermsRoute, isSelectPartnerRoute, isSignInRoute } = useRouteDetector();

  const { terms, isTermsFetching, invalidateTermsQuery } = useTerms(
    !isSignInRoute && !isSelectPartnerRoute,
  );

  const ctx = useMemo<ITermsContext | null>(() => {
    if (!terms) return null;

    return {
      isAccepted: terms.partnerAlreadyAccepted,
      latestVersion: terms.version,
      invalidateTermsQuery,
    };
  }, [terms, invalidateTermsQuery]);

  // loading can be placed here
  if (isTermsFetching) return <LoadingScreen />;

  const shouldRedirectToTermsPage =
    !isSignInRoute && !isSelectPartnerRoute && !isTermsRoute && !terms?.partnerAlreadyAccepted;

  return (
    <TermsContext.Provider value={ctx}>
      {shouldRedirectToTermsPage ? <Navigate to={AppRoutes.terms.route} /> : children}
    </TermsContext.Provider>
  );
};

export const useTermsContext = (): ITermsContext => {
  const context = useContext(TermsContext);
  if (!context) throw new Error("useTermsContext must be used within a TermsContextProvider");

  return context;
};
