import SnackbarToastContainer from "components/hoc/Notifications/SnackbarToastContainer";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type Severity = "info" | "success" | "warning" | "error";

export interface NotificationMessage {
  id: number;
  label: string;
  severity: Severity;
}
interface INotificationContext {
  raiseInfoNotification: (msg: string) => void;
  raiseSuccessNotification: (msg: string) => void;
  raiseWarningNotification: (msg: string) => void;
  raiseErrorNotification: (msg: string) => void;
  removeNotification: (id: number) => void;
  notifications: NotificationMessage[];
}

const NotificationContext = createContext<INotificationContext | null>(null);

let ID_COUNTER = 0;

interface NotificationContextProviderProps {
  children: React.ReactNode;
}

const NotificationContextProvider = ({
  children,
}: NotificationContextProviderProps) => {
  const [messages, setMessages] = useState<NotificationMessage[]>([]);

  const removeNotification = useCallback((messageId: number) => {
    setMessages((prev) => prev.filter((m) => m.id !== messageId));
  }, []);

  const raiseNotification = useCallback((msg: string, severity: Severity) => {
    const id = ID_COUNTER;
    ID_COUNTER += 1;
    setMessages((prev) => [{ id, label: msg, severity }, ...prev]);
  }, []);

  const raiseInfoNotification = useCallback(
    (msg: string) => raiseNotification(msg, "info"),
    [raiseNotification],
  );
  const raiseSuccessNotification = useCallback(
    (msg: string) => raiseNotification(msg, "success"),
    [raiseNotification],
  );
  const raiseWarningNotification = useCallback(
    (msg: string) => raiseNotification(msg, "warning"),
    [raiseNotification],
  );
  const raiseErrorNotification = useCallback(
    (msg: string) => raiseNotification(msg, "error"),
    [raiseNotification],
  );

  const ctxValue = useMemo<INotificationContext>(
    () => ({
      raiseInfoNotification,
      raiseSuccessNotification,
      raiseWarningNotification,
      raiseErrorNotification,
      removeNotification,
      notifications: messages,
    }),
    [
      raiseInfoNotification,
      raiseSuccessNotification,
      raiseWarningNotification,
      raiseErrorNotification,
      removeNotification,
      messages,
    ],
  );

  return (
    <NotificationContext.Provider value={ctxValue}>
      {children}
      <SnackbarToastContainer />
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = (): INotificationContext => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationContextProvider",
    );
  }

  return context;
};

export default NotificationContextProvider;
