import UserClaim from "./userClaim";

export default class User {
  public firstName: string | null;

  public surname: string | null;

  public username: string | null;

  constructor(session: UserClaim[] | null) {
    if (session === null) {
      throw new Error("Session cannot be null.");
    }

    this.firstName = session.find((claim) => claim.type === "given_name")
      ?.value as string;
    this.surname = session.find((claim) => claim.type === "family_name")
      ?.value as string;
    this.username = session.find((claim) => claim.type === "preferred_username")
      ?.value as string;
  }
}
