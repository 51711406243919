import { IPriceRuleAllowedAction, PriceRule, PriceRuleStatus } from "api/client.generated";

export enum PriceRuleModelStringConstants {
  ALL_PROGRAMS = "All Programs",
  ALL_PRODUCTS = "All Products",
  ALL_SOURCES = "All Sources",
  ALL_CUSTOMERS = "All Customers",
}

export interface PriceRuleTableModel {
  id: string;
  action: string;
  rate: string;
  source: string;
  program: string;
  customer: string;
  product: string;
  status: PriceRuleStatus;
  startDate: Date;
  endDate: Date | undefined;
  lastModifiedDate: Date | undefined;
}

export class PriceRuleModel {
  id: string;

  program: string;

  productName: string;

  productSku: string;

  source: string;

  sourceCustomerId: string;

  sourceCustomerName: string;

  rate: number | undefined;

  priceRuleAllowedAction: IPriceRuleAllowedAction;

  startDateUtc: Date;

  endDateUtc: Date | undefined;

  status: PriceRuleStatus;

  private priceRule: PriceRule;

  constructor(rule: PriceRule) {
    this.priceRule = rule;

    this.id = rule.id;

    this.program = rule?.program ?? PriceRuleModelStringConstants.ALL_PROGRAMS;

    this.source = rule.source ?? PriceRuleModelStringConstants.ALL_SOURCES;
    this.productName = rule.productName ?? PriceRuleModelStringConstants.ALL_PRODUCTS;
    this.productSku = rule.productSku ?? PriceRuleModelStringConstants.ALL_PRODUCTS;

    this.sourceCustomerId = rule.sourceCustomerId
      ? rule.sourceCustomerId
      : PriceRuleModelStringConstants.ALL_CUSTOMERS;

    this.sourceCustomerName =
      rule.sourceCustomerName ?? PriceRuleModelStringConstants.ALL_CUSTOMERS;
    this.rate = rule.rate === null ? undefined : rule.rate;
    this.startDateUtc = new Date(rule.startDateUtc);
    this.endDateUtc = rule.endDateUtc ? new Date(rule.endDateUtc) : undefined;
    this.status = rule.status;

    this.priceRuleAllowedAction = rule.priceRuleAllowedAction ?? ({} as IPriceRuleAllowedAction);
  }

  private getRateForDisplay(valueOnly: boolean): string {
    if (!this.rate) return `-`;

    return this.priceRuleAllowedAction.isPercentage
      ? `${(this.rate * 100).toFixed(2)}${valueOnly ? "" : " %"}`
      : `${valueOnly ? "" : "$ "}${this.rate.toFixed(2)}`;
  }

  getRateForDisplayValueOnly(): string {
    return this.getRateForDisplay(true);
  }

  getRateForDisplayFull(): string {
    return this.getRateForDisplay(false);
  }

  getApiPriceRule(): PriceRule {
    return this.priceRule;
  }

  toTableModel(): PriceRuleTableModel {
    return {
      id: this.id,
      action: this.priceRuleAllowedAction?.display,
      rate: this.getRateForDisplayFull(),
      source: this.source,
      program: this.program,
      customer: this.sourceCustomerName,
      product: this.productName,
      status: this.status,
      startDate: new Date(this.startDateUtc),
      endDate: this.endDateUtc ? new Date(this.endDateUtc) : undefined,
      lastModifiedDate: this.priceRule.lastModifiedDateUtc
        ? new Date(this.priceRule.lastModifiedDateUtc)
        : undefined,
    };
  }
}
