import {
  Box,
  Button,
  IconButton,
  InfoChip,
  LinkBehavior,
  Paper,
  SuccessChip,
  Typography,
  WarningChip,
  useTheme,
} from "@crayon/design-system-react";
import FlexSpacer from "components/primitives/FlexSpacer";
import useScreen from "hooks/useScreen";
import { EditIcon } from "images/MuiIcons";

interface IProgramCard {
  name: string;
  isConfigured: boolean;
  isActive: boolean;
  editUrl: string;
}

const ProgramCard = (props: IProgramCard) => {
  const { name, isActive, isConfigured, editUrl } = props;
  const { smMatch } = useScreen();
  const theme = useTheme();

  return (
    <Paper
      component={Box}
      display="flex"
      alignItems="center"
      height="80px"
      sx={{ py: 2, minWidth: smMatch ? "550px" : "360px" }}
    >
      <FlexSpacer />
      <Typography variant="h5" color={theme.palette.text.primary} width="250px">
        {name}
      </Typography>
      <Box sx={{ ml: "auto" }}>
        {isConfigured ? <SuccessChip label="Configured" /> : <InfoChip label="Not Configured" />}
      </Box>
      <Box sx={{ ml: "auto" }}>
        {isActive ? (
          <SuccessChip label="Active" sx={{ ml: "auto" }} />
        ) : (
          <WarningChip label="Inactive" />
        )}
      </Box>
      {smMatch ? (
        <>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={{ ml: "auto" }}
            component={LinkBehavior}
            href={editUrl}
          >
            Edit
          </Button>
          <FlexSpacer />
        </>
      ) : (
        <IconButton color="primary" component={LinkBehavior} href={editUrl}>
          <EditIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default ProgramCard;
