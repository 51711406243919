import { Box, Divider, Paper, Skeleton, Stack, Typography } from "@crayon/design-system-react";
import CustomersSyncDetailsTable from "components/hoc/Sync/Billing/Digest/CustomersSyncDetailsTable";
import SyncStatistics from "components/hoc/Sync/SyncStatistics";
import TextHeader from "components/primitives/TextHeader";
import useBillingDigest from "hooks/api/useBillingDigest";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import SyncRouteParams from "types/sync-route-params";

interface BillingSyncHistoryDetailsRouteParams extends SyncRouteParams {
  id: string;
}

const BillingSyncHistoryDetailsPage = () => {
  const { source, target, program, id } = useParams<
    keyof BillingSyncHistoryDetailsRouteParams
  >() as BillingSyncHistoryDetailsRouteParams;

  const { customerDigest, isCustomerDigestFetching } = useBillingDigest(id);

  const StatsHeaderSlice = (
    <Box p={4} display="flex" justifyContent="space-between">
      <SyncStatistics
        successCount={customerDigest?.stats?.syncCount ?? 0}
        errorCount={customerDigest?.stats?.errorCount ?? 0}
        secondarySuccessCount={customerDigest?.stats?.secondarySyncCount ?? 0}
        warningCount={customerDigest?.stats?.warningCount ?? 0}
        isLoading={isCustomerDigestFetching}
        syncType="Billing"
        invoices={customerDigest?.stats?.invoices}
      />
      <Box>
        {isCustomerDigestFetching ? (
          <Skeleton width={180} />
        ) : (
          // prettier-ignore
          <Typography testId="Sync Date Time" variant="body1">
              {customerDigest?.stats?.when
                ? DateTime.fromJSDate(new Date(customerDigest!.stats!.when)).extToDateTimeString()
                : "Unknown"}
            </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Stack spacing={2}>
      <TextHeader
        testId="page-header"
        label={`${program} Billing Sync Details | ${source} > ${target}`}
      />
      <Box component={Paper}>
        {StatsHeaderSlice}
        <Divider />
        <CustomersSyncDetailsTable
          customerDigest={customerDigest}
          isCustomerDigestFetching={isCustomerDigestFetching}
          target={target}
        />
      </Box>
    </Stack>
  );
};

export default BillingSyncHistoryDetailsPage;
