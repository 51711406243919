import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Skeleton,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from "@crayon/design-system-react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

/*
 * The 'label' prop is taken as displayed text by default.
 * See: https://mui.com/material-ui/react-autocomplete/#options-structure
 * To use another prop as display text use 'getOptionLabel' prop of <Autocomplete>
 *
 * <========= !!! READ THE TEXT BELOW - save your time !!! =========>
 *
 * If initially there is no selected value, you need to use 'null' instead of 'undefined' value.
 * Because if 'value=undefined' on the 1st render, a component (<Autocomplete>) is treated
 * as 'uncontrollable' by MUI and causes a "switch from uncontrolled to controlled state" error
 * after selecting a value
 */
export interface FormAutocompleteOption {
  id: string;
  label: string;
}

interface FormAutocompleteProps<TField extends FieldValues> {
  bindSchemaFieldName: Path<TField>;
  clearable?: boolean;
  control: Control<TField>;
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  options: FormAutocompleteOption[];
  required?: boolean;
  sxRoot?: SxProps<Theme>;
  testId?: string;
}

const FormAutocomplete = <TField extends FieldValues>({
  bindSchemaFieldName,
  clearable = false,
  control,
  disabled = false,
  isLoading = false,
  label,
  options,
  required = false,
  sxRoot = undefined,
  testId = undefined,
}: FormAutocompleteProps<TField>) => {
  const theme = useTheme();

  return (
    <Controller
      name={bindSchemaFieldName}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          error={Boolean(fieldState.error)}
          sx={{ ...sxRoot }}
          {...(testId && { testId })}
        >
          {isLoading ? (
            <Skeleton variant="rounded" height={56} />
          ) : (
            <Autocomplete
              value={field.value}
              onChange={(_, value) => field.onChange(value)}
              isOptionEqualToValue={(
                option: FormAutocompleteOption,
                value: FormAutocompleteOption,
              ) => option.id === value.id}
              disableClearable={!clearable}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  error={Boolean(fieldState.error)}
                  ref={field.ref}
                  required={required}
                />
              )}
              sx={{
                ".MuiAutocomplete-input": {
                  color: theme.palette.secondary.main,
                },
              }}
              disabled={disabled}
              {...(testId && { testId })}
            />
          )}
          <FormHelperText {...(testId && { testId: `${testId}-helper-text` })}>
            {fieldState.error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormAutocomplete;
