import {
  CancelButton,
  PrimaryButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@crayon/design-system-react";

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { open, title, content, onConfirm, onCancel } = props;

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ margin: 1 }}>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <PrimaryButton onClick={onConfirm}>Confirm</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
