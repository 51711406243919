import { useQuery } from "@tanstack/react-query";
import { PriceRuleModel } from "types/price-rule-model";
import useApi from "./useApi";

interface UsePriceRuleReturn {
  priceRule: PriceRuleModel | undefined;
  isPriceRuleFetching: boolean;
}

const usePriceRule = (ruleId: string | undefined): UsePriceRuleReturn => {
  const { priceRulesClient } = useApi();

  const loadData = async (): Promise<PriceRuleModel | undefined> => {
    if (!ruleId) throw new Error("'enabled' key should prevent this case");

    const response = await priceRulesClient.getPriceRule(ruleId);
    return new PriceRuleModel(response);
  };

  const { data: priceRule, isFetching: isPriceRuleFetching } = useQuery({
    queryKey: ["getPriceRule", ruleId],
    queryFn: loadData,
    enabled: !!ruleId,
  });

  return { priceRule, isPriceRuleFetching };
};

export default usePriceRule;
