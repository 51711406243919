import {
  Box,
  Chip,
  IconButton,
  LinkBehavior,
  ListItemIcon,
  Menu,
  MenuItem,
  NormalButton,
  Paper,
  PrimaryButton,
  SuccessChip,
  WarningChip,
} from "@crayon/design-system-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ConnectionState, Source, Target } from "api/client.generated";
import FlexSpacer from "components/primitives/FlexSpacer";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useScreen from "hooks/useScreen";
import { BookmarkIcon, EditIcon } from "images/MuiIcons";
import { BaseSyntheticEvent, useMemo, useState } from "react";
import AppRoutes from "routes/app-routes";
import { Endpoint, EndpointName } from "types/Endpoint";
import isValidHttpUrl from "utils/url-validators";

interface IConnectionCard {
  endpointName: EndpointName;
  status: ConnectionState | undefined;
}

const ConnectionCard = ({ endpointName, status }: IConnectionCard) => {
  const { smMatch } = useScreen();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { partnerConfig } = usePartnerConfigContext();

  const lineBreak = "%0D%0A";
  const requestEmailSubject: string = "Onboarding request";
  const requestEmailBody: string =
    `Hi Support,${lineBreak}${lineBreak}` +
    `Requesting to be onboarded to Billing Sync.${lineBreak}${lineBreak}` +
    `Partner Name: ${partnerConfig?.partnerName}${lineBreak}` +
    `Partner Id: ${partnerConfig?.partnerId}${lineBreak}` +
    `Requested Source: ${Endpoint.isSource(endpointName) ? endpointName : ""}${lineBreak}` +
    `Requested PSA: ${Endpoint.isSource(endpointName) ? "" : endpointName}${lineBreak}${lineBreak}` +
    `Thanks`;
  const requestEmailHref = `mailto:${process.env.REACT_APP_CRAYON_SUPPORT_EMAIL}?subject=${requestEmailSubject}&body=${requestEmailBody}`;

  const kbDocUrl = useMemo<string | undefined>(() => {
    let url: string | undefined;

    switch (endpointName) {
      case Source.Prism:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_PRISM_URL;
        break;
      case Source.CloudIq:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_CLOUDIQ_URL;
        break;
      case Target.Autotask:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_AUTOTASK_URL;
        break;
      case Target.ConnectWise:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_CONNECTWISE_URL;
        break;
      case Target.Syncro:
        url = process.env.REACT_APP_KNOWLEDGE_BASE_SYNCRO_URL;
        break;
      default:
        url = undefined;
    }

    return url === undefined || !isValidHttpUrl(url) ? undefined : url;
  }, [endpointName]);

  return (
    <Paper
      component={Box}
      display="flex"
      alignItems="center"
      height="80px"
      sx={{ py: 2, minWidth: smMatch ? "550px" : "360px" }}
    >
      <FlexSpacer />
      {/* Logo */}
      <Box component="img" src={Endpoint.getLogo(endpointName)} maxWidth="140px" />
      {/* Chip */}
      <Box sx={{ ml: "auto", mr: "auto", pl: 1 }}>
        {status === ConnectionState.Connected && <SuccessChip label="Connected" />}
        {status === ConnectionState.NotConnected && <WarningChip label="No Access" />}
        {status === ConnectionState.RequestAccess && <Chip label="Not Available" />}
      </Box>
      {smMatch ? (
        <>
          {/* Docs */}
          {kbDocUrl ? (
            <Box pl={1}>
              <NormalButton startIcon={<BookmarkIcon />} onClick={() => window.open(kbDocUrl)}>
                Documentation
              </NormalButton>
            </Box>
          ) : (
            <FlexSpacer themeSpacing={23} />
          )}

          <FlexSpacer />

          {/* Edit */}
          {status === ConnectionState.RequestAccess && (
            <PrimaryButton component={LinkBehavior} href={requestEmailHref} variant="contained">
              Request
            </PrimaryButton>
          )}
          {status !== ConnectionState.RequestAccess && (
            <PrimaryButton
              component={LinkBehavior}
              href={AppRoutes.connectionEdit.buildRoute(endpointName)}
              startIcon={<EditIcon />}
            >
              Edit
            </PrimaryButton>
          )}
          <FlexSpacer />
        </>
      ) : (
        <>
          <FlexSpacer />
          <IconButton
            onClick={(e: BaseSyntheticEvent) => setAnchorEl(e.currentTarget)}
            sx={{ mr: 1 }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClick={() => setAnchorEl(null)}
            onClose={() => setAnchorEl(null)}
          >
            {kbDocUrl && (
              <MenuItem onClick={() => window.open(kbDocUrl)}>
                <ListItemIcon>
                  <BookmarkIcon color="secondary" />
                </ListItemIcon>
                Documentation
              </MenuItem>
            )}
            {status === ConnectionState.RequestAccess && (
              <MenuItem component={LinkBehavior} href={requestEmailHref}>
                <ListItemIcon>
                  <EditIcon color="primary" />
                </ListItemIcon>
                Request Access
              </MenuItem>
            )}
            {status !== ConnectionState.RequestAccess && (
              <MenuItem
                component={LinkBehavior}
                href={AppRoutes.connectionEdit.buildRoute(endpointName)}
              >
                <ListItemIcon>
                  <EditIcon color="primary" />
                </ListItemIcon>
                Edit
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </Paper>
  );
};

export default ConnectionCard;
