import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@crayon/design-system-react";
import { CustomerSyncResult, GetCustomerSyncResultsResponse, Target } from "api/client.generated";
import SubscriptionsSyncDetailsLayout from "components/layout/Sync/SubscriptionsSyncDetailsLayout";
import TableBodyCell from "components/primitives/TableBodyCell";
import TableBodySyncOutcomeCell from "components/primitives/TableBodySyncOutcomeCell";
import TableHeaderCell from "components/primitives/TableHeaderCell";
import TableRowCollapsible from "components/primitives/TableRowCollapsible";
import { useMemo } from "react";
import {
  getTargetAgreementLabel,
  getTargetCustomerLabel,
} from "utils/target-customer-agreement-labels";

interface CustomersSyncDetailsTableProps {
  customerDigest: GetCustomerSyncResultsResponse | undefined;
  isCustomerDigestFetching: boolean;
  target: Target;
}

const CustomersSyncDetailsTable = (props: CustomersSyncDetailsTableProps) => {
  const { customerDigest, isCustomerDigestFetching, target } = props;

  const columnOptions = useMemo<string[]>(
    () => [
      "Source Customer",
      "Source Agreement",
      getTargetCustomerLabel(target),
      getTargetAgreementLabel(target),
      "Invoice",
      "Status",
    ],
    [target],
  );

  const SkeletonTableBody = (
    <TableBody>
      {Array.from(Array(3).keys()).map((row) => (
        <TableRow key={row}>
          {columnOptions.map((col) => (
            <TableCell key={col}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );

  const renderCell = (label: string | undefined) =>
    label ? <TableBodyCell label={label} /> : <TableBodyCell label="Missing" fontItalic />;

  const collapsibleContent = (digest: CustomerSyncResult) => {
    if (!digest.errorMessage && !digest.subscriptionSyncResults?.length) return undefined;

    return (
      <SubscriptionsSyncDetailsLayout
        subscriptionSyncResults={digest.subscriptionSyncResults}
        errorMessage={digest.errorMessage}
      />
    );
  };

  const DataTableBody = (
    <TableBody>
      {customerDigest?.digest?.results?.map((digest) => (
        <TableRowCollapsible
          key={digest.customerMappingId}
          columnCount={columnOptions.length}
          oneRow={false}
          collapsibleContent={collapsibleContent(digest)}
        >
          {renderCell(digest.sourceCustomer?.name)}
          {renderCell(digest.sourceCustomer?.agreement?.name)}
          {renderCell(digest.targetCustomer?.name)}
          {renderCell(digest.targetCustomer?.agreements?.at(0)?.name)}
          <TableBodyCell label={digest.sourceCustomer?.invoiceNumber ?? ""} />
          <TableBodySyncOutcomeCell syncOutcome={digest.outcome} />
        </TableRowCollapsible>
      ))}
    </TableBody>
  );

  return (
    <TableContainer>
      <Table testId="Customer Sync Details">
        <TableHead>
          <TableRow>
            {columnOptions.map((c, i) => (
              <TableHeaderCell key={c + String(i)} columnName={c} />
            ))}
          </TableRow>
        </TableHead>
        {isCustomerDigestFetching ? SkeletonTableBody : DataTableBody}
      </Table>
    </TableContainer>
  );
};

export default CustomersSyncDetailsTable;
