import { Stack, Typography } from "@crayon/design-system-react";
import { SubscriptionUsageRecordSyncResult } from "api/client.generated";
import UsageSyncDetailsTable from "components/hoc/Sync/Billing/Digest/UsageSyncDetailsTable";

interface UsageSyncDetailsLayoutProps {
  usageSyncResults: SubscriptionUsageRecordSyncResult[] | undefined;
}

const UsageSyncDetailsLayout = (props: UsageSyncDetailsLayoutProps) => {
  const { usageSyncResults } = props;
  return (
    <Stack p={1} spacing={1}>
      <Typography variant="subtitle1">Usage</Typography>
      <UsageSyncDetailsTable usageSyncResults={usageSyncResults} />
    </Stack>
  );
};

export default UsageSyncDetailsLayout;
