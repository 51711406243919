import { Alert, Grow } from "@crayon/design-system-react";
import { NotificationMessage, useNotificationContext } from "context/notificationContext";
import { CheckCircleIcon } from "images/MuiIcons";
import { useCallback, useEffect, useState } from "react";

interface SnackbarToastProps {
  message: NotificationMessage;
}

const SnackbarToast = ({ message }: SnackbarToastProps) => {
  const TRANSITION_DURATION = 300;
  const AUTOCLOSE_TIMEOUT = 5000;

  const [isOpen, setIsOpen] = useState(true);
  const { removeNotification } = useNotificationContext();

  const closeToast = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => removeNotification(message.id), TRANSITION_DURATION);
  }, [message.id, removeNotification]);

  useEffect(() => {
    const timeout = setTimeout(() => closeToast(), AUTOCLOSE_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [closeToast]);

  const onCloseClick = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    closeToast();
  };

  return (
    <Grow in={isOpen} timeout={TRANSITION_DURATION}>
      <Alert
        variant="filled"
        severity={message.severity}
        onClose={onCloseClick}
        {...(message.severity === "success" && { icon: <CheckCircleIcon /> })}
      >
        {message.label}
      </Alert>
    </Grow>
  );
};

SnackbarToast.displayName = "SnackbarToast";

export default SnackbarToast;
