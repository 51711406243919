import { Box, Paper, Stack } from "@crayon/design-system-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { SaveAutotaskConfigurationRequestModel } from "api/client.generated";
import { ValidationException } from "api/client.generated.extensions";
import FormActionButtons from "components/primitives/FormActionButtons";
import FormErrorMessage from "components/primitives/FormErrorMessage";
import FormTextField from "components/primitives/FormTextField";
import { useNotificationContext } from "context/notificationContext";
import { usePartnerConfigContext } from "context/partnerConfigContext";
import useApi from "hooks/api/useApi";
import useAutotaskConfiguration from "hooks/api/useAutotaskConfiguration";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AppRoutes from "routes/app-routes";
import NotificationMessage from "types/notification-message";
import * as yup from "yup";

const AutotaskConnectionForm = () => {
  const [formErrorMsg, setFormErrorMsg] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { autotaskConfig, isAutotaskConfigFetching, isAutotaskConfigFetchingError } =
    useAutotaskConfiguration();

  const { autotaskConfigurationClient } = useApi();
  const navigate = useNavigate();
  const { raiseSuccessNotification, raiseWarningNotification, raiseErrorNotification } =
    useNotificationContext();
  const { invalidatePartnerConfig } = usePartnerConfigContext();

  const formSchema = yup.object().shape({
    apiUserName: yup.string().required("Required"),
    apiSecret: yup.string().required("Required"),
  });

  type SchemaType = yup.InferType<typeof formSchema>;

  const { control, handleSubmit, reset } = useForm<SchemaType>({
    resolver: yupResolver(formSchema),
  });

  // to update form values when sourceConfig is loaded
  useEffect(() => {
    if (!autotaskConfig) return;

    reset({ apiUserName: autotaskConfig.username });
  }, [autotaskConfig, reset]);

  useEffect(() => {
    if (isAutotaskConfigFetchingError)
      raiseWarningNotification(NotificationMessage.FAILED_TO_GET_AUTOTASK_CFG);
  }, [isAutotaskConfigFetchingError, raiseWarningNotification]);

  const onSave = async (formData: SchemaType): Promise<void> => {
    setIsSaving(true);
    try {
      await autotaskConfigurationClient.saveAutotaskConfiguration({
        username: formData.apiUserName,
        secret: formData.apiSecret,
      } as SaveAutotaskConfigurationRequestModel);

      raiseSuccessNotification(NotificationMessage.CONNECTION_SAVED);
      invalidatePartnerConfig();
      navigate(AppRoutes.connections.route);
    } catch (e: unknown) {
      const validationError = ValidationException.parse(e);
      if (validationError) {
        setFormErrorMsg(validationError.getFirstErrorMessage());
      } else {
        setFormErrorMsg(NotificationMessage.UNKNOWN_ERROR);
      }
      raiseErrorNotification(NotificationMessage.FAILED_TO_SAVE_CONNECTION);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Box component={Paper} p={2}>
        <Stack spacing={2}>
          <FormTextField
            control={control}
            bindSchemaFieldName="apiUserName"
            testId="API User Name"
            label="API User Name"
            isLoading={isAutotaskConfigFetching}
            disabled={isSaving}
            required
          />
          <FormTextField
            label="Secret"
            testId="Secret"
            control={control}
            bindSchemaFieldName="apiSecret"
            isLoading={isAutotaskConfigFetching}
            disabled={isSaving}
            sensitive
            required
          />
          <FormErrorMessage message={formErrorMsg} />
          <FormActionButtons
            cancelRoute={AppRoutes.connections.route}
            isSaveDisable={isAutotaskConfigFetching}
            isSaveLoading={isSaving}
          />
        </Stack>
      </Box>
      <DevTool control={control} />
    </form>
  );
};

export default AutotaskConnectionForm;
