// ideally should be <T extends Enum> but typescript doesn't have such constrain
export const combineEnumFlags = <T extends string | number>(
  ...enumFlags: T[]
): T => enumFlags.join(",") as T;

export const parseEnumFlags = <TEnum, TKeys extends string>(
  enumType: { [key in TKeys]: TEnum },
  flagsToParse: string,
): TEnum[] => {
  const result: TEnum[] = [];

  flagsToParse
    .replace(/\s+/g, "")
    .split(",")
    .forEach((flag) => {
      const enumFlag = (Object.keys(enumType) as TKeys[]).find(
        (v) => v === flag,
      );
      if (enumFlag) result.push(enumType[enumFlag]);
    });

  return result;
};

export const isValidEnumValue = <TEnum, TKeys extends string>(
  enumType: { [key in TKeys]: TEnum },
  testedValue: string,
): boolean => parseEnumFlags(enumType, testedValue).length !== 0;
