import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetPartnerConfigurationResponseV2 } from "api/client.generated";
import useApi from "./useApi";

const usePartnerConfiguration = (
  isQueryEnable: boolean,
): {
  partnerConfig: GetPartnerConfigurationResponseV2 | undefined;
  isPartnerConfigFetching: boolean;
  invalidatePartnerConfig: () => void;
} => {
  const { partnerConfigurationV2Client } = useApi();

  const queryKeys = ["partnerConfiguration"];

  const { data: partnerConfig, isFetching: isPartnerConfigFetching } = useQuery({
    queryKey: queryKeys,
    queryFn: () => partnerConfigurationV2Client.getPartnerConfiguration(),
    enabled: isQueryEnable,
  });

  const queryClient = useQueryClient();
  const invalidatePartnerConfig = () => {
    queryClient.invalidateQueries({ queryKey: queryKeys });
  };

  return { partnerConfig, isPartnerConfigFetching, invalidatePartnerConfig };
};

export default usePartnerConfiguration;
