import { IconButton, Tooltip } from "@crayon/design-system-react";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import { useSidebarContext } from "context/sideBarContext";
import useRouteDetector from "hooks/useRouteDetector";
import useScreen from "hooks/useScreen";
import { MenuIcon, UserGuideIcon } from "images/MuiIcons";
import SupportMenu from "../../hoc/Menu/SupportMenu";
import UserMenu from "../../hoc/Menu/UserMenu";
import Topbar from "./Topbar";

const AppTopbar = () => {
  const { isBigScreen } = useScreen();
  const { toggleSidebarOpen } = useSidebarContext();
  const { partner } = useSelectedPartnerContext();
  const { isSelectPartnerRoute, isSignInRoute, isTermsRoute } = useRouteDetector();

  const MenuButton =
    !isBigScreen && !isSelectPartnerRoute && !isSignInRoute && !isTermsRoute ? (
      <IconButton
        backgroundColor="secondary"
        onClick={toggleSidebarOpen}
        showBackground
        showBorder={false}
        sx={{ mr: 1 }}
      >
        <MenuIcon />
      </IconButton>
    ) : undefined;

  const PartnerName = !isSelectPartnerRoute && !isSignInRoute ? partner?.name : undefined;

  const TopbarButtons = !isSignInRoute ? (
    <>
      <Tooltip title="User Guides">
        <IconButton
          backgroundColor="primary"
          showBackground
          showBorder={false}
          testId="User Guides"
          onClick={() => window.open(process.env.REACT_APP_KNOWLEDGE_BASE_URL)}
        >
          <UserGuideIcon />
        </IconButton>
      </Tooltip>
      <SupportMenu />
      <UserMenu />
    </>
  ) : undefined;

  return (
    <Topbar partnerName={PartnerName} beforeLogoElement={MenuButton}>
      {TopbarButtons}
    </Topbar>
  );
};

export default AppTopbar;
