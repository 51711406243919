import { SyncDigestStats } from "api/client.generated";
import { DateTime } from "luxon";

class SyncDigestStatsViewModel {
  public when: DateTime;

  public errorCount: number;

  public warningCount: number;

  public syncCount: number;

  public secondarySyncCount: number;

  public digestId: string;

  public errorMessage: string;

  public invoices: string[];

  constructor(stats: SyncDigestStats) {
    this.when = DateTime.fromJSDate(new Date(stats.when!));
    this.errorCount = stats.errorCount ?? 0;
    this.warningCount = stats.warningCount ?? 0;
    this.syncCount = stats.syncCount ?? 0;
    this.secondarySyncCount = stats.secondarySyncCount ?? 0;
    this.digestId = stats.digestId ?? "";
    this.errorMessage = stats.errorMessage ?? "";
    this.invoices = stats.invoices ?? [];
  }
}

export default SyncDigestStatsViewModel;
