import axios from "axios";
import { Source } from "./client.generated";
import User from "./user";
import UserClaim from "./userClaim";

export default class UserService {
  static readonly UserRoute: string = "/bff/user";

  static readonly LoginRoute: string = "/bff/login";

  static readonly LogoutRoute: string = "/bff/logout";

  static async getUserClaims(): Promise<UserClaim[] | null> {
    try {
      const response = await axios.get(this.UserRoute, {
        headers: { "X-CSRF": "1" },
      });

      return response.status === 200 ? (response.data as UserClaim[]) : null;
    } catch {
      return null;
    }
  }

  private static getBaseUrlForLogin(source: Source) {
    switch (source) {
      case Source.Prism:
        return process.env.REACT_APP_RHIPE_LOGIN_URL ?? "";
      case Source.CloudIq:
        return process.env.REACT_APP_CRAYON_LOGIN_URL ?? "";
      default:
        throw new Error(`Unsupported source: ${source}`);
    }
  }

  static redirectToLogin(source: Source) {
    const baseUrl = this.getBaseUrlForLogin(source);
    window.location.replace(`${baseUrl}${UserService.LoginRoute}`);
  }

  static logout = (userClaims: UserClaim[] | null) => {
    const userSpecificRoute = userClaims?.find(
      (claim) => claim.type === "bff:logout_url",
    )?.value;

    const logoutRoute = (userSpecificRoute as string) ?? this.LogoutRoute;

    window.location.replace(logoutRoute);
  };

  static getUser = (userClaims: UserClaim[] | null): User | null => {
    if (userClaims === null) {
      return null;
    }

    return new User(userClaims);
  };
}
