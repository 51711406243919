import { Box, SxProps, TableCell, Theme, Typography } from "@crayon/design-system-react";
import React from "react";

interface TableHeaderCellProps {
  label?: string | number | undefined;
  color?: string;
  align?: "inherit" | "left" | "center" | "right";
  fontItalic?: boolean;
  colSpan?: number;
  icon?: React.ReactElement;
  sxRoot?: SxProps<Theme>;
}

const TableBodyCell = ({
  label = undefined,
  color = "inherit",
  align = "inherit",
  fontItalic = false,
  colSpan = 1,
  icon = undefined,
  sxRoot = undefined,
}: TableHeaderCellProps) => (
  <TableCell colSpan={colSpan} sx={{ ...sxRoot }}>
    <Box display="flex" alignItems="center" justifyContent={align} gap={1}>
      {icon}
      {Boolean(label) && (
        <Typography
          variant="body2"
          color={color}
          sx={{ ...(fontItalic && { fontStyle: "italic" }) }}
        >
          {label}
        </Typography>
      )}
    </Box>
  </TableCell>
);

export default TableBodyCell;
