/* eslint-disable func-names */
import { DateTime } from "luxon";

declare module "luxon/src/datetime" {
  export interface DateTime {
    extToDateTimeString(): string;
    extToDateString(): string;
  }
}

// Luxon time tokens: https://moment.github.io/luxon/#/formatting?id=table-of-tokens

DateTime.prototype.extToDateTimeString = function (): string {
  return this.toFormat("dd-MMM-yyyy hh:mm a");
};

DateTime.prototype.extToDateString = function (): string {
  return this.toFormat("dd MMM yyyy");
};
