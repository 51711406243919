import { Stack } from "@crayon/design-system-react";
import SyncHistoryTable from "components/hoc/Sync/SyncHistoryTable";
import TextHeader from "components/primitives/TextHeader";
import { useSyncOverviewContext } from "context/syncOverviewContext";

const SyncHistoryOverview = () => {
  const { source, target, program, syncType } = useSyncOverviewContext();
  return (
    <Stack spacing={2}>
      <TextHeader
        testId="page-header"
        label={`${program} ${syncType} Sync History | ${source} > ${target}`}
      />
      <SyncHistoryTable />
    </Stack>
  );
};

export default SyncHistoryOverview;
