import {
  AutotaskApiProxyClient,
  AutotaskConfigurationClient,
  CloudIqConfigurationClient,
  ConnectWiseConfigurationClient,
  CustomerMappingsClient,
  CustomersClient,
  DigestClient,
  PartnerConfigurationV2Client,
  PartnersClient,
  PriceRulesClient,
  PrismConfigurationClient,
  ProductsClient,
  ProgramsClient,
  SourceProductsClient,
  SyncroConfigurationClient,
  TermsClient,
} from "api/client.generated";
import ClientBase from "api/clientBase";
import ClientConfig from "api/clientConfig";
import axios, { AxiosError, AxiosInstance } from "axios";
import { useSelectedPartnerContext } from "context/selectedPartnerContext";
import { useCallback, useMemo } from "react";

interface UseApiReturn {
  autotaskApiProxyClient: AutotaskApiProxyClient;
  autotaskConfigurationClient: AutotaskConfigurationClient;
  digestClient: DigestClient;
  cloudIqConfigurationClient: CloudIqConfigurationClient;
  connectWiseConfigurationClient: ConnectWiseConfigurationClient;
  customersClient: CustomersClient;
  customerMappingsClient: CustomerMappingsClient;
  partnerConfigurationV2Client: PartnerConfigurationV2Client;
  partnersClient: PartnersClient;
  priceRulesClient: PriceRulesClient;
  prismConfigurationClient: PrismConfigurationClient;
  productsClient: ProductsClient;
  programsClient: ProgramsClient;
  sourceProductsClient: SourceProductsClient;
  syncroConfigurationClient: SyncroConfigurationClient;
  termsClient: TermsClient;
}

// TODO: probably not efficient - on every call the whole return object is created every time.
// Either use param to get exactly what's gonna be used OR wrap into context
const useApi = (): UseApiReturn => {
  const { partner, setPartner } = useSelectedPartnerContext();

  const clientConfig = useMemo<ClientConfig>(() => new ClientConfig(partner?.id ?? ""), [partner]);

  const axiosInstance = useMemo(() => axios.create(), []);

  // generic handler for '403 Forbidden'
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 403) {
        setPartner(undefined);
      }

      throw error;
    },
  );

  const createClientInstance = useCallback(
    <T extends ClientBase>(
      TargetClient: new (
        configuration: ClientConfig,
        baseUrl?: string,
        instance?: AxiosInstance,
      ) => T,
    ): T => new TargetClient(clientConfig, undefined, axiosInstance),
    [clientConfig, axiosInstance],
  );

  return useMemo<UseApiReturn>(
    () => ({
      autotaskApiProxyClient: createClientInstance(AutotaskApiProxyClient),
      autotaskConfigurationClient: createClientInstance(AutotaskConfigurationClient),
      digestClient: createClientInstance(DigestClient),
      cloudIqConfigurationClient: createClientInstance(CloudIqConfigurationClient),
      connectWiseConfigurationClient: createClientInstance(ConnectWiseConfigurationClient),
      customersClient: createClientInstance(CustomersClient),
      customerMappingsClient: createClientInstance(CustomerMappingsClient),
      partnerConfigurationV2Client: createClientInstance(PartnerConfigurationV2Client),
      partnersClient: createClientInstance(PartnersClient),
      priceRulesClient: createClientInstance(PriceRulesClient),
      prismConfigurationClient: createClientInstance(PrismConfigurationClient),
      productsClient: createClientInstance(ProductsClient),
      programsClient: createClientInstance(ProgramsClient),
      sourceProductsClient: createClientInstance(SourceProductsClient),
      syncroConfigurationClient: createClientInstance(SyncroConfigurationClient),
      termsClient: createClientInstance(TermsClient),
    }),
    [createClientInstance],
  );
};

export default useApi;
