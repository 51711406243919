import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
  useTheme,
} from "@crayon/design-system-react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useToggle from "hooks/useToggle";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface FormTextFieldProps<TField extends FieldValues> {
  label: string;
  bindSchemaFieldName: Path<TField>;
  control: Control<TField>;
  disabled?: boolean;
  isLoading?: boolean;
  sensitive?: boolean;
  testId?: string;
  required?: boolean;
}

const FormTextField = <TField extends FieldValues>({
  bindSchemaFieldName,
  control,
  label,
  disabled = false,
  isLoading = false,
  sensitive = false,
  testId = undefined,
  required = undefined,
}: FormTextFieldProps<TField>) => {
  const theme = useTheme();
  const [revealSensitive, toggleSensitive] = useToggle(false);

  return (
    <Controller
      control={control}
      name={bindSchemaFieldName}
      render={({ field, fieldState }) => (
        <FormControl error={Boolean(fieldState.error)} {...(testId && { testId })}>
          {isLoading ? (
            <Skeleton variant="rounded" height={56} />
          ) : (
            <>
              <TextField
                error={Boolean(fieldState.error)}
                label={label}
                sx={{
                  input: {
                    color: theme.palette.secondary.main,
                    ...(sensitive &&
                      !revealSensitive && {
                        WebkitTextSecurity: "disc",
                      }),
                  },
                }}
                {...(sensitive && { autoComplete: "one-time-code" })}
                autoComplete="one-time-code"
                {...field}
                value={field.value ? `${field.value}` : ""}
                disabled={disabled}
                {...(sensitive && {
                  InputProps: {
                    endAdornment:
                      // prettier-ignore
                      <InputAdornment position="end">
                        <IconButton onClick={toggleSensitive} {...(testId && { testId })}>
                          {revealSensitive ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>,
                  },
                })}
                {...(testId && { testId })}
                required={required}
              />

              <FormHelperText {...(testId && { testId: `${testId}-helper-text` })}>
                {fieldState.error?.message}
              </FormHelperText>
            </>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormTextField;
