import { useQuery } from "@tanstack/react-query";
import { GetCustomerSyncResultsResponse } from "api/client.generated";
import useApi from "./useApi";

interface UseBillingDigestReturn {
  customerDigest: GetCustomerSyncResultsResponse | undefined;
  isCustomerDigestFetching: boolean;
}

const useBillingDigest = (digestId: string): UseBillingDigestReturn => {
  const { digestClient } = useApi();

  const { data: customerDigest, isFetching: isCustomerDigestFetching } =
    useQuery({
      queryKey: ["getCustomerDigest", digestId],
      queryFn: () => digestClient.getCustomerDigest(digestId),
    });

  return { customerDigest, isCustomerDigestFetching };
};

export default useBillingDigest;
