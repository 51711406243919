import { Box, LinkBehavior, PrimaryButton, Toolbar, Typography } from "@crayon/design-system-react";
import ThemeProviderWrapper from "components/layout/ThemeProviderWrapper";
import Topbar from "components/layout/Topbar/Topbar";
import { useRouteError } from "react-router-dom";
import AppRoutes from "routes/app-routes";

interface RouteError {
  statusText: string | null;
  message: string | null;
  status: number;
}

const ErrorPage = () => {
  const error = useRouteError() as RouteError;
  window.console.error(error.statusText || error.message);

  return (
    <ThemeProviderWrapper>
      <Box id="error-page-layout">
        <Topbar />
        {/* <Toolbar /> here is to compensate Topbar heigh */}
        <Toolbar />
        <Box
          height="90vh"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={4}
        >
          <Typography variant="h1">Oops!</Typography>
          <Typography variant="h2" align="center">
            We have encountered an unexpected error
          </Typography>
          <PrimaryButton component={LinkBehavior} href={AppRoutes.dashboard.route}>
            Return to Dashboard
          </PrimaryButton>
        </Box>
      </Box>
    </ThemeProviderWrapper>
  );
};

export default ErrorPage;
